/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { City, ServiceType } from "@models/index";
import { CampApi, deleteFile } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { ServiceItem } from "./serviceItemSlice";
import $api from "src/app/axios/axiosInstance";

export type CampType = {
  id?: string;
  camp_type: City;
} & ServiceType;

export type CampTypeRes = {
  id: string;
  camp_type: City;
  service_items: ServiceItem[];
} & ServiceType;

interface CampState {
  camp?: CampTypeRes;
  loading: boolean;
  error?: string | null;
}

export type ArgumentsType = {
  type: City;
};

export const fetchCamp = createAsyncThunk(
  SliceConstants.FetchCafe,
  async ({ type }: ArgumentsType, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${CampApi.camp}?camp_type=${type}`);

      return response.data.items[0];
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createCamp = createAsyncThunk(
  SliceConstants.CreateCafe,
  async (data: CampType, { rejectWithValue }) => {
    try {
      const response = await $api.post(CampApi.camp, data);

      notification.success({
        message: "Лагерь создан",
      });

      return response.data;
    } catch (error: any) {
      if (data.images?.length) {
        await Promise.all(
          data.images.map(
            async (img) =>
              await deleteFile(img).catch((err) => {
                console.error("Failed to delete file:", img, err);
              })
          )
        );
      }

      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateCamp = createAsyncThunk(
  SliceConstants.UpdateCafe,
  async ({ id, ...data }: CampType, { rejectWithValue }) => {
    try {
      const response = await $api.put(`${CampApi.camp}/${id}`, data);

      notification.success({
        message: "Лагерь обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteCamp = createAsyncThunk(
  SliceConstants.deleteCamp,
  async (id: string, { rejectWithValue }) => {
    try {
      await $api.delete(`${CampApi.camp}/delete/${id}`);

      notification.success({
        message: "Лагерь удален",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: CampState = {
  camp: null,
  loading: false,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const campSlice = createSlice({
  name: "camp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchCamp, (state: any, action: any) => {
      state.camp = action.payload;
    });

    handleAsyncAction(builder, createCamp, (state: any, action: any) => {
      state.camp = action.payload;
    });

    handleAsyncAction(builder, updateCamp, (state: any, action: any) => {
      state.camp = action.payload;
    });

    handleAsyncAction(builder, deleteCamp, (state: any, action: any) => {
      state.camp = null;
    });
  },
});

export default campSlice.reducer;
