import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd"; // Assuming you're using Ant Design

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <img
        src={
          "https://lh6.googleusercontent.com/Bu-pRqU_tWZV7O3rJ5nV1P6NjqFnnAs8kVLC5VGz_Kf7ws0nDUXoGTc7pP87tyUCfu8VyXi0YviIm7CxAISDr2lJSwWwXQxxz98qxVfMcKTJfLPqbcfhn-QEeOowjrlwX1LYDFJN"
        }
        alt="404 Not Found"
        style={{ maxWidth: "100%", height: "auto" }}
      />
      <h1>Страница либо не найдена, либо Ваша авторизация истекла</h1>
      <p>Заполните данные снова, в случае истечения авторизации*</p>
      <br />
      <br />
      <Button type="primary" onClick={() => navigate("/")}>
        На главную страницу{" "}
      </Button>
    </div>
  );
};

export default NotFoundPage;
