import { useAppDispatch, useAppSelector } from "@shared/libs";
import { setParamsType } from "@store/slices/vacanciesSlice";

export const useFilter = () => {
  const dispatch = useAppDispatch();
  const { params } = useAppSelector((state) => state.vacancies);
  const { employment, experience, work_type, vacancyCity } = params;

  const handleExperienceChange = (value) => {
    dispatch(setParamsType({ type: "experience", value }));
  };

  const handleEmploymentChange = (value) => {
    dispatch(setParamsType({ type: "employment", value }));
  };

  const handleCityChange = (value) => {
    dispatch(setParamsType({ type: "vacancyCity", value }));
  };
  const handleWorkTypeChange = (value) => {
    dispatch(setParamsType({ type: "work_type", value }));
  };

  return {
    experience,
    employment,
    work_type,
    vacancyCity,
    handleExperienceChange,
    handleEmploymentChange,
    handleWorkTypeChange,
    handleCityChange,
  };
};
