/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { LangIds } from "@models/index";
import { ExpMainPageApi } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { Banner } from "./bannerSlice";
import { VideoType } from "./videoSlice";
import $api from "src/app/axios/axiosInstance";

export type ExpMainPageType = {
  id?: string;
  leftBanner: LangIds;
  centerBanner: LangIds;
  rightBanner: LangIds;
  videos: LangIds;
};

export type ExpMainPageTypeRes = {
  id: string;
  leftBanner: {
    id: string;
    ru: Banner;
    ky: Banner;
    en: Banner;
  };
  centerBanner: {
    id: string;
    ru: Banner;
    ky: Banner;
    en: Banner;
  };
  rightBanner: {
    id: string;
    ru: Banner;
    ky: Banner;
    en: Banner;
  };
  videos: {
    ru: VideoType;
    ky: VideoType;
    en: VideoType;
  };
};

interface ExpMainPageState {
  expMainPage?: ExpMainPageTypeRes;
  loading: boolean;
  error?: string | null;
}

export const fetchExpMainPage = createAsyncThunk(
  SliceConstants.fetchExpMainPage,
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${ExpMainPageApi.expMainPage}`);

      return response.data.items[0];
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createExpMainPage = createAsyncThunk(
  SliceConstants.createExpMainPage,
  async (data: ExpMainPageType, { rejectWithValue }) => {
    try {
      const response = await $api.post(ExpMainPageApi.expMainPage, data);

      notification.success({
        message: "Баннеры на главной стр. созданы",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateExpMainPage = createAsyncThunk(
  SliceConstants.updateExpMainPage,
  async ({ id, ...data }: ExpMainPageType, { rejectWithValue }) => {
    try {
      const response = await $api.put(
        `${ExpMainPageApi.expMainPage}/${id}`,
        data
      );

      notification.success({
        message: "Баннеры на главной стр. изменены",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);
export const deleteExpMainPage = createAsyncThunk(
  SliceConstants.deleteExpMainPage,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await $api.delete(
        `${ExpMainPageApi.expMainPage}/delete/${id}`
      );

      notification.success({
        message: "Баннеры на главной стр. удалены",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: ExpMainPageState = {
  expMainPage: null,
  loading: false,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const expMainPageSlice = createSlice({
  name: "expMainPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchExpMainPage, (state: any, action: any) => {
      state.expMainPage = action.payload;
    });

    handleAsyncAction(builder, createExpMainPage, (state: any, action: any) => {
      state.expMainPage = action.payload;
    });

    handleAsyncAction(builder, updateExpMainPage, (state: any, action: any) => {
      state.expMainPage = action.payload;
    });

    handleAsyncAction(builder, deleteExpMainPage, (state: any, action: any) => {
      state.expMainPage = null;
    });
  },
});

export default expMainPageSlice.reducer;
