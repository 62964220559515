import { Form, Input, Button, Select } from "antd";
import { SocialNetwork } from "@models/socialNetwork";
import { createSocialNetwork } from "@store/slices/socialNetworkSlice";
import { authStore } from "@store/authorization/authStore";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { REQUIRED, SELECT_REQ, validateURL } from "@constants/validateFields";

const { Option } = Select;

const CreateSocialNetwork = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.socialNetworks);
  const { userRole } = authStore;

  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = (values: SocialNetwork) => {
    try {
      const type = userRole !== "adminStore" ? values.type : "store";

      dispatch(createSocialNetwork({ ...values, type })).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          form.resetFields();
          onFormNotDirty();
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Form
      form={form}
      name="createSocialNetwork"
      onFinish={onFinish}
      layout="vertical"
      style={{ maxWidth: 1000 }}
      disabled={loading}
      onValuesChange={onFormDirty}
    >
      <h2>Создать социальную сеть</h2>

      <Form.Item name="title" label="Название" rules={[SELECT_REQ]}>
        <Input />
      </Form.Item>

      <Form.Item
        name="url"
        label="Ссылка"
        rules={[REQUIRED, { validator: validateURL }]}
      >
        <Input />
      </Form.Item>

      {userRole !== "adminStore" && (
        <Form.Item name="type" label="Тип" rules={[SELECT_REQ]}>
          <Select style={{ width: "230px" }}>
            <Option value="jalal-abad">Джалал-Абад</Option>
            <Option value="bishkek">Бишкек</Option>
            {userRole === "superAdmin" && (
              <Option value="store">Barça Store</Option>
            )}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        name="social_network_type"
        label="Соц сеть"
        rules={[SELECT_REQ]}
      >
        <Select>
          <Option value="facebook">Facebook</Option>
          <Option value="instagram">Instagram</Option>
          <Option value="youtube">Youtube</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={false}
        >
          Создать
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateSocialNetwork;
