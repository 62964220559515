import { useEffect } from "react";
import { Tabs, TabsProps } from "antd";
import { CreateCategory } from "./CreateCategory";
import { CategoryTable } from "./CategoryTable";
import Title from "antd/lib/typography/Title";
import { useAppDispatch, useURLSearchParams } from "@shared/libs";
import { resetPagination } from "@store/slices/categorySlice";

export const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Таблица категорий",
    children: <CategoryTable />,
  },
  {
    key: "2",
    label: "Создать категорию",
    children: <CreateCategory />,
  },
];

export const Category = () => {
  const dispatch = useAppDispatch();
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  useEffect(() => {
    return () => {
      dispatch(resetPagination());
    };
  }, [dispatch]);

  return (
    <div>
      <Title>Категории</Title>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};
