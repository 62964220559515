import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateVacancies } from "@store/slices/vacanciesSlice";
import { Form, InputNumber, Select, notification } from "antd";
import { Vacancy } from "@models/vacancies";
import { AppDispatch } from "@store/index";
import { LangField, ModalUI } from "@shared/uikit";
import { REQUIRED } from "@constants/validateFields";
import { useAppSelector, useUnloadPage } from "@shared/libs";

interface IProps {
  vacancy: Vacancy;
  onClose: () => void;
}

const UpdateVacancy = ({ vacancy, onClose }: IProps) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useAppSelector((state) => state.vacancies);
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [formData, setFormData] = useState(vacancy);

  useEffect(() => {
    if (vacancy) {
      setFormData(vacancy);
      form.setFieldsValue(vacancy);
    }
  }, [vacancy, dispatch, form]);

  const handleFieldsChange = (data, name: string) => {
    setFormData((prev) => ({
      ...prev,
      [name]: data,
    }));
  };

  const onFinish = async () => {
    const { createdAt, updatedAt, status, ...others } = formData;

    try {
      await form.validateFields();
      dispatch(updateVacancies(others)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          onClose();
        }
      });
    } catch (errorInfo) {
      notification.error({
        message: "Заполните все обязательные поля!",
      });
    }
  };

  const fieldLabels = {
    title: "Название",
    description: "Описание",
    work_conditions: "Условия работы",
    skills: "Навыки",
  };

  const onCancel = () => {
    onFormNotDirty();
    onClose();
  };

  return (
    <ModalUI
      title={`Редактировать вакансию Jal-Group - ${vacancy.title?.ru}`}
      open
      onCancel={onCancel}
      onOk={onFinish}
      loading={loading}
    >
      <Form
        name="updateVacancy"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        disabled={loading}
        onValuesChange={onFormDirty}
      >
        {Object.keys(fieldLabels).map((fieldName) => (
          <Form.Item key={fieldName} label={fieldLabels[fieldName]} required>
            <LangField
              value={formData[fieldName]}
              name={fieldName}
              isTextArea={fieldName !== "title"}
              onChange={handleFieldsChange}
            />
          </Form.Item>
        ))}
        <Form.Item name="vacancy_city" label="Город">
          <Select
            onChange={(e) => handleFieldsChange(e, "vacancy_city")}
            defaultValue={formData.vacancy_city}
            options={[
              { value: "bishkek", label: "Бишкек" },
              { value: "jalal-abad", label: "Джалал-Абад" },
            ]}
          />
        </Form.Item>
        <Form.Item name="experience" label="Опыт">
          <Select
            onChange={(e) => handleFieldsChange(e, "experience")}
            defaultValue={formData.experience}
            options={[
              { value: "less-one", label: "Менее 1 года" },
              { value: "one_three", label: "1-3 года" },
              { value: "three_six", label: "3-6 лет" },
              { value: "none", label: "Нет" },
            ]}
          />
        </Form.Item>
        <Form.Item name="employment" label="Тип занятости">
          <Select
            onChange={(e) => handleFieldsChange(e, "employment")}
            defaultValue={formData.employment}
            options={[
              { value: "full-time", label: "Полный рабочий день" },
              { value: "part-time", label: "Неполный рабочий день" },
            ]}
          />
        </Form.Item>
        <Form.Item name="work_type" label="Тип работы">
          <Select
            onChange={(e) => handleFieldsChange(e, "work_type")}
            defaultValue={formData.work_type}
            options={[
              { value: "remote", label: "Удаленно" },
              { value: "office", label: "Офис" },
            ]}
          />
        </Form.Item>
        <Form.Item
          name="min_salary"
          label="Минимальная зарплата"
          rules={[REQUIRED]}
        >
          <InputNumber
            type="number"
            min={0}
            value={formData.min_salary}
            onChange={(e) => handleFieldsChange(e, "min_salary")}
          />
        </Form.Item>
        <Form.Item
          name="max_salary"
          label="Максимальная зарплата"
          rules={[REQUIRED]}
        >
          <InputNumber
            type="number"
            min={0}
            value={formData.max_salary}
            onChange={(e) => handleFieldsChange(e, "max_salary")}
          />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};

export default UpdateVacancy;
