import { Category } from "@features/category";
import React from "react";

export const CategoryPage = () => {
  return (
    <div>
      <Category />
    </div>
  );
};
