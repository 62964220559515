import { RouteProps } from "react-router-dom";
import {
  MainPage,
  AuthPage,
  BishkekTrainers,
  JalalAbadTrainers,
  BarcaShop,
  BishkekAcademy,
  JalalAbadAcademy,
} from "@pages/index";
import GoodsPage from "@pages/GoodsPage";
import { AppRoutes, Routes } from "../enums/routes/appRoutes";
import { ExperienceNews } from "@pages/ExperienceNews";
import { CategoryPage } from "@pages/index";
import Collections from "@pages/CollectionsPage/Collections";
import { OrdersPage } from "@pages/OrdersPage";
import { UsersPage } from "@pages/UsersPage";
import VacanciesReplyPage from "@pages/VacanciesReplyPage/VacanciesReplyPage";
import NotFoundPage from "@pages/NotFounPage";
import ExperienceVacanciesPage from "@pages/ExperienceVacancies";
import SocialNetworkPage from "@pages/SocialNetworkPage/SocialNetworkPage";
import JalGroupPage from "@pages/JalGroupPage/JalGroupPage";
import AdminsPage from "@pages/AdminsPage";
import ExperienceMainPage from "@pages/ExperienceMainPage/ExperienceMainPage";
import Banners from "@pages/BannersPage/Banners";
import VideoPage from "@pages/VideoPage/VideoPage";
import ChampionshipPage from "@pages/ChampionshipPage/ChampionshipPage";
import { ContactPage } from "@pages/ContactPage";
import { FaqPage } from "@pages/FaqPage";
import { InfoPage } from "@pages/InfoPage";
import ClinicPage from "@pages/ClinicPage/ClinicPage";
import ExperienceProjectPage from "@pages/ExperienceProjectPage/ExperienceProjectPage";

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: Routes.MAIN,
  [AppRoutes.GOODS]: Routes.GOODS,
  [AppRoutes.JALAL_ABAD_ACADEMY_TRAINERS]: Routes.JALAL_ABAD_ACADEMY_TRAINERS,
  [AppRoutes.BISHKEK_ACADEMY_TRAINERS]: Routes.BISHKEK_ACADEMY_TRAINERS,
  [AppRoutes.EXPERIENCE_NEWS]: Routes.EXPERIENCE_NEWS,
  [AppRoutes.SHOP]: Routes.SHOP,
  [AppRoutes.CATEGORIES]: Routes.CATEGORIES,
  [AppRoutes.CONTACTS]: Routes.CONTACTS,
  [AppRoutes.BISHKEK_CAFE]: Routes.BISHKEK_CAFE,
  [AppRoutes.INFO]: Routes.INFO,
  [AppRoutes.FAQ]: Routes.FAQ,
  [AppRoutes.JALAL_ABAD_CAFE]: Routes.JALAL_ABAD_CAFE,
  [AppRoutes.MUSEUM]: Routes.MUSEUM,
  [AppRoutes.COLLECTIONS]: Routes.COLLECTIONS,
  [AppRoutes.ORDERS]: Routes.ORDERS,
  [AppRoutes.USERS]: Routes.USERS,
  [AppRoutes.VACANCIES_REPLY]: Routes.VACANCIES_REPLY,
  [AppRoutes.BISHKEK_ACADEMY]: Routes.BISHKEK_ACADEMY,
  [AppRoutes.JALAL_ABAD_ACADEMY]: Routes.JALAL_ABAD_ACADEMY,
  [AppRoutes.EXPERIENCE_VACANCIES]: Routes.EXPERIENCE_VACANCIES,
  [AppRoutes.SOCIAL_NETWORKS]: Routes.SOCIAL_NETWORKS,
  [AppRoutes.JAL_GROUP]: Routes.JAL_GROUP,
  [AppRoutes.ADMINS]: Routes.ADMINS,
  [AppRoutes.EXPERIENCE_MAIN]: Routes.EXPERIENCE_MAIN,
  [AppRoutes.BANNERS]: Routes.BANNERS,
  [AppRoutes.CHAMPIONSHIP]: Routes.CHAMPIONSHIP,
  [AppRoutes.VIDEO]: Routes.VIDEO,
  [AppRoutes.CLINIC]: Routes.CLINIC,
  [AppRoutes.EXPERIENCE_PROJECT]: Routes.EXPERIENCE_PROJECT,
  [AppRoutes.NOT_FOUND]: Routes.NOT_FOUND,
};

export const routeConfigPrivateStore = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.SHOP]: {
    path: RoutePath.shop,
    element: <BarcaShop />,
  },
  [AppRoutes.CATEGORIES]: {
    path: RoutePath.categories,
    element: <CategoryPage />,
  },
  [AppRoutes.COLLECTIONS]: {
    path: RoutePath.collections,
    element: <Collections />,
  },
  [AppRoutes.USERS]: {
    path: RoutePath.users,
    element: <UsersPage />,
  },
  [AppRoutes.ORDERS]: {
    path: RoutePath.orders,
    element: <OrdersPage />,
  },
  [AppRoutes.SOCIAL_NETWORKS]: {
    path: RoutePath["social-networks"],
    element: <SocialNetworkPage />,
  },
  [AppRoutes.BANNERS]: {
    path: RoutePath.banners,
    element: <Banners />,
  },
  [AppRoutes.VIDEO]: {
    path: RoutePath.video,
    element: <VideoPage />,
  },
  [AppRoutes.CONTACTS]: {
    path: RoutePath.contacts,
    element: <ContactPage />,
  },
  [AppRoutes.FAQ]: {
    path: RoutePath.faq,
    element: <FaqPage />,
  },
  [AppRoutes.INFO]: {
    path: RoutePath.info,
    element: <InfoPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath["*"],
    element: <NotFoundPage />,
  },
};

export const routeConfigPrivateExperience = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.CLINIC]: {
    path: RoutePath.clinic,
    element: <ClinicPage />,
  },
  [AppRoutes.CONTACTS]: {
    path: RoutePath.contacts,
    element: <ContactPage />,
  },
  [AppRoutes.BISHKEK_ACADEMY]: {
    path: RoutePath["bishkek-academy"],
    element: <BishkekAcademy />,
  },
  [AppRoutes.JALAL_ABAD_ACADEMY]: {
    path: RoutePath["jalal-abad-academy"],
    element: <JalalAbadAcademy />,
  },
  [AppRoutes.VACANCIES_REPLY]: {
    path: RoutePath["vacancy-reply"],
    element: <VacanciesReplyPage />,
  },
  [AppRoutes.BANNERS]: {
    path: RoutePath.banners,
    element: <Banners />,
  },
  [AppRoutes.VIDEO]: {
    path: RoutePath.video,
    element: <VideoPage />,
  },
  [AppRoutes.BISHKEK_ACADEMY_TRAINERS]: {
    path: RoutePath["bishkek-adacemy/trainers"],
    element: <BishkekTrainers />,
  },
  [AppRoutes.JALAL_ABAD_ACADEMY_TRAINERS]: {
    path: RoutePath["jalal-abad-academy/trainers"],
    element: <JalalAbadTrainers />,
  },
  [AppRoutes.EXPERIENCE_NEWS]: {
    path: RoutePath["experience/news"],
    element: <ExperienceNews />,
  },
  [AppRoutes.EXPERIENCE_VACANCIES]: {
    path: RoutePath["experience/vacancies"],
    element: <ExperienceVacanciesPage />,
  },
  [AppRoutes.USERS]: {
    path: RoutePath.users,
    element: <UsersPage />,
  },
  [AppRoutes.SOCIAL_NETWORKS]: {
    path: RoutePath["social-networks"],
    element: <SocialNetworkPage />,
  },
  [AppRoutes.EXPERIENCE_MAIN]: {
    path: RoutePath["experience-main"],
    element: <ExperienceMainPage />,
  },
  [AppRoutes.CHAMPIONSHIP]: {
    path: RoutePath.championship,
    element: <ChampionshipPage />,
  },
  [AppRoutes.EXPERIENCE_PROJECT]: {
    path: RoutePath["experience-project"],
    element: <ExperienceProjectPage />,
  },
  [AppRoutes.FAQ]: {
    path: RoutePath.faq,
    element: <FaqPage />,
  },
  [AppRoutes.INFO]: {
    path: RoutePath.info,
    element: <InfoPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath["*"],
    element: <NotFoundPage />,
  },
};

export const routeConfigPrivateJalgroup = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.VIDEO]: {
    path: RoutePath.video,
    element: <VideoPage />,
  },
  [AppRoutes.BANNERS]: {
    path: RoutePath.banners,
    element: <Banners />,
  },
  [AppRoutes.JAL_GROUP]: {
    path: RoutePath["jal-group"],
    element: <JalGroupPage />,
  },
  [AppRoutes.VACANCIES_REPLY]: {
    path: RoutePath["vacancy-reply"],
    element: <VacanciesReplyPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath["*"],
    element: <NotFoundPage />,
  },
};

export const routeConfigPrivate: Record<AppRoutes | any, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.BISHKEK_ACADEMY]: {
    path: RoutePath["bishkek-academy"],
    element: <BishkekAcademy />,
  },
  [AppRoutes.JALAL_ABAD_ACADEMY]: {
    path: RoutePath["jalal-abad-academy"],
    element: <JalalAbadAcademy />,
  },
  [AppRoutes.GOODS]: {
    path: RoutePath.goods,
    element: <GoodsPage />,
  },
  [AppRoutes.BISHKEK_ACADEMY_TRAINERS]: {
    path: RoutePath["bishkek-adacemy/trainers"],
    element: <BishkekTrainers />,
  },
  [AppRoutes.JALAL_ABAD_ACADEMY_TRAINERS]: {
    path: RoutePath["jalal-abad-academy/trainers"],
    element: <JalalAbadTrainers />,
  },
  [AppRoutes.EXPERIENCE_NEWS]: {
    path: RoutePath["experience/news"],
    element: <ExperienceNews />,
  },
  [AppRoutes.EXPERIENCE_MAIN]: {
    path: RoutePath["experience-main"],
    element: <ExperienceMainPage />,
  },
  [AppRoutes.SHOP]: {
    path: RoutePath.shop,
    element: <BarcaShop />,
  },
  [AppRoutes.CONTACTS]: {
    path: RoutePath.contacts,
    element: <ContactPage />,
  },
  [AppRoutes.FAQ]: {
    path: RoutePath.faq,
    element: <FaqPage />,
  },
  [AppRoutes.INFO]: {
    path: RoutePath.info,
    element: <InfoPage />,
  },
  [AppRoutes.CATEGORIES]: {
    path: RoutePath.categories,
    element: <CategoryPage />,
  },
  [AppRoutes.VIDEO]: {
    path: RoutePath.video,
    element: <VideoPage />,
  },
  [AppRoutes.CLINIC]: {
    path: RoutePath.clinic,
    element: <ClinicPage />,
  },
  [AppRoutes.EXPERIENCE_PROJECT]: {
    path: RoutePath["experience-project"],
    element: <ExperienceProjectPage />,
  },
  [AppRoutes.COLLECTIONS]: {
    path: RoutePath.collections,
    element: <Collections />,
  },
  [AppRoutes.CHAMPIONSHIP]: {
    path: RoutePath.championship,
    element: <ChampionshipPage />,
  },
  [AppRoutes.ORDERS]: {
    path: RoutePath.orders,
    element: <OrdersPage />,
  },
  [AppRoutes.USERS]: {
    path: RoutePath.users,
    element: <UsersPage />,
  },
  [AppRoutes.VACANCIES_REPLY]: {
    path: RoutePath["vacancy-reply"],
    element: <VacanciesReplyPage />,
  },
  [AppRoutes.EXPERIENCE_VACANCIES]: {
    path: RoutePath["experience/vacancies"],
    element: <ExperienceVacanciesPage />,
  },
  [AppRoutes.SOCIAL_NETWORKS]: {
    path: RoutePath["social-networks"],
    element: <SocialNetworkPage />,
  },
  [AppRoutes.JAL_GROUP]: {
    path: RoutePath["jal-group"],
    element: <JalGroupPage />,
  },
  [AppRoutes.ADMINS]: {
    path: RoutePath.adminsPage,
    element: <AdminsPage />,
  },
  [AppRoutes.BANNERS]: {
    path: RoutePath.banners,
    element: <Banners />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath["*"],
    element: <NotFoundPage />,
  },
};

export const routeConfigPublic = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <AuthPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath["*"],
    element: <NotFoundPage />,
  },
};
