import { FC, useEffect, useState } from "react";
import { Card, Form, FormInstance } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { REQUIRED } from "@constants/validateFields";
import styles from "./LangEditor.module.scss";
import { CommonName } from "@models/common";
import { links } from "@enums/links";

type LangEditorProps = {
  form: FormInstance;
  value?: CommonName;
  name: string;
  isRequired?: boolean;
};

const quills = [
  { lang: "ru", label: "На русском" },
  { lang: "ky", label: "На кыргызском" },
  { lang: "en", label: "На английском" },
];

export const LangEditor: FC<LangEditorProps> = (props) => {
  const { form, value, name, isRequired = true } = props;

  const [editorValues, setEditorValues] = useState({
    ru: value?.ru || "",
    ky: value?.ky || "",
    en: value?.en || "",
  });
  const [currentLang, setCurrentLang] = useState<string | null>(null);

  useEffect(() => {
    if (value) {
      setEditorValues(value);
    }
  }, [value]);

  useEffect(() => {
    const editors = document.querySelectorAll(".ql-editor");
    editors.forEach((editor) => {
      editor.setAttribute("spellcheck", "false");
    });
  }, []);

  const modules = {
    toolbar: [
      [{ header: [2, 3, 4, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ color: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
    "clean",
  ];

  const handleFocus = (lang: string) => () => {
    setCurrentLang(lang);
  };

  const handleChange = (lang: string) => (text: string) => {
    const val = text === "<p><br></p>" ? "" : text;

    if (currentLang) {
      setEditorValues((prevValues) => ({
        ...prevValues,
        [lang]: val,
      }));

      form.setFieldValue(name, { ...form.getFieldValue(name), [lang]: val });
      form.validateFields([[name, lang]]);
    }
  };

  return (
    <Card className={styles.formItem}>
      {quills.map(({ lang, label }) => (
        <Form.Item
          key={lang}
          name={[name, lang]}
          rules={isRequired && [REQUIRED]}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: 10,
            }}
          >
            <label className={styles.label}>{label}</label>
            <img src={links[lang]} alt="" width={25} height={25} />
          </div>
          <ReactQuill
            className={styles.editor}
            theme="snow"
            value={editorValues[lang]}
            formats={formats}
            modules={modules}
            onChange={handleChange(lang)}
            onFocus={handleFocus(lang)}
          />
        </Form.Item>
      ))}
    </Card>
  );
};
