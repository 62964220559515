import { Tabs, TabsProps } from "antd";
import { ActiveOrders } from "./ActiveOrders";
import { CanceledOrders } from "./CanceledOrders";
import { FinishedOrders } from "./FinishedOrders";
import { useURLSearchParams } from "@shared/libs";

export const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Активные заказы",
    children: <ActiveOrders />,
  },
  {
    key: "2",
    label: "Отмененные заказы",
    children: <CanceledOrders />,
  },
  {
    key: "3",
    label: "Завершенные заказы",
    children: <FinishedOrders />,
  },
];

export const OrdersList = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <h1>Список заказов Barça Shop</h1>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
