import { Tabs, TabsProps } from "antd";
import { ActiveContact } from "./ActiveContactBish";

const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Активные контакты",
    children: <ActiveContact />,
  },
];

export const TrainersTableBish = () => {
  return (
    <div>
      <Tabs items={tabItems} destroyInactiveTabPane />
    </div>
  );
};
