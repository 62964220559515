import { useEffect, useState } from "react";
import { Button, Table } from "antd";
import { activateUsers, fetchUsers } from "@store/slices/usersSlice";
import { columnsTable } from "../tableItems";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { LIMIT } from "@constants/variables";

export const BannedUsers = () => {
  const dispatch = useAppDispatch();
  const { usersList, loading, amount } = useAppSelector((state) => state.users);

  const [skip, setSkip] = useState(0);

  useEffect(() => {
    dispatch(fetchUsers({ status: "banned" }));
    dispatch(fetchUsers({ skip, limit: LIMIT, status: "banned" }));
  }, [dispatch, skip]);

  const onChangePage = (page: number) => {
    setSkip((page - 1) * LIMIT);
  };

  const columns = [
    ...columnsTable,
    {
      title: "Действия",
      dataIndex: "id",
      key: "id",
      render: (id) => {
        const activeUser = () => {
          dispatch(activateUsers(id));
        };

        return (
          <Button onClick={activeUser} danger>
            Активировать пользователя
          </Button>
        );
      },
    },
  ];

  return (
    <Table
      scroll={{ x: true }}
      dataSource={usersList}
      columns={columns}
      loading={loading}
      rowKey="id"
      pagination={{
        onChange: onChangePage,
        total: amount,
        pageSize: LIMIT,
        showSizeChanger: false,
      }}
    />
  );
};
