import { Tabs, TabsProps } from "antd";
import { ActiveFaq } from "./ActiveFaqBish";

const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Активные  Вопросы - Ответы",
    children: <ActiveFaq />,
  },
];

export const FaqTableBish = () => {
  return (
    <div>
      <Tabs items={tabItems} destroyInactiveTabPane />
    </div>
  );
};
