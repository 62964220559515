import { useState } from "react";
import { Button, Form, Popconfirm } from "antd";
import {
  ServiceItem,
  ServiceItemType,
  createServiceItem,
} from "@store/slices/serviceItemSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { SELECT_BANNERS } from "@constants/validateFields";
import {
  EditableTable,
  LangBanner,
  LangEditor,
  LangField,
  UploadFilesUI,
} from "@shared/uikit";
import { useFilesUpload } from "@constants/api";

const CreateService = ({ type }: { type: ServiceItemType }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.serviceItem);

  const {
    filesUpload: { uploadFiles, loadingFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [showDesc, setShowDesc] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [table, setTable] = useState({
    ru: "",
    en: "",
    ky: "",
  });

  const onFinish = async (values) => {
    const images = await uploadFiles(values?.images);

    const updatedData: ServiceItem = {
      ...values,
      images,
      table: displayTable && type === "championship" ? table : null,
      service_item_type: type,
    };

    dispatch(createServiceItem(updatedData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        onFormNotDirty();
        form.resetFields();
        setTable({ ru: "", en: "", ky: "" });
      }
    });
  };

  const handleTableHTML = (html, language) => {
    setTable((prevTable) => ({
      ...prevTable,
      [language]: html,
    }));
  };

  const onToggleDesc = () => {
    setShowDesc(!showDesc);
  };

  const onToggleTable = () => {
    setDisplayTable(!displayTable);
  };

  return (
    <Form
      name="createService"
      form={form}
      onFinish={onFinish}
      layout="vertical"
      disabled={loading || loadingFiles}
      onValuesChange={onFormDirty}
      scrollToFirstError={{ behavior: "smooth", block: "center" }}
    >
      <Form.Item name="title" label="Название" required>
        <LangField name="title" />
      </Form.Item>

      <Button htmlType="button" type="dashed" onClick={onToggleDesc}>
        {showDesc ? "Убрать описание" : "Добавить описание"}
      </Button>
      <br />
      <br />

      {type !== "championship" && showDesc && (
        <Form.Item name="description" label="Описание" required>
          <LangField name="description" />
        </Form.Item>
      )}

      <Form.Item name="text" label="Текст" required>
        <LangEditor form={form} name="text" />
      </Form.Item>

      {/* <Form.Item
        name="service_item_type"
        label="Тип сервиса"
        rules={[SELECT_REQ]}
      >
        <Select
          options={[
            { label: "Кафе", value: "cafe" },
            { label: "Музей", value: "museum" },
            { label: "Лагерь", value: "camp" },
            { label: "Клиника", value: "clinic" },
            { label: "Чемпионат", value: "championship" },
          ]}
        />
      </Form.Item> */}

      {type === "championship" && (
        <Form.Item name="images" label="Изображения">
          <UploadFilesUI form={form} name="images" />
        </Form.Item>
      )}

      <Form.Item name="banners" label="Баннеры" rules={[SELECT_BANNERS]}>
        <LangBanner form={form} name="banners" banner_type="service_item" />
      </Form.Item>
      <br />

      {type === "championship" && (
        <>
          {!displayTable && (
            <Button htmlType="button" onClick={onToggleTable}>
              Добавить таблицу
            </Button>
          )}

          {displayTable && (
            <>
              <Popconfirm
                title="Данные таблицы будут утеряны, продолжить?"
                onConfirm={onToggleTable}
              >
                <Button htmlType="button">Убрать таблицу</Button>
              </Popconfirm>

              <div
                style={{
                  border: "1px solid #8080802e",
                  borderRadius: 8,
                  padding: "20px 20px 0px",
                  margin: "20px 0px",
                }}
              >
                <h3>Таблицы</h3>
                <Form.Item
                  label={
                    <>
                      Таблица <strong style={{ marginLeft: 5 }}>(RU)</strong>
                    </>
                  }
                >
                  <EditableTable
                    onSendHtml={(html) => handleTableHTML(html, "ru")}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      Таблица <strong style={{ marginLeft: 5 }}>(KY)</strong>
                    </>
                  }
                >
                  <EditableTable
                    onSendHtml={(html) => handleTableHTML(html, "ky")}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      Таблица <strong style={{ marginLeft: 5 }}>(EN)</strong>
                    </>
                  }
                >
                  <EditableTable
                    onSendHtml={(html) => handleTableHTML(html, "en")}
                  />
                </Form.Item>
              </div>
            </>
          )}
          <br />
          <br />
        </>
      )}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading || loadingFiles}
          disabled={false}
        >
          Создать сервис
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateService;
