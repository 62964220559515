import { Card, Tabs, TabsProps } from "antd";
import { CafeInfo } from "@shared/uikit/Cafe/CafeInfo";
import { CreateCafe } from "@shared/uikit/Cafe/CreateCafeReusable";
import { useURLSearchParams } from "@shared/libs";

export const Cafe = ({ type }) => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Кафе",
      children: <CafeInfo type={type} />,
    },
    {
      key: "2",
      label: "Создать кафе",
      children: <CreateCafe type={type} />,
    },
  ];

  return (
    <Card>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </Card>
  );
};
