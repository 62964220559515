/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { VideoApi, deleteFile } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { CommonParams } from "@models/common";
import { RootState } from "..";
import {
  createValidate,
  deleteValidate,
  resetAll,
  setPagination,
} from "./paramsSlice";
import { LIMIT } from "@constants/variables";
import $api from "src/app/axios/axiosInstance";

export interface VideoType {
  id?: any;
  video: string;
  preview_path?: string;
}

interface VideoState {
  videoList: VideoType[];
  amount: number;
  loading: boolean;
  error: string | null;
}

export const fetchVideo = createAsyncThunk(
  SliceConstants.fetchVideo,
  async (params: CommonParams, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${VideoApi.video}`, {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createVideo = createAsyncThunk(
  SliceConstants.createVideo,
  async (data: VideoType, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(VideoApi.video, data);

      const store = getState() as RootState;
      const { page } = store.params;

      if (createValidate([], page)) {
        await dispatch(fetchVideo({ skip: 0, limit: LIMIT }));
      } else {
        dispatch(resetAll());
      }

      notification.success({
        message: "Видео создано",
      });

      return response.data;
    } catch (error: any) {
      await deleteFile(data.video);
      if (data.preview_path) {
        await deleteFile(data.preview_path);
      }
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createVideoOne = createAsyncThunk(
  SliceConstants.createVideoOne,
  async (data: VideoType, { rejectWithValue }) => {
    try {
      const response = await $api.post(VideoApi.video, data);

      notification.success({
        message: "Видео создано",
      });

      return response.data;
    } catch (error: any) {
      await deleteFile(data.video);
      if (data.preview_path) {
        await deleteFile(data.preview_path);
      }
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateVideo = createAsyncThunk(
  SliceConstants.updateVideo,
  async ({ id, ...data }: VideoType, { rejectWithValue }) => {
    try {
      const response = await $api.put(`${VideoApi.video}/${id}`, data);
      notification.success({
        message: "Видео обновлено",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteVideo = createAsyncThunk(
  SliceConstants.deleteVideo,
  async ({ id }: VideoType, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${VideoApi.video}/delete/${id}`);

      const store = getState() as RootState;
      const { videoList } = store.video;
      const { skip, page } = store.params;

      const currentPage = deleteValidate(skip, videoList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, videoList);

        await dispatch(fetchVideo({ skip: newSkip, limit: LIMIT }));
      } else {
        dispatch(setPagination(currentPage));
      }

      notification.success({
        message: "Видео удалено",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: VideoState = {
  videoList: [],
  amount: 0,
  loading: false,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchVideo, (state: any, action: any) => {
      state.videoList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(builder, createVideo, (state: any, action: any) => {});

    handleAsyncAction(builder, createVideoOne, (state: any, action: any) => {});

    handleAsyncAction(builder, updateVideo, (state: any, action: any) => {
      const { id, ...data } = action.payload;
      const index = state.videoList.findIndex(
        (video: VideoType) => video.id === id
      );
      if (index !== -1) {
        state.videoList[index] = {
          ...state.videoList[index],
          ...data,
        };
      }
    });

    handleAsyncAction(builder, deleteVideo, (state: any, action: any) => {});
  },
});

export default videoSlice.reducer;
