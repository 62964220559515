/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OrderApi } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { notification } from "antd";
import { Product } from "@models/products";
import { CommonParams } from "@models/common";
import { RootState } from "..";
import { deleteValidate } from "./paramsSlice";
import { LIMIT } from "@constants/variables";
import $api from "src/app/axios/axiosInstance";

export type OrderStatus = "active" | "canceled" | "finished";

export type OrderRecepient = {
  email: string;
  name: string;
  lastname: string;
  phone: number;
};

export type OrderItems = {
  id: string;
  quantity: number;
  size: string;
  product: Product;
  true_price: number;
  createdAt: string;
  updatedAt: string;
};

export interface OrderType {
  id: string;
  order_number: string;
  status: OrderStatus;
  cost: number;
  order_items: OrderItems[];
  reservation_time: string;
  recepient: OrderRecepient;
  createdAt: string;
  updatedAt: string;
}

interface OrderState {
  ordersList: OrderType[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    status?: OrderStatus;
    skip: number;
    page: number;
  };
}

export const fetchOrders = createAsyncThunk(
  "orders/fetchOrders",
  async (
    params: { status: OrderStatus } & CommonParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.get("/admin/orders", {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const activateOrder = createAsyncThunk(
  "orderSlice/activateOrders",
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${OrderApi.orders}/active`, {
        ids,
      });

      const store = getState() as RootState;
      const { ordersList, params } = store.orders;
      const { skip, page, status } = params;

      const currentPage = deleteValidate(skip, ordersList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, ordersList);

        await dispatch(
          fetchOrders({
            skip: newSkip,
            limit: LIMIT,
            status,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Заказ активирован",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const cancelOrder = createAsyncThunk(
  "orderSlice/cancelOrders",
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${OrderApi.orders}/cancel`, {
        ids,
      });

      const store = getState() as RootState;
      const { ordersList, params } = store.orders;
      const { skip, page, status } = params;

      const currentPage = deleteValidate(skip, ordersList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, ordersList);

        await dispatch(
          fetchOrders({
            skip: newSkip,
            limit: LIMIT,
            status,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Заказ отменен",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const finishOrder = createAsyncThunk(
  "orderSlice/finishOrders",
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${OrderApi.orders}/finish`, {
        ids,
      });

      const store = getState() as RootState;
      const { ordersList, params } = store.orders;
      const { skip, page, status } = params;

      const currentPage = deleteValidate(skip, ordersList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, ordersList);

        await dispatch(
          fetchOrders({
            skip: newSkip,
            limit: LIMIT,
            status,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Заказ завершен",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: OrderState = {
  ordersList: [],
  amount: 0,
  loading: false,
  error: null,
  params: {
    status: null,
    skip: 0,
    page: 1,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setStatusOrder(state, action) {
      state.params.status = action.payload;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetParams(state) {
      state.params.status = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchOrders, (state: any, action: any) => {
      state.ordersList = action.payload.items;
      state.amount = action.payload.amount;
    });
    handleAsyncAction(builder, activateOrder, (state: any, action: any) => {});
    handleAsyncAction(builder, cancelOrder, (state: any, action: any) => {});
    handleAsyncAction(builder, finishOrder, (state: any, action: any) => {});
  },
});

export const { setStatusOrder, setCurrentPage, resetParams } =
  orderSlice.actions;
export default orderSlice.reducer;
