import { useEffect, useMemo, useState } from "react";
import { Button, Modal, Popconfirm, Select, Table } from "antd";
import {
  acceptVacancies,
  fetchVacanciesReply,
  rejectVacancies,
  setCurrentPage,
  setParamsType,
} from "@store/slices/vacancyReplySlice";
import { authStore } from "@store/authorization/authStore";
import { LIMIT } from "@constants/variables";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { ROLES, tableItems, typeVacancyReplies } from "../tableItems";
import { VacancyModal } from "../VacancyModal";
import styles from "../styles.module.scss";

export const ActiveReplies = () => {
  const dispatch = useAppDispatch();
  const { replyList, amount, loading, params } = useAppSelector(
    (state) => state.vacancyReplies
  );
  const { page, skip, vacancy_reply_type } = params;
  const { userRole } = authStore;

  const role = useMemo(
    () => vacancy_reply_type || ROLES[userRole],
    [userRole, vacancy_reply_type]
  ) as any;

  const [letter, setLetter] = useState({ name: "", letter: "" });
  const [vacancy, setVacancy] = useState(null);

  const columns = [
    ...tableItems(setLetter, setVacancy),
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (text: string, record) => {
        const onAccept = () => {
          dispatch(acceptVacancies({ ids: [record.id] }));
        };

        const onReject = () => {
          dispatch(rejectVacancies({ ids: [record.id] }));
        };

        return (
          <div
            style={{
              display: "flex",
              gap: 10,
              width: "fit-content",
            }}
          >
            <Popconfirm title="Одобрить?" onConfirm={onAccept}>
              <Button type="primary" style={{ background: "#79bd5b" }}>
                Одобрить
              </Button>
            </Popconfirm>

            <Popconfirm title="Отказать?" onConfirm={onReject}>
              <Button danger>Отказать</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeType = (value) => {
    dispatch(setParamsType({ type: "vacancy_reply_type", value }));
  };

  const onCloseLetter = () => {
    setLetter({ name: "", letter: "" });
  };

  const onCloseVacancy = () => {
    setVacancy(null);
  };

  useEffect(() => {
    dispatch(
      fetchVacanciesReply({
        skip,
        limit: LIMIT,
        status: "review",
        vacancy_reply_type: role,
      })
    );
  }, [dispatch, skip, role]);

  useEffect(() => {
    dispatch(setParamsType({ type: "status", value: "review" }));
    dispatch(
      setParamsType({
        type: "vacancy_reply_type",
        value: role,
      })
    );
  }, [dispatch, role]);

  return (
    <>
      {userRole === "superAdmin" && (
        <div className={styles.header}>
          <div>Фильтр</div>
          <Select
            className={styles.selectType}
            value={vacancy_reply_type}
            onChange={onChangeType}
          >
            <Select.Option>Все типы</Select.Option>
            {typeVacancyReplies.map((yea) => (
              <Select.Option key={yea.value} value={yea.value}>
                {yea.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}

      <Table
        rowKey="id"
        loading={loading}
        scroll={{ x: true }}
        dataSource={replyList}
        columns={columns}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {letter.name && (
        <Modal
          open
          title={`Сопроводительное письмо - ${letter.name}`}
          onCancel={onCloseLetter}
          footer={null}
        >
          <div>{letter.letter}</div>
        </Modal>
      )}

      {vacancy && <VacancyModal data={vacancy} onClose={onCloseVacancy} />}
    </>
  );
};
