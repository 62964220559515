import React, { useEffect, useMemo, useState } from "react";
import { Button, Select } from "antd";
import { LIMIT } from "@constants/variables";
import { useAppDispatch } from "@shared/libs";

interface SelectUploadUIProps {
  value?: number | null;
  onChange?: (value: number | null) => void;
  request: any;
}

export const SelectUploadUI: React.FC<SelectUploadUIProps> = (props) => {
  const { value, onChange, request } = props;
  const dispatch = useAppDispatch();

  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [list, setList] = useState([]);

  const onChangeSkip = () => {
    setSkip((prev) => prev + LIMIT);
  };

  const options = useMemo(
    () =>
      list.map((item) => ({
        value: item.id,
        label: item.name.ru,
      })),
    [list]
  );

  useEffect(() => {
    setLoading(true);
    dispatch(request({ skip, limit: LIMIT })).then((data) => {
      const { items, amount } = data.payload as any;
      setList((prev) => (skip === 0 ? items : [...prev, ...items]));
      setAmount(amount);
      setLoading(false);
    });
  }, [dispatch, skip]);

  return (
    <Select
      style={{ width: "250px" }}
      options={options}
      value={value}
      onChange={onChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          {list.length < amount && (
            <Button
              type="text"
              style={{
                width: "100%",
                textAlign: "center",
                cursor: "pointer",
              }}
              loading={loading}
              onClick={onChangeSkip}
            >
              Ещё...
            </Button>
          )}
        </>
      )}
    />
  );
};
