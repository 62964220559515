import { Tabs, TabsProps } from "antd";
import { VacanciesPage } from "..";
import { NewsJalGroup } from "@features/newsJalGroup";
import { FinReport } from "@features/finReport";
import { useURLSearchParams } from "@shared/libs";

const JalGroupPage = () => {
  const { activeTabsKey, setAndResetTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Вакансии",
      children: <VacanciesPage />,
    },
    {
      key: "2",
      label: "Новости",
      children: <NewsJalGroup />,
    },
    {
      key: "3",
      label: "Фин отчетность",
      children: <FinReport />,
    },
  ];

  const onChangeTab = (key: string) => {
    setAndResetTabs("page", key);
  };

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={onChangeTab}
        activeKey={activeTabsKey["page"]}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default JalGroupPage;
