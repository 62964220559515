/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { SliceConstants } from "@constants/slices";
import { LIMIT } from "@constants/variables";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { InfoApi, deleteFile } from "@constants/api";
import { Info } from "@models/info";
import { CommonParams, Type } from "@models/common";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

interface infoState {
  infoList: Info[];
  loading: boolean;
  amount: number;
  error: string | null;
  params: {
    view_type?: Type;
    title: string;
    skip: number;
    page: number;
  };
}

export const fetchInfo = createAsyncThunk(
  SliceConstants.FetchInfo,
  async (
    params: { title?: string; view_type?: Type } & CommonParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.get(`${InfoApi.info}?info_page_type=faq`, {
        params: { ...params, title: params.title || null },
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const fetchInfoOne = createAsyncThunk(
  SliceConstants.FetchInfoOne,
  async (
    params: { title?: string; view_type?: Type } & CommonParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.get(`${InfoApi.info}?info_page_type=faq`, {
        params: { ...params, title: params.title || null },
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createinfo = createAsyncThunk(
  SliceConstants.CreateInfo,
  async (data: Info, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(InfoApi.info, data);

      const store = getState() as RootState;
      const { page, title, view_type } = store.info.params;

      if (createValidate([], page) && !title) {
        await dispatch(fetchInfo({ skip: 0, limit: LIMIT, view_type }));
      } else {
        dispatch(resetParams());
      }

      notification.success({
        message: "Успешно cоздано",
      });

      return response.data;
    } catch (error: any) {
      await deleteFile(data.images[0]);
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateinfo = createAsyncThunk(
  SliceConstants.UpdateInfo,
  async ({ id, ...data }: Info, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.put(`${InfoApi.info}/${id}`, data);

      const store = getState() as RootState;
      const { skip, title, view_type } = store.info.params;
      await dispatch(fetchInfo({ skip, limit: LIMIT, view_type, title }));

      notification.success({
        message: "Успешно обновлено",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteinfo = createAsyncThunk(
  SliceConstants.DeleteInfo,
  async (id: string, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${InfoApi.info}/delete/${id}`);

      const store = getState() as RootState;
      const { infoList, params } = store.info;
      const { skip, page, title, view_type } = params;

      const currentPage = deleteValidate(skip, infoList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, infoList);

        await dispatch(
          fetchInfo({ skip: newSkip, limit: LIMIT, title, view_type })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Удалено",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

const initialState: infoState = {
  infoList: [],
  loading: false,
  amount: 0,
  error: null,
  params: {
    page: 1,
    skip: 0,
    title: "",
    view_type: null,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params.view_type = action.payload;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    setTitleSearch(state, action) {
      state.params.title = action.payload;
    },
    resetPagination(state) {
      state.params.page = 1;
      state.params.skip = 0;
    },
    resetParams(state) {
      state.params.title = "";
      state.params.page = 1;
      state.params.skip = 0;
    },
    resetAll(state) {
      state.params.view_type = null;
      state.params.title = "";
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchInfo, (state: any, action: any) => {
      state.infoList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(builder, fetchInfoOne, (state: any, action: any) => {});

    handleAsyncAction(builder, createinfo, (state: any, action: any) => {});

    handleAsyncAction(builder, updateinfo, (state: any, action: any) => {});

    handleAsyncAction(builder, deleteinfo, (state: any, action: any) => {});
  },
});

export const {
  setParamsType,
  setCurrentPage,
  setTitleSearch,
  resetPagination,
  resetParams,
  resetAll,
} = infoSlice.actions;
export default infoSlice.reducer;
