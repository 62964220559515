import { TrainersJalal } from "@features/trainers/trainersJalalAbad";
import { Card } from "antd";
import React from "react";

export const JalalAbadTrainers = () => {
  return (
    <div>
      <Card>
      <TrainersJalal />

      </Card>
    </div>
  );
};
