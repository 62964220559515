import { motion } from "framer-motion";
import React, { Suspense, useState } from "react";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import "@theme/global.css";
import { Button, Layout, Popconfirm, Spin, theme } from "antd";
import { AppRouter } from "./providers";
import { Sidebar } from "@shared/uikit";
import { authStore } from "@store/authorization/authStore";
import { observer } from "mobx-react-lite";
import { AuthPage } from "./pages";

const { Header, Content } = Layout;

const App: React.FC = observer(() => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { isAuth } = authStore;

  if (!isAuth) {
    return <AuthPage />;
  }

  const logOut = () => {
    authStore.signOut();
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Suspense fallback={<Spin />}>
      <Layout style={{ height: "100vh" }}>
        <motion.div
          initial={{ x: -240 }}
          animate={{ x: collapsed ? -240 : 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <Sidebar />
        </motion.div>
        <Layout>
          <motion.div
            initial={{ marginLeft: 0 }}
            animate={{ marginLeft: collapsed ? -240 : 0 }}
            transition={{ type: "keyframes", stiffness: 240, damping: 20 }}
          >
            <Header
              style={{
                padding: "0 20px 0 0",
                background: colorBgContainer,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={toggleSidebar}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <Popconfirm
                onConfirm={logOut}
                title="Вы уверены, что хотите выйти из аккаунта?"
                okText="Да"
                cancelText="Отмена"
                placement="bottomLeft"
              >
                <Button>
                  <LogoutOutlined />
                </Button>
              </Popconfirm>
            </Header>
          </motion.div>
          <motion.div
            initial={{ marginLeft: 0 }}
            animate={{ marginLeft: collapsed ? -240 : 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            style={{
              overflowY: "scroll",
            }}
          >
            <Content
              style={{
                margin: "24px 16px",
                padding: 24,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <AppRouter />
            </Content>
          </motion.div>
        </Layout>
      </Layout>
    </Suspense>
  );
});

export default App;
