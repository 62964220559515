export enum AppRoutes {
  MAIN = "main",
  GOODS = "goods",
  JALAL_ABAD_ACADEMY_TRAINERS = "jalal-abad-academy/trainers",
  JALAL_ABAD_ACADEMY = "jalal-abad-academy",
  BISHKEK_ACADEMY = "bishkek-academy",
  BISHKEK_ACADEMY_TRAINERS = "bishkek-adacemy/trainers",
  EXPERIENCE_NEWS = "experience/news",
  SHOP = "shop",
  CATEGORIES = "categories",
  ADMINS = "adminsPage",
  BISHKEK_CAFE = "bishkek-academy/cafe",
  JALAL_ABAD_CAFE = "jalal-abad-academy/cafe",
  MUSEUM = "museum",
  INFO = "info",
  FAQ = "faq",
  COLLECTIONS = "collections",
  ORDERS = "orders",
  USERS = "users",
  VACANCIES_REPLY = "vacancy-reply",
  EXPERIENCE_VACANCIES = "experience/vacancies",
  SOCIAL_NETWORKS = "social-networks",
  JAL_GROUP = "jal-group",
  EXPERIENCE_MAIN = "experience-main",
  BANNERS = "banners",
  CHAMPIONSHIP = "championship",
  CONTACTS = "contacts",
  VIDEO = "video",
  CLINIC = "clinic",
  EXPERIENCE_PROJECT = "experience-project",
  NOT_FOUND = "*",
}

export enum Routes {
  MAIN = "/",
  GOODS = "/goods",
  JALAL_ABAD_ACADEMY_TRAINERS = "/jalal-abad-academy/trainers",
  BISHKEK_ACADEMY_TRAINERS = "/bishkek-adacemy/trainers",
  JALAL_ABAD_ACADEMY = "/jalal-abad-academy",
  BISHKEK_ACADEMY = "/bishkek-academy",
  EXPERIENCE_NEWS = "/experience/news",
  JAL_GROUP = "/jal-group",
  SHOP = "/shop",
  INFO = "/info",
  FAQ = "/faq",
  CATEGORIES = "/categories",
  ADMINS = "/adminsPage",
  BISHKEK_CAFE = "/bishkek-academy/cafe",
  JALAL_ABAD_CAFE = "/jalal-abad-academy/cafe",
  HOTELS = "/bishkek-academy/hotels",
  MUSEUM = "/museum",
  CHAMPIONSHIP = "/championship",
  COLLECTIONS = "/collections",
  ORDERS = "/orders",
  USERS = "/users",
  VACANCIES_REPLY = "/vacancy-reply",
  EXPERIENCE_VACANCIES = "/experience/vacancies",
  SOCIAL_NETWORKS = "/social-networks",
  EXPERIENCE_MAIN = "/experience-main",
  BANNERS = "/banners",
  VIDEO = "/video",
  CLINIC = "/clinic",
  EXPERIENCE_PROJECT = "/experience-project",
  CONTACTS = "/contacts",
  NOT_FOUND = "*",
}
