import { useState } from "react";
import { Form, Button, Input } from "antd";
import { VideoType, createVideo } from "@store/slices/videoSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { REQUIRED, validateURL } from "@constants/validateFields";
import { UploadFileUI, UploadVideoUI } from "@shared/uikit";
import { useFilesUpload } from "@constants/api";

const videoState: VideoType = {
  video: "",
  preview_path: "",
};

const CreateVideo = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.video);

  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [uploadVideoMode, setUploadVideoMode] = useState(true);

  const onFinish = async (values: VideoType) => {
    const video = await uploadFile(values.video);
    const preview_path = await uploadFile(values.preview_path);

    const updatedData: VideoType = {
      video,
      preview_path: preview_path || undefined,
    };

    if (video) {
      dispatch(createVideo(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          form.resetFields();
        }
      });
    }
  };

  const toggleUploadVideoMode = () => {
    setUploadVideoMode(!uploadVideoMode);
    form.setFieldValue("video", undefined);
    form.validateFields(["video"]);
  };

  return (
    <>
      <h2>Создать видео</h2>
      <div style={{ width: "400px" }}>
        <Form
          form={form}
          name="createVideoForm"
          onFinish={onFinish}
          layout="vertical"
          disabled={loading || loadingFile}
          initialValues={videoState}
          onValuesChange={onFormDirty}
        >
          <Button onClick={toggleUploadVideoMode}>
            {uploadVideoMode
              ? "Сменить на ссылку на YOUTUBE"
              : "Сменить на Файл обработчик"}
          </Button>
          <br />
          <br />
          {uploadVideoMode ? (
            <Form.Item name="video" label="Видео" rules={[REQUIRED]}>
              <UploadVideoUI form={form} name="video" />
            </Form.Item>
          ) : (
            <Form.Item
              name="video"
              label="Видео"
              rules={[
                REQUIRED,
                {
                  validator: validateURL,
                },
              ]}
            >
              <Input placeholder="Введите url" />
            </Form.Item>
          )}
          <Form.Item label="Превью" name="preview_path">
            <UploadFileUI form={form} name="preview_path" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading || loadingFile}
              disabled={false}
            >
              Создать
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default CreateVideo;
