import { Tabs, TabsProps } from "antd";
import { InfoTableBish } from "./InfoTable";
import { CreateInfo } from "./CreateInfoBish";
import { useURLSearchParams } from "@shared/libs";

export const Info = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Таблица FAQ",
      children: <InfoTableBish />,
    },
    {
      key: "2",
      label: "Создать FAQ",
      children: <CreateInfo />,
    },
  ];

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};
