import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Input, Popconfirm, Table, TableColumnsType } from "antd";
import { LIMIT } from "@constants/variables";
import { deletefaq } from "@store/slices/faqSlice";
import { UpdateFaq } from "@shared/uikit/UpdateFaq";
import { useAppDispatch, useAppSelector, useDebounce } from "@shared/libs";
import { fetchInfo } from "@store/slices/infoSlice";
import { Info } from "@models/info";
import {
  resetAll,
  resetPagination,
  setPagination,
  setTitleSearch,
  setType,
} from "@store/slices/paramsSlice";
import { authStore } from "@store/authorization/authStore";
import styles from "./ActiveFaqBish.module.scss";
import { ROLES_INFO } from "@features/info/utils";

export const ActiveFaq = () => {
  const [editQuestion, setEditQuestion] = useState(null);

  const dispatch = useAppDispatch();
  const { loading: faqLoading } = useAppSelector((state) => state.faq);
  const { infoList, loading, amount } = useAppSelector((state) => state.info);
  const { page, skip, title, type } = useAppSelector((state) => state.params);
  const { userRole } = authStore;

  const role = useMemo(
    () => type || ROLES_INFO[userRole],
    [userRole, type]
  ) as any;

  const debouncedSearchQuery = useDebounce(title, () => {
    dispatch(resetPagination());
  });

  const columns2 = useCallback(
    (data) => [
      {
        title: "Вопрос",
        dataIndex: ["question", "ru"],
        key: "question",
        render: (question) => <div className={styles.question}>{question}</div>,
      },
      {
        title: "Ответ",
        dataIndex: ["answer", "ru"],
        key: "answer",
        render: (answer) => <div className={styles.answer}>{answer}</div>,
      },
      {
        title: "Действия",
        dataIndex: "",
        key: "action",
        render: (record) => {
          const handleEdit = () => {
            const updatedData = {
              ...record,
              infoPageId: {
                value: data.id,
                label: data.title.ru,
              },
            };

            setEditQuestion(updatedData);
          };

          const deleteSoft = () => {
            dispatch(deletefaq(record.id));
          };

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                width: "fit-content",
              }}
            >
              <Button onClick={handleEdit}>Редактировать</Button>
              <Popconfirm title="Удалить?" onConfirm={deleteSoft}>
                <Button danger>Удалить</Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ],
    [dispatch]
  );

  const columns: TableColumnsType<Info> = [
    {
      title: "FAQ название (RU)",
      dataIndex: ["title", "ru"],
      key: "title",
      className: styles.title,
      render: (title) => <div className={styles.titleText}>{title}</div>,
    },
  ];

  const handleSearchChange = (e) => {
    dispatch(setTitleSearch(e.target.value));
  };

  const onChangePage = (page: number) => {
    dispatch(setPagination(page));
  };

  const onClose = () => {
    setEditQuestion(null);
  };

  useEffect(() => {
    dispatch(
      fetchInfo({
        skip,
        limit: LIMIT,
        title: debouncedSearchQuery,
        view_type: role,
      })
    );
  }, [debouncedSearchQuery, dispatch, role, skip]);

  useEffect(() => {
    dispatch(setType(role));
  }, [dispatch, role]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch]);

  return (
    <div>
      <Input
        placeholder="Поиск по названию (RU)"
        onChange={handleSearchChange}
        value={title}
      />
      <br />
      <br />
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={infoList}
        loading={loading || faqLoading}
        rowKey="id"
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={columns2(record)}
              dataSource={record.question_answers}
              pagination={false}
              rowKey="id"
              scroll={{ x: true }}
            />
          ),
          rowExpandable: (record) =>
            record.question_answers && record.question_answers.length > 0,
        }}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editQuestion && <UpdateFaq data={editQuestion} onClose={onClose} />}
    </div>
  );
};
