import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import CreateSocialNetwork from "./CreateSocialNetwork/CreateSocialNetwork";
import SocialNetworkList from "./SocialNetworkList/SocialNetworkList";
import { useURLSearchParams } from "@shared/libs";

const SociaNetworks = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Title>Соц сети</Title>
      <Tabs
        items={[
          {
            label: "Список соц сетей",
            key: "1",
            children: <SocialNetworkList />,
          },
          {
            label: "Создать соц сеть",
            key: "2",
            children: <CreateSocialNetwork />,
          },
        ]}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};

export default SociaNetworks;
