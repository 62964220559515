import { Tabs } from "antd";
import { CreateClinic } from "./createClinic/CreateClinic";
import { ClinicInfo } from "./clinicList/Clinic";
import { useURLSearchParams } from "@shared/libs";

const Clinics = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <Tabs
      items={[
        {
          label: "Клиника",
          key: "1",
          children: <ClinicInfo />,
        },
        {
          label: "Создать клинику",
          key: "2",
          children: <CreateClinic />,
        },
      ]}
      onChange={setActiveTabs("tab")}
      activeKey={activeTabsKey["tab"]}
    />
  );
};

export default Clinics;
