import { useState } from "react";
import { Modal, Tag } from "antd";
import { CommonName } from "@models/common";
import styles from "./MultiLangUI.module.scss";

interface MultiLangUIProps {
  data: CommonName;
  desc?: boolean;
}

export const MultiLangUI = ({ data, desc }: MultiLangUIProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {!desc ? (
        <div className={styles.title} onClick={onToggle}>
          {data.ru}
        </div>
      ) : (
        <div className={styles.desc} onClick={onToggle}>
          {data.ru}
        </div>
      )}

      <Modal width={600} title="Подробнее" open={isOpen} onCancel={onToggle} footer={false}>
        <ul className={styles.list}>
          <li>
            <Tag>RU:</Tag>
            <span className={styles.text}>{data.ru}</span>
          </li>
          <li>
            <Tag>KY:</Tag>
            <span className={styles.text}>{data.ky}</span>
          </li>
          <li>
            <Tag>EN:</Tag>
            <span className={styles.text}>{data.en}</span>
          </li>
        </ul>
      </Modal>
    </div>
  );
};
