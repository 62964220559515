import { Card } from "antd";
import { TrainersBishkek } from "@features/trainers/trainersBishkek";

export const BishkekTrainers = () => {
  return (
    <div>
      <Card>
        <TrainersBishkek />
      </Card>
    </div>
  );
};
