import { FC } from "react";
import { Form, Input, InputNumber, Select, notification } from "antd";
import { FinReportType, updateFinreport } from "@store/slices/finReportSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { REQUIRED, SELECTED_FILE } from "@constants/validateFields";
import { File } from "@features/finReport/CreateFinReport";
import { useFilesUpload } from "@constants/api";
import { ModalUI } from "@shared/uikit";

type UpdateFinReportProps = {
  report: FinReportType;
  open: boolean;
  onClose: () => void;
};

export const UpdateFinReport: FC<UpdateFinReportProps> = (props) => {
  const { open, onClose, report } = props;
  const [form] = Form.useForm<FinReportType>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.finReport);

  const {
    fileUpload: { uploadFile, loadingFile, setImgFile, setIsChangedFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const url = await uploadFile(values.url);

      const updateData = {
        ...values,
        id: report.id,
        url,
      };

      if (url) {
        dispatch(updateFinreport(updateData)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            onFormNotDirty();
            onClose();
          }

          if (data.meta.requestStatus === "rejected") {
            setIsChangedFile(url !== report.url);
            setImgFile(url);
          }
        });
      }
    } catch (errorInfo) {
      notification.error({
        message: "Заполните все обязательные поля!",
      });
    }
  };

  const onCancel = () => {
    onFormNotDirty();
    onClose();
  };

  return (
    <ModalUI
      title="Редактировать отчет"
      open={open}
      onCancel={onCancel}
      onOk={onFinish}
      loading={loading || loadingFile}
    >
      <Form
        name="updateFinReport"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        disabled={loading || loadingFile}
        initialValues={report}
        onValuesChange={onFormDirty}
      >
        <Form.Item
          name={["title", "ru"]}
          label="Название (RU)"
          rules={[REQUIRED]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["title", "ky"]}
          label="Название (KY)"
          rules={[REQUIRED]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["title", "en"]}
          label="Название (EN)"
          rules={[REQUIRED]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="finreport_type"
          label="Тип документа(фин отчета)"
          rules={[REQUIRED]}
        >
          <Select
            options={[
              {
                label: "PDF",
                value: "pdf",
              },
            ]}
          />
        </Form.Item>

        <Form.Item name="quarter_type" label="Квартал" rules={[REQUIRED]}>
          <Select
            options={[
              {
                label: "I",
                value: "I",
              },
              {
                label: "II",
                value: "II",
              },
              {
                label: "III",
                value: "III",
              },
              {
                label: "IV",
                value: "IV",
              },
            ]}
          />
        </Form.Item>

        <Form.Item name="url" label="URL" rules={[SELECTED_FILE]}>
          <File form={form} name="url" />
        </Form.Item>

        <Form.Item name="year" label="Год" rules={[REQUIRED]}>
          <InputNumber min={1945} maxLength={4} />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
