/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import { SliceConstants } from "@constants/slices";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProductApi, deleteFile } from "@constants/api";
import { Product } from "@models/products";
import { notification } from "antd";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { CommonParams } from "@models/common";
import { LIMIT } from "@constants/variables";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

interface ProductState {
  ProductList: Product[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    status: Product["status"];
    name: string;
    skip: number;
    page: number;
  };
}

interface ArgumentsType {
  status: Product["status"];
  name?: string;
}

export const fetchProducts = createAsyncThunk(
  SliceConstants.FetchProducts,
  async (params: ArgumentsType & CommonParams, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${ProductApi.Products}`, {
        params: { ...params, name: params.name || null },
      });

      return { items: response.data.items, amount: response.data.amount };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createProduct = createAsyncThunk(
  SliceConstants.CreateProduct,
  async (data: Product, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(ProductApi.Products, data);

      const store = getState() as RootState;
      const { page, status, name } = store.products.params;

      if (status && status === "active") {
        if (createValidate([], page) && !name) {
          await dispatch(
            fetchProducts({
              skip: 0,
              limit: LIMIT,
              status,
              name,
            })
          );
        } else {
          dispatch(resetParams());
        }
      }

      notification.success({
        message: "Продукт создан",
      });

      return response.data;
    } catch (error: any) {
      if (data.images?.length) {
        await Promise.all(
          data.images.map(
            async (img) =>
              await deleteFile(img).catch((err) => {
                console.error("Failed to delete file:", img, err);
              })
          )
        );
      }

      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateProduct = createAsyncThunk(
  SliceConstants.UpdateProduct,
  async ({ id, ...data }: Product, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.put(`${ProductApi.Products}/${id}`, data);

      const store = getState() as RootState;
      const { skip, status, name } = store.products.params;
      await dispatch(
        fetchProducts({
          skip,
          limit: LIMIT,
          status,
          name,
        })
      );

      notification.success({
        message: "Продукт обновлен",
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  SliceConstants.DeleteProduct,
  async (id: string, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${ProductApi.Products}/delete/${id}`);

      const store = getState() as RootState;
      const { ProductList, params } = store.products;
      const { skip, page, status, name } = params;

      const currentPage = deleteValidate(skip, ProductList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, ProductList);

        await dispatch(
          fetchProducts({
            skip: newSkip,
            limit: LIMIT,
            status,
            name,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Продукт полностью удален",
      });

      return id;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
// archive, delete, activate

export const archiveProducts = createAsyncThunk(
  SliceConstants.ArchiveProduct,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${ProductApi.Products}/archive`, {
        ids,
      });

      const store = getState() as RootState;
      const { ProductList, params } = store.products;
      const { skip, page, status, name } = params;

      const currentPage = deleteValidate(skip, ProductList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, ProductList);

        await dispatch(
          fetchProducts({
            skip: newSkip,
            limit: LIMIT,
            status,
            name,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Продукт архивирован",
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const activateProducts = createAsyncThunk(
  SliceConstants.ActivateProduct,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${ProductApi.Products}/active`, {
        ids,
      });

      const store = getState() as RootState;
      const { ProductList, params } = store.products;
      const { skip, page, status, name } = params;

      const currentPage = deleteValidate(skip, ProductList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, ProductList);

        await dispatch(
          fetchProducts({
            skip: newSkip,
            limit: LIMIT,
            status,
            name,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Продукт активирован",
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteProductsSoft = createAsyncThunk(
  SliceConstants.DeleteSoftProduct,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${ProductApi.Products}/delete`, {
        ids,
      });

      const store = getState() as RootState;
      const { ProductList, params } = store.products;
      const { skip, page, status, name } = params;

      const currentPage = deleteValidate(skip, ProductList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, ProductList);

        await dispatch(
          fetchProducts({
            skip: newSkip,
            limit: LIMIT,
            status,
            name,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Продукт удален",
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState: ProductState = {
  ProductList: [],
  loading: false,
  error: null,
  amount: 0,
  params: {
    status: null,
    name: "",
    skip: 0,
    page: 1,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const ProductSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params[action.payload.type] = action.payload.value;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    setNameSearch(state, action) {
      state.params.name = action.payload;
    },
    resetPagination(state) {
      state.params.page = 1;
      state.params.skip = 0;
    },
    resetParams(state) {
      state.params.status = null;
      state.params.name = "";
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchProducts, (state: any, action: any) => {
      state.ProductList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(builder, createProduct, (state: any, action: any) => {});

    handleAsyncAction(builder, updateProduct, (state: any, action: any) => {});

    handleAsyncAction(
      builder,
      archiveProducts,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      activateProducts,
      (state: any, action: any) => {}
    );

    handleAsyncAction(builder, deleteProduct, (state: any, action: any) => {});

    handleAsyncAction(
      builder,
      deleteProductsSoft,
      (state: any, action: any) => {}
    );
  },
});

export const {
  setParamsType,
  setCurrentPage,
  setNameSearch,
  resetParams,
  resetPagination,
} = ProductSlice.actions;
export default ProductSlice.reducer;
