import { FC } from "react";
import { Form, Select, notification } from "antd";
import { updateTrainer, TrainerType } from "@store/slices/trainerSlice";
import { LangEditor, LangField, ModalUI, UploadFileUI } from "@shared/uikit";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { SELECTED_FILE } from "@constants/validateFields";

interface IProps {
  data: TrainerType;
  onClose: () => void;
}

export const UpdateTrainer: FC<IProps> = ({ data, onClose }) => {
  const [form] = Form.useForm<TrainerType>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.trainers);

  const {
    fileUpload: { uploadFile, loadingFile, setImgFile, setIsChangedFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const photo = await uploadFile(values.photo);

      const updatedData: TrainerType = {
        ...values,
        id: data.id,
        photo,
      };

      if (photo) {
        dispatch(updateTrainer(updatedData)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            onFormNotDirty();
            onClose();
          }

          if (data.meta.requestStatus === "rejected") {
            setIsChangedFile(photo !== values.photo);
            setImgFile(photo);
          }
        });
      }
    } catch (error) {
      console.error(error);

      notification.error({
        message:
          error?.outOfDate === false
            ? "Заполните все обязательные поля!"
            : "Что-то пошло не так!",
      });
    }
  };

  return (
    <ModalUI
      title="Редактировать тренера"
      open
      onCancel={onClose}
      onOk={onFinish}
      width="sm"
      loading={loading || loadingFile}
    >
      <Form
        name="updateTrainer"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading || loadingFile}
        initialValues={data}
        onValuesChange={onFormDirty}
      >
        <Form.Item name="firstname" label="Имя">
          <LangField name="firstname" />
        </Form.Item>

        <Form.Item name="lastname" label="Фамилия">
          <LangField name="lastname" isTextArea />
        </Form.Item>

        <Form.Item name="description" label="Описание" required>
          <LangEditor form={form} name="description" />
        </Form.Item>

        <Form.Item name="role_type" label="Роль">
          <Select
            style={{ width: "220px" }}
            options={[
              { value: "trainer", label: "Тренер" },
              { value: "manager", label: "Менеджер" },
              { value: "coordinator", label: "Координатор" },
              { value: "cto", label: "Технический Директор" },
            ]}
          />
        </Form.Item>

        <Form.Item name="photo" label="Фотография" rules={[SELECTED_FILE]}>
          <UploadFileUI form={form} name="photo" />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
