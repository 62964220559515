import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Image, Select, FormInstance, Button } from "antd";
import { LIMIT } from "@constants/variables";
import { VideoType, fetchVideo } from "@store/slices/videoSlice";
import {
  getYouTubeVideoId,
  isYouTubeUrl,
  useAppDispatch,
  useAppSelector,
} from "@shared/libs";
import { links } from "@enums/links";
import styles from "./LangVideo.module.scss";
import { CreateVideoOne } from "./CreateVideoOne";

interface LangVideoProps {
  form: FormInstance;
  name: string;
  isRequired?: boolean;
  createVideo?: boolean;
}

const initialState = { ruId: 0, kyId: 0, enId: 0 };

export const LangVideo: FC<LangVideoProps> = ({
  form,
  name,
  isRequired = false,
  createVideo = true,
}) => {
  const dispatch = useAppDispatch();
  const { loading, amount } = useAppSelector((state) => state.video);

  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [list, setList] = useState<VideoType[]>([]);
  const [selectedLanguagesId, setSelectedLanguagesId] = useState(initialState);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState({
    ru: null,
    ky: null,
    en: null,
  });

  useEffect(() => {
    const initialState = form.getFieldValue(name);

    if (initialState) {
      setSelectedVideos(initialState);
      setSelectedLanguagesId({
        ruId: initialState.ru?.id,
        kyId: initialState.ky?.id,
        enId: initialState.en?.id,
      });
    }
  }, [form, name]);

  const observer = useRef<IntersectionObserver | null>(null);

  const handleVideoSelect = (value, lang) => {
    const selectedVideo = list.find((video) => video.id === value);
    const findedVideo = selectedVideo
      ? {
          video: selectedVideo.video,
          preview_path: selectedVideo.preview_path,
        }
      : null;

    if (selectedVideos.ru) {
      setSelectedVideos((prevState) => ({
        ...prevState,
        [lang]: findedVideo,
      }));
    } else {
      setSelectedVideos({
        ru: findedVideo,
        ky: findedVideo,
        en: findedVideo,
      });
    }

    if (selectedLanguagesId.ruId) {
      setSelectedLanguagesId((prevIds) => ({
        ...prevIds,
        [`${lang}Id`]: value,
      }));
    } else {
      setSelectedLanguagesId({
        ruId: value,
        kyId: value,
        enId: value,
      });
    }
    setSelectedVideoId(value);
  };

  const lastCommentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && list.length < amount + count) {
          setSkip((prevSkip) => prevSkip + LIMIT + count);
          setCount(0);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, list.length, amount]
  );

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onReset = () => {
    setSelectedVideos({
      ru: null,
      ky: null,
      en: null,
    });
    setSelectedLanguagesId(initialState);
    form.setFieldValue(name, null);
  };

  useEffect(() => {
    if (selectedLanguagesId.ruId !== 0) {
      form.setFieldValue(name, selectedLanguagesId);
      form.validateFields([name]);
    }
  }, [selectedLanguagesId]);

  useEffect(() => {
    dispatch(fetchVideo({ skip, limit: LIMIT })).then((data) => {
      if (data.payload) {
        const { items } = data.payload as any;
        setList((prev) => (skip === 0 ? items : [...prev, ...items]));
      }
    });
  }, [dispatch, skip]);

  return (
    <>
      <div className={styles.videos}>
        {["ru", "ky", "en"].map((lang) => (
          <div key={lang} className={styles.videoOne}>
            <div className={styles.title}>
              Видео
              <img src={links[lang]} alt="" width={30} />
            </div>

            {selectedVideos[lang] && (
              <div className={styles.videoBlock}>
                {isYouTubeUrl(selectedVideos[lang].video) ? (
                  <iframe
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                      selectedVideos[lang].video
                    )}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    sandbox="allow-same-origin allow-scripts"
                    className={styles.video}
                    allowFullScreen
                  />
                ) : (
                  <video
                    key={selectedVideoId}
                    controls
                    className={styles.video}
                    poster={selectedVideos[lang].preview_path}
                    preload="metadata"
                  >
                    <source src={selectedVideos[lang].video} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            )}

            <Select
              className={styles.select}
              loading={loading}
              placeholder={`Выберите видео (${lang})`}
              value={selectedLanguagesId[`${lang}Id`] || null}
              onChange={(value) => {
                handleVideoSelect(value, lang);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {loading && (
                    <div style={{ textAlign: "center" }}>Загрузка...</div>
                  )}
                </>
              )}
            >
              {list.map((video, index) => (
                <Select.Option key={video.id} value={video.id}>
                  <Image
                    src={video.preview_path}
                    width={80}
                    height={50}
                    preview={false}
                    fallback="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ="
                    style={{ objectFit: "contain" }}
                  />
                  &nbsp; &nbsp;
                  <span>{video.video}</span>
                  {index === list.length - 1 ? (
                    <div ref={lastCommentRef} />
                  ) : null}
                </Select.Option>
              ))}
            </Select>
          </div>
        ))}
      </div>
      <Button type="primary" onClick={onToggle}>
        Создать видео
      </Button>
      {!isRequired && (
        <Button
          type="primary"
          className={styles.btnDelete}
          danger
          onClick={onReset}
          disabled={!selectedVideos.ru}
        >
          Убрать видео
        </Button>
      )}

      <CreateVideoOne
        open={isOpen}
        onClose={onToggle}
        setList={setList}
        setCount={setCount}
      />
    </>
  );
};
