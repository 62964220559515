import { Dispatch, FC, SetStateAction } from "react";
import { Form, Input, Select } from "antd";
import { Banner, BannerType, createBannerOne } from "@store/slices/bannerSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { ModalUI, UploadFileUI } from "@shared/uikit";
import { SELECTED_FILE, validateURL } from "@constants/validateFields";
import { typeBanners } from "@pages/BannersPage/consts";
import { useFilesUpload } from "@constants/api";

type CreateBannerProps = {
  open: boolean;
  onClose: () => void;
  setList: Dispatch<SetStateAction<Banner[]>>;
  setCount: Dispatch<SetStateAction<number>>;
  banner_type?: BannerType;
};

const bannerState: Banner = {
  banner_type: "news",
  banner: "",
};

export const CreateBannerOne: FC<CreateBannerProps> = ({
  open,
  onClose,
  setList,
  setCount,
  banner_type,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.banners);

  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();

  const onFinish = async (values: Banner) => {
    const banner = await uploadFile(values.banner);

    const updatedData: Banner = {
      ...values,
      banner,
      link: values.link || undefined,
    };

    if (banner) {
      dispatch(createBannerOne(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          if (!banner_type || banner_type === updatedData.banner_type) {
            setList((prev) => [data.payload, ...prev]);
            setCount((prev) => prev + 1);
          }
          form.resetFields();
          onClose();
        }
      });
    }
  };

  return (
    <ModalUI
      title="Создать баннер"
      open={open}
      onOk={form.submit}
      onCancel={onClose}
      loading={loading || loadingFile}
      okText="Создать"
    >
      <Form
        form={form}
        name="createBannerForm"
        onFinish={onFinish}
        layout="vertical"
        disabled={loading || loadingFile}
        initialValues={bannerState}
      >
        <Form.Item label="Название" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <Input.TextArea style={{ height: 120 }} />
        </Form.Item>
        <Form.Item
          label="Изображение баннера"
          name="banner"
          rules={[SELECTED_FILE]}
        >
          <UploadFileUI form={form} name="banner" />
        </Form.Item>
        <Form.Item
          label="Ссылка"
          name="link"
          rules={[
            {
              validator: validateURL,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Тип баннера" name="banner_type" required>
          <Select options={typeBanners} />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
