// NEWS BARCA EXPERIENCE
import { useEffect, useMemo, useState } from "react";
import { Form, Button, Select } from "antd";
import { LangField } from "@shared/uikit";
import { createfaq } from "@store/slices/faqSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { SELECT_REQ } from "@constants/validateFields";
import { Faq } from "@models/faq";
import { fetchInfoOne } from "@store/slices/infoSlice";
import { Info } from "@models/info";
import { authStore } from "@store/authorization/authStore";
import { LIMIT } from "@constants/variables";
import { ROLES_INFO } from "@features/info/utils";

export const CreateFaq = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.faq);
  const { amount, loading: infoLoading } = useAppSelector(
    (state) => state.info
  );
  const { userRole } = authStore;
  const view_type = userRole === "superAdmin" ? null : ROLES_INFO[userRole];

  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [skip, setSkip] = useState(0);
  const [list, setList] = useState<Info[]>([]);

  const onChangeSkip = () => {
    setSkip((prev) => prev + LIMIT);
  };

  const options = useMemo(
    () =>
      list.map((item) => ({
        value: item.id,
        label: item.title.ru,
      })),
    [list]
  );

  useEffect(() => {
    dispatch(fetchInfoOne({ skip, limit: LIMIT, view_type })).then((data) => {
      const { items } = data.payload as any;
      setList((prev) => (skip === 0 ? items : [...prev, ...items]));
    });
  }, [dispatch, skip]);

  const onFinish = (values: Faq) => {
    dispatch(createfaq(values)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        form.resetFields();
        onFormNotDirty();
      }
    });
  };

  return (
    <>
      <h2>Создать Вопрос - Ответ</h2>
      <div>
        <Form
          name="CreateFaq"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          disabled={loading}
          style={{ maxWidth: 1000 }}
          onValuesChange={onFormDirty}
        >
          <div>
            <Form.Item name="infoPageId" label="FAQ" rules={[SELECT_REQ]}>
              <Select
                style={{ width: "200px" }}
                options={options}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {list.length < amount && (
                      <Button
                        type="text"
                        style={{
                          width: "100%",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        loading={infoLoading}
                        onClick={onChangeSkip}
                      >
                        Ещё...
                      </Button>
                    )}
                  </>
                )}
              />
            </Form.Item>

            <Form.Item name="question" label="Вопрос" required>
              <LangField name="question" />
            </Form.Item>

            <Form.Item name="answer" label="Ответ" required>
              <LangField name="answer" isTextArea />
            </Form.Item>
          </div>
          <br />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={false}
            >
              Создать
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
