import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, Tag } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import styled from "styled-components";
import { Routes } from "@enums/routes";
import { userRole } from "@shared/uikit/SideBar/ui/sideBarItems";
import { AppDispatch } from "@store/index";
import { fetchBanner } from "@store/slices/bannerSlice";

const CardBlock = styled(Card)`
  .ant-card-head-title {
    font-size: 18px;
  }
`;

const styles = {
  fontSize: "22px",
  cursor: "pointer",
};

export const MainPage = () => {
  const navigate = useNavigate();

  const handleClick = (route: string) => {
    navigate(route);
  };

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchBanner({}));
  }, [dispatch]);

  if (userRole === "superAdmin") {
    return (
      <>
        <h3>Инструкция по использованию</h3>
        <Tag color="green" style={{ marginTop: 10, fontSize: "medium" }}>
          Супер-админ
        </Tag>
        <br />
        <br />

        <CardBlock title="Jal-Group">
          <Paragraph>
            <Title style={styles} onClick={() => handleClick(Routes.JAL_GROUP)}>
              Страница для работы с Jal-Group
            </Title>
          </Paragraph>
        </CardBlock>
        <br />

        <CardBlock title="Barça Experience">
          <Paragraph>
            <Title
              style={styles}
              onClick={() => handleClick(Routes.EXPERIENCE_MAIN)}
            >
              Страница для работы с Barça Experience
            </Title>
          </Paragraph>
        </CardBlock>
        <br />

        <CardBlock title="Barça Academy">
          <Paragraph>
            <Title
              style={styles}
              onClick={() => handleClick(Routes.BISHKEK_ACADEMY)}
            >
              Страница для работы с Barça Academy
            </Title>
          </Paragraph>
        </CardBlock>
        <br />

        <CardBlock title="Barça Shop">
          <Paragraph>
            <Title style={styles} onClick={() => handleClick(Routes.SHOP)}>
              Страница для работы с Barça Shop
            </Title>
          </Paragraph>
        </CardBlock>
      </>
    );
  } else if (userRole === "adminExperience") {
    return (
      <>
        <h3>Инструкция по использованию</h3>
        <Tag color="green" style={{ marginTop: 10, fontSize: "medium" }}>
          Администратор Barça Experience
        </Tag>
        <br />
        <br />

        <CardBlock title="Barça Experience">
          <Paragraph>
            <Title
              style={styles}
              onClick={() => handleClick(Routes.EXPERIENCE_VACANCIES)}
            >
              Страница для работы с Barça Experience
            </Title>
          </Paragraph>
        </CardBlock>
        <br />

        <CardBlock title="Barça Academy">
          <Paragraph>
            <Title
              style={styles}
              onClick={() => handleClick(Routes.BISHKEK_ACADEMY)}
            >
              Страница для работы с Barça Academy
            </Title>
          </Paragraph>
        </CardBlock>
      </>
    );
  } else if (userRole === "adminStore") {
    return (
      <>
        <h3>Инструкция по использованию</h3>
        <Tag color="green" style={{ marginTop: 10, fontSize: "medium" }}>
          Администратор Barça Store
        </Tag>
        <br />
        <br />

        <CardBlock title="Barça Store">
          <Paragraph>
            <Title style={styles} onClick={() => handleClick(Routes.SHOP)}>
              Страница для работы с Barça Store
            </Title>
          </Paragraph>
        </CardBlock>
      </>
    );
  } else if (userRole === "adminJalgroup") {
    return (
      <>
        <h3>Инструкция по использованию</h3>
        <Tag color="green" style={{ marginTop: 10, fontSize: "medium" }}>
          Администратор Jal Group
        </Tag>
        <br />
        <br />

        <CardBlock title="Jal Group">
          <Paragraph>
            <Title style={styles} onClick={() => handleClick(Routes.JAL_GROUP)}>
              Страница для работы с Jal Group
            </Title>
          </Paragraph>
        </CardBlock>
      </>
    );
  }
};
