import { Tabs } from "antd";
import CreateVideo from "@features/video/CreateVideo/CreateVideo";
import Videos from "@features/video/VideoList/Videos";
import { useURLSearchParams } from "@shared/libs";
import Title from "antd/es/typography/Title";

const VideoPage = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Title>Видео</Title>
      <Tabs
        items={[
          {
            label: "Список видео",
            key: "1",
            children: <Videos />,
          },
          {
            label: "Создать видео",
            key: "2",
            children: <CreateVideo />,
          },
        ]}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};

export default VideoPage;
