import { Tabs, TabsProps } from "antd";
import { ActiveTrainersBish } from "./ActiveTrainersBish";
import { ArchivedTrainers } from "./ArchivedTrainersBish";
import { DeletedTrainers } from "./DeletedTrainersBish";
import { useURLSearchParams } from "@shared/libs";

const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Активные тренера",
    children: <ActiveTrainersBish type="bishkek" />,
  },
  {
    key: "2",
    label: "Архив тренеров",
    children: <ArchivedTrainers type="bishkek" />,
  },
  {
    key: "3",
    label: "Удаленные тренера",
    children: <DeletedTrainers type="bishkek" />,
  },
];

export const TrainersTableBish = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("trainers")}
        activeKey={activeTabsKey["trainers"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
