import { Tabs } from "antd";
import AdminList from "@features/admins/AdminList/AdminList";
import CreateAdmin from "@features/admins/CreateAdmin/CreateAdmin";
import Title from "antd/es/typography/Title";
import { useURLSearchParams } from "@shared/libs";

const AdminsPage = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Title>Администраторы</Title>
      <Tabs
        items={[
          {
            label: "Администраторы",
            key: "1",
            children: <AdminList />,
          },
          {
            label: "Создать администратора",
            key: "2",
            children: <CreateAdmin />,
          },
        ]}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};

export default AdminsPage;
