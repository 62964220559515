/* eslint-disable consistent-return */
// useSubmitData.ts
import { SignInType } from "src/models";
import { notification } from "antd";
import { authStore } from "@store/authorization/authStore";
import { useNavigate } from "react-router-dom";

const useSubmitData = () => {
  const { signIn } = authStore;
  const navigate = useNavigate();

  const handleSubmit = async (values: SignInType) => {
    try {
      const res = await signIn(values);

      if (res) {
        navigate("/");
        window.location.reload();
      }
    } catch (error: unknown) {
      notification.error({
        message: "Произошла ошибка",
      });
    }
  };
  return { handleSubmit };
};

export default useSubmitData;
