import { Form, Input } from "antd";
import styles from "./ResourseBlock.module.scss";
import TextArea from "antd/es/input/TextArea";
import { REQUIRED, validateURL } from "@constants/validateFields";

export const ResourceBlock = ({ field }) => {
  return (
    <div className={styles.resources}>
      <Form.Item
        {...field}
        label="Название(RU)"
        style={{ marginBottom: 15 }}
        name={[field.name, "title", "ru"]}
        fieldKey={[field.fieldKey, "title", "ru"]}
        rules={[REQUIRED]}
        key={`${field.key}-title-ru`}
      >
        <Input width={"100%"} />
      </Form.Item>
      <Form.Item
        {...field}
        label="Название(KY)"
        style={{ marginBottom: 15 }}
        name={[field.name, "title", "ky"]}
        fieldKey={[field.fieldKey, "title", "ky"]}
        rules={[REQUIRED]}
        key={`${field.key}-title-ky`}
      >
        <Input />
      </Form.Item>
      <Form.Item
        {...field}
        label="Название(EN)"
        style={{ marginBottom: 15 }}
        name={[field.name, "title", "en"]}
        fieldKey={[field.fieldKey, "title", "en"]}
        rules={[REQUIRED]}
        key={`${field.key}-title-en`}
      >
        <Input />
      </Form.Item>

      <Form.Item
        {...field}
        label="Текст (RU)"
        style={{ marginBottom: 15 }}
        name={[field.name, "text", "ru"]}
        fieldKey={[field.fieldKey, "text", "ru"]}
        rules={[REQUIRED]}
        key={`${field.key}-text-ru`}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        {...field}
        label="Текст (KY)"
        style={{ marginBottom: 15 }}
        name={[field.name, "text", "ky"]}
        fieldKey={[field.fieldKey, "text", "ky"]}
        rules={[REQUIRED]}
        key={`${field.key}-text-ky`}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        {...field}
        label="Текст (EN)"
        style={{ marginBottom: 15 }}
        name={[field.name, "text", "en"]}
        fieldKey={[field.fieldKey, "text", "en"]}
        rules={[REQUIRED]}
        key={`${field.key}-text-en`}
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item
        {...field}
        label="Ссылка ресурса"
        style={{ marginBottom: 15 }}
        name={[field.name, "btn_link"]}
        fieldKey={[field.fieldKey, "btn_link"]}
        rules={[REQUIRED, { validator: validateURL }]}
        key={`${field.key}-btn_link`}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
