import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import { useURLSearchParams } from "@shared/libs";
import { Championship, CreateChampionship } from "@features/championship";

const ChampionshipPage = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Title>Чемпионат Academy</Title>
      <Tabs
        items={[
          {
            label: "Чемпионат",
            children: <Championship />,
            key: "1",
          },
          {
            label: "Создание чемпионата",
            children: <CreateChampionship />,
            key: "2",
          },
        ]}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};

export default ChampionshipPage;
