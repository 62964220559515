import { useEffect, useState } from "react";
import {
  AdminRole,
  deleteAdmin,
  fetchAdmins,
  setCurrentPage,
} from "@store/slices/adminSlice";
import { Button, Popconfirm, Table, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { LIMIT } from "@constants/variables";
import { UpdatePassword } from "./UpdatePassword";

const AdminList = () => {
  const dispatch = useAppDispatch();
  const { admins, loading, amount, params } = useAppSelector(
    (state) => state.admins
  );
  const { skip, page } = params;

  const [selectedAdminId, setSelectedAdminId] = useState("");

  const columns = [
    {
      title: "Имя",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      render: (role: AdminRole) => {
        if (role === "adminExperience") {
          return <Tag>Barça Experience</Tag>;
        } else if (role === "adminStore") {
          return <Tag>Barça Store</Tag>;
        } else if (role === "adminJalgroup") {
          return <Tag>Jal Group</Tag>;
        } else {
          return null;
        }
      },
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "actions",
      render: (_, record) => {
        const onEditPassword = () => {
          setSelectedAdminId(record.id);
        };

        const handleDelete = () => {
          dispatch(deleteAdmin(record.id));
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Button onClick={onEditPassword}>Сбросить пароль</Button>

            <Popconfirm
              onConfirm={handleDelete}
              title="Вы уверены что хотите удалить?"
            >
              <Button danger type="primary">
                Удалить
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const onCloseModal = () => {
    setSelectedAdminId("");
  };

  useEffect(() => {
    dispatch(fetchAdmins({ skip, limit: LIMIT }));
  }, [dispatch, skip]);

  return (
    <div>
      <Table
        dataSource={admins}
        columns={columns}
        rowKey="id"
        scroll={{ x: true }}
        loading={loading}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {selectedAdminId && (
        <UpdatePassword idAdmin={selectedAdminId} onClose={onCloseModal} />
      )}
    </div>
  );
};

export default AdminList;
