import { ColumnType } from "antd/lib/table";
import { Vacancy } from "@models/vacancies";
import styles from "./style.module.scss";
import { MultiLangUI } from "@shared/uikit";

const columns: ColumnType<Vacancy>[] = [
  {
    title: "Title (RU)",
    dataIndex: ["title"],
    key: "title_ru",
    render: (title) => <MultiLangUI data={title} />,
  },
  {
    title: "Тип вакансии",
    dataIndex: "vacancy_type",
    key: "vacancy_type",
    render: (vacancy_type) => {
      return vacancy_type === "jalgroup" ? "Jal Group" : "Barça experience";
    },
  },
  {
    title: "Опыт",
    dataIndex: "experience",
    key: "experience",
    render: (experience) => {
      let exp = "";

      switch (experience) {
        case "less-one":
          exp = "Менее 1 года";
          break;
        case "one_three":
          exp = "1-3 года";
          break;
        case "three_six":
          exp = "3-6 лет";
          break;
        default:
          exp = "Без опыта";
      }
      return <div className={styles.experience}>{exp}</div>;
    },
  },
  {
    title: "Занятость",
    dataIndex: "employment",
    key: "employment",
    render: (employment) => {
      return (
        <div className={styles.busy}>
          {employment === "full-time"
            ? "Полный рабочий день"
            : "Неполный рабочий день"}
        </div>
      );
    },
  },
  {
    title: "Тип работы",
    dataIndex: "work_type",
    key: "work_type",
    render: (work_type) => {
      return work_type === "remote" ? "удаленно" : "офис";
    },
  },
  {
    title: "Город",
    dataIndex: "vacancy_city",
    key: "vacancy_city",
    render: (vacancy_city) => {
      return (
        <div className={styles.city}>
          {vacancy_city === "bishkek" ? "Бишкек" : "Джалал-абад"}
        </div>
      );
    },
  },
  {
    title: "Мин. зарплата",
    dataIndex: "min_salary",
    key: "min_salary",
    render: (min_salary) => <div className={styles.salary}>{min_salary}</div>,
  },
  {
    title: "Макс. зарплата",
    dataIndex: "max_salary",
    key: "max_salary",
    render: (max_salary) => <div className={styles.salary}>{max_salary}</div>,
  },
];

export default columns;
