import { useEffect, useState } from "react";
import {
  Image,
  Table,
  Button,
  TableColumnsType,
  Popconfirm,
  Select,
} from "antd";
import {
  fetchBanner,
  deleteBanner,
  Banner,
  setCurrentPage,
  setParamsType,
  resetParams,
} from "@store/slices/bannerSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { LIMIT } from "@constants/variables";
import { typeBanners } from "./consts";
import { UpdateBannerModal } from "./UpdateBanner";
import styles from "./style.module.scss";

const BannersList = () => {
  const dispatch = useAppDispatch();
  const { loading, bannerList, amount, params } = useAppSelector(
    (state) => state.banners
  );
  const { page, skip, type } = params;

  const [editBanner, setEditBanner] = useState<Banner>(null);

  const columns: TableColumnsType<Banner> = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title) => <div className={styles.title}>{title}</div>,
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      render: (desc) => <div className={styles.desc}>{desc}</div>,
    },
    {
      title: "Изображение",
      dataIndex: "banner",
      key: "banner",
      render: (imageUrl) => (
        <Image src={imageUrl} alt="Banner" className={styles.img} />
      ),
    },
    {
      title: "Тип",
      dataIndex: "banner_type",
      key: "banner_type",
      render: (banner_type) => {
        const typeBanner = {
          news: "Новости",
          service: "Сервисы",
          service_item: "Часть сервиса",
          "experience-mainpage": "Главная cтр. Experience",
        };

        return <div className={styles.title}>{typeBanner[banner_type]}</div>;
      },
    },
    {
      title: "Ссылка",
      dataIndex: "link",
      key: "link",
      render: (link) =>
        link ? (
          <a href={link} target="_blank" rel="noreferrer">
            Перейти по ссылке
          </a>
        ) : (
          "Ссылки нет"
        ),
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (rowData) => {
        const handleEditModal = () => {
          setEditBanner(rowData);
        };

        const handleDelete = async () => {
          await dispatch(deleteBanner(rowData));
        };

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "fit-content",
            }}
          >
            <Button onClick={handleEditModal}>Редактировать</Button>
            <Popconfirm title="Удалить баннер?" onConfirm={handleDelete}>
              <Button danger>Удалить</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeType = (value) => {
    dispatch(setParamsType(value));
  };

  const onClose = () => {
    setEditBanner(null);
  };

  useEffect(() => {
    dispatch(fetchBanner({ skip, limit: LIMIT, banner_type: type }));
  }, [dispatch, skip, type]);

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <div>
      <div className={styles.header}>
        <div>Фильтр</div>
        <Select
          className={styles.selectType}
          value={type}
          onChange={onChangeType}
        >
          <Select.Option>Все типы</Select.Option>
          {typeBanners.map((yea) => (
            <Select.Option key={yea.value} value={yea.value}>
              {yea.label}
            </Select.Option>
          ))}
        </Select>
      </div>

      <Table
        dataSource={bannerList}
        columns={columns}
        loading={loading}
        scroll={{ x: true }}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editBanner && <UpdateBannerModal data={editBanner} onClose={onClose} />}
    </div>
  );
};

export default BannersList;
