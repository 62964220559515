/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { BannerApi } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { CommonParams } from "@models/common";
import { deleteFile } from "@constants/api/filesUpload";
import { LIMIT } from "@constants/variables";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

export type BannerType =
  | "news"
  | "service"
  | "service_item"
  | "experience-mainpage";

export interface Banner {
  id?: string;
  title?: string;
  description?: string;
  banner_type: BannerType;
  banner: string;
  link?: string;
}

interface BannerState {
  bannerList: Banner[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    type?: BannerType;
    skip: number;
    page: number;
  };
}

export const fetchBanner = createAsyncThunk(
  SliceConstants.fetchBanner,
  async (
    params: { banner_type?: BannerType } & CommonParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.get(BannerApi.banner, {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createBanner = createAsyncThunk(
  SliceConstants.createBanner,
  async (data: Banner, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(BannerApi.banner, data);

      const store = getState() as RootState;
      const { type, page } = store.banners.params;

      if (createValidate([type], page)) {
        await dispatch(fetchBanner({ skip: 0, limit: LIMIT }));
      } else {
        dispatch(resetParams());
      }

      notification.success({
        message: "Баннер создан",
      });

      return response.data;
    } catch (error: any) {
      await deleteFile(data.banner);
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createBannerOne = createAsyncThunk(
  SliceConstants.createBannerOne,
  async (data: Banner, { rejectWithValue }) => {
    try {
      const response = await $api.post(BannerApi.banner, data);

      notification.success({
        message: "Баннер создан",
      });

      return response.data;
    } catch (error: any) {
      await deleteFile(data.banner);
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateBanner = createAsyncThunk(
  SliceConstants.updateBanner,
  async ({ id, ...data }: Banner, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.put(`${BannerApi.banner}/${id}`, data);

      const store = getState() as RootState;
      const { type, skip } = store.banners.params;
      await dispatch(fetchBanner({ skip, limit: LIMIT, banner_type: type }));

      notification.success({
        message: "Баннер обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteBanner = createAsyncThunk(
  SliceConstants.deleteBanner,
  async ({ id }: Banner, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${BannerApi.banner}/delete/${id}`);

      const store = getState() as RootState;
      const { bannerList, params } = store.banners;
      const { type, skip, page } = params;

      const currentPage = deleteValidate(skip, bannerList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, bannerList);

        await dispatch(
          fetchBanner({ skip: newSkip, limit: LIMIT, banner_type: type })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Баннер удален",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: BannerState = {
  bannerList: [],
  amount: 0,
  loading: false,
  error: null,
  params: {
    page: 1,
    skip: 0,
    type: null,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params.type = action.payload;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetParams(state) {
      state.params.type = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchBanner, (state: any, action: any) => {
      state.bannerList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(builder, createBanner, (state: any, action: any) => {});

    handleAsyncAction(
      builder,
      createBannerOne,
      (state: any, action: any) => {}
    );

    handleAsyncAction(builder, deleteBanner, (state: any, action: any) => {});

    handleAsyncAction(builder, updateBanner, (state: any, action: any) => {});
  },
});

export const { setParamsType, setCurrentPage, resetParams } =
  bannerSlice.actions;
export default bannerSlice.reducer;
