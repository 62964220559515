import { useEffect, useState } from "react";
import { Button, Image, Popconfirm, Table, TableColumnsType } from "antd";
import { LIMIT } from "@constants/variables";
import { VideoType, deleteVideo, fetchVideo } from "@store/slices/videoSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { UpdateVideo } from "./UpdateVideo";
import { resetAll, setPagination } from "@store/slices/paramsSlice";

const Videos = () => {
  const dispatch = useAppDispatch();
  const { loading, videoList, amount } = useAppSelector((state) => state.video);
  const { page, skip } = useAppSelector((state) => state.params);

  const [editingVideo, setEditingVideo] = useState<VideoType>(null);

  const columns: TableColumnsType<VideoType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Видео",
      dataIndex: "video",
      key: "video",
      render: (video) => (
        <a href={video} target="_blank" rel="noreferrer">
          Перейти по ссылке
        </a>
      ),
    },
    {
      title: "Превью",
      dataIndex: "preview_path",
      key: "preview_path",
      render: (preview_path) => {
        if (preview_path) {
          return (
            <Image
              src={preview_path}
              alt="Video Preview"
              width={120}
              style={{ maxHeight: 120, objectFit: "contain" }}
            />
          );
        }
        return "Нет фото";
      },
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (text: string, record: any) => {
        const handleEdit = () => {
          setEditingVideo(record);
        };

        const handleDelete = async () => {
          await dispatch(deleteVideo(record));
        };

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "fit-content",
            }}
          >
            <Button onClick={handleEdit}>Редактировать</Button>
            <Popconfirm title="Удалить видео?" onConfirm={handleDelete}>
              <Button danger>Удалить</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setPagination(page));
  };

  const onClose = () => {
    setEditingVideo(null);
  };

  useEffect(() => {
    dispatch(fetchVideo({ skip, limit: LIMIT }));
  }, [dispatch, skip]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={videoList}
        rowKey="id"
        scroll={{ x: true }}
        loading={loading}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editingVideo && <UpdateVideo data={editingVideo} onClose={onClose} />}
    </div>
  );
};

export default Videos;
