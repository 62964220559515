import { FC, useState } from "react";
import {
  Form,
  Button,
  Input,
  Space,
  Tag,
  Tooltip,
  notification,
  Select,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { LangField, ModalUI } from "@shared/uikit";
import { updateContact } from "@store/slices/contactSlice";
import { Contact } from "@models/contacts";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import {
  EMAIL_CORRECT,
  REQUIRED,
  SELECT_REQ,
  validateURL,
} from "@constants/validateFields";
import { authStore } from "@store/authorization/authStore";

const { Option } = Select;

type UpdateContactProps = {
  data: Contact;
  onClose: () => void;
};

export const UpdateContact: FC<UpdateContactProps> = ({ data, onClose }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.contacts);
  const { userRole } = authStore;

  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [isSite, setIsSite] = useState(!!data.site);
  const [isNote, setIsNote] = useState(!!data.note);

  const onFinish = async () => {
    try {
      const { siteTitle, email, ...values } = await form.validateFields();

      const updatedData: Contact = {
        ...values,
        id: data.id,
        email: email || undefined,
        site: siteTitle
          ? {
              ...values.site,
              title: siteTitle,
            }
          : undefined,
      };

      dispatch(updateContact(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          onClose();
        }
      });
    } catch (error) {
      notification.error({
        message: "Заполните все обязательные поля!",
      });
    }
  };

  return (
    <ModalUI
      title="Редактировать контакт"
      open
      onCancel={onClose}
      onOk={onFinish}
      loading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading}
        initialValues={data}
        onValuesChange={onFormDirty}
      >
        <Form.Item name="title" label="Название" required>
          <LangField name="title" />
        </Form.Item>

        <Form.Item name="adreses" label="Адрес" required>
          <LangField name="adreses" />
        </Form.Item>

        {userRole === "superAdmin" && (
          <Form.Item name="type" label="Тип" rules={[SELECT_REQ]}>
            <Select placeholder="Выберите тип">
              <Option value="academy">Barça Academy</Option>
              <Option value="store">Barça Store</Option>
            </Select>
          </Form.Item>
        )}

        <Form.Item name="email" label="Email" rules={[EMAIL_CORRECT]}>
          <Input />
        </Form.Item>

        <Form.Item name="fax" label="Факс">
          <Input />
        </Form.Item>

        {!isSite && (
          <>
            <Button type="default" onClick={() => setIsSite(true)}>
              Добавить сайт
            </Button>
            <br />
            <br />
          </>
        )}
        {isSite && (
          <>
            <Form.Item name="siteTitle" label="Название сайта" required>
              <LangField name="siteTitle" />
            </Form.Item>

            <Form.Item
              name={["site", "url"]}
              label="Ссылка на сайт"
              rules={[REQUIRED, { validator: validateURL }]}
            >
              <Input />
            </Form.Item>
          </>
        )}

        {!isNote && (
          <>
            <Button type="default" onClick={() => setIsNote(true)}>
              Добавить примечание
            </Button>
            <br />
            <br />
          </>
        )}
        {isNote && (
          <Form.Item name="note" label="Примечание" required>
            <LangField name="note" />
          </Form.Item>
        )}

        <Form.List name="phone">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                <Space
                  key={key}
                  style={{
                    display: "block",
                    marginBottom: 8,
                    width: "100%",
                  }}
                  align="baseline"
                >
                  <Tag color="blue-inverse" style={{ marginBottom: 10 }}>
                    Номер телефона ({idx + 1})
                  </Tag>

                  <Form.Item
                    {...restField}
                    name={[name, "phoneNumber"]}
                    fieldKey={[fieldKey, "phoneNumber"]}
                    label="Номер телефона"
                    rules={[REQUIRED]}
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="Пример: +996(555)12-06-56" />
                  </Form.Item>

                  {idx > 0 && (
                    <Button danger onClick={() => remove(name)}>
                      Удалить
                    </Button>
                  )}
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add({ phoneNumber: "" })}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить номер
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.List name="workingHours">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                <Space
                  key={key}
                  style={{
                    display: "block",
                    marginBottom: 8,
                    width: "100%",
                  }}
                  align="baseline"
                >
                  <Tag color="cyan-inverse" style={{ marginBottom: 10 }}>
                    Рабочее время ({idx + 1})
                  </Tag>

                  <Form.Item
                    {...restField}
                    name={[name, "ru"]}
                    fieldKey={[fieldKey, "ru"]}
                    label={
                      <div>
                        <span style={{ marginRight: 10 }}>(RU)</span>
                        <Tooltip title="Время работы: ( по будням, выходным, по праздникам )">
                          <Tag color="green">
                            Пример ( скобки необязательны )
                          </Tag>
                        </Tooltip>
                      </div>
                    }
                    rules={[REQUIRED]}
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="Введите рабочее время (RU)" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "ky"]}
                    fieldKey={[fieldKey, "ky"]}
                    label={
                      <div>
                        <span style={{ marginRight: 10 }}>(KY)</span>
                        <Tooltip title="дүйшөмбү: 09:00 - 18:30">
                          <Tag color="green">Мисал</Tag>
                        </Tooltip>
                      </div>
                    }
                    rules={[REQUIRED]}
                  >
                    <Input placeholder="Введите рабочее время (KY)" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "en"]}
                    fieldKey={[fieldKey, "en"]}
                    label={
                      <div>
                        <span style={{ marginRight: 10 }}>(EN)</span>
                        <Tooltip title="Mon-Sat: 09:00 - 18:30">
                          <Tag color="green">Example</Tag>
                        </Tooltip>
                      </div>
                    }
                    rules={[REQUIRED]}
                  >
                    <Input placeholder="Введите рабочее время (EN)" />
                  </Form.Item>

                  {idx > 0 && (
                    <Button danger onClick={() => remove(name)}>
                      Удалить
                    </Button>
                  )}
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add({ ru: "", en: "", ky: "" })}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить рабочее время
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </ModalUI>
  );
};
