import { SignInResponseType, SignInType } from "@models/login";
import axios, { AxiosResponse } from "axios";
import { AuthApi } from "@constants/api";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export class AuthService {
  static async signIn(
    payload: SignInType
  ): Promise<AxiosResponse<SignInResponseType>> {
    const { username, password } = payload;
    return axios.post(BASE_URL + AuthApi.Login, { username, password });
  }
}
