/* eslint-disable no-param-reassign */
import { SliceConstants } from "@constants/slices";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateAdminApi } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { notification } from "antd";
import { LIMIT } from "@constants/variables";
import { CommonParams } from "@models/common";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

export type AdminRole = "adminExperience" | "adminStore" | "adminJalgroup";

export interface AdminType {
  id?: string | number;
  username: string;
  password: string;
  role: AdminRole;
}
export interface AdminResetPasswordType {
  id: number | string;
  password: string;
}

type AdminState = {
  admins: AdminType[];
  amount: number;
  loading: boolean;
  error?: string;
  params: {
    skip: number;
    page: number;
  };
};

export const fetchAdmins = createAsyncThunk(
  SliceConstants.fetchAdmins,
  async (params: CommonParams, { rejectWithValue }) => {
    try {
      const response = await $api.get("/admin/admins", {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createAdmin = createAsyncThunk(
  SliceConstants.createAdmin,
  async (adminData: AdminType, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(CreateAdminApi.createAdmin, adminData);

      const store = getState() as RootState;
      const { page } = store.admins.params;

      if (createValidate([], page)) {
        await dispatch(
          fetchAdmins({
            skip: 0,
            limit: LIMIT,
          })
        );
      } else {
        dispatch(resetPagination());
      }

      notification.success({
        message: "Админ создан",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  SliceConstants.deleteAdmin,
  async (id: string, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`/admin/admins/delete/${id}`);

      const store = getState() as RootState;
      const { admins, params } = store.admins;
      const { skip, page } = params;

      const currentPage = deleteValidate(skip, admins, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, admins);

        await dispatch(
          fetchAdmins({
            skip: newSkip,
            limit: LIMIT,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Админ удалён",
      });

      return id;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);
export const resetPasswordAdmin = createAsyncThunk(
  SliceConstants.resetPassword,
  async (data: AdminResetPasswordType, { rejectWithValue }) => {
    try {
      await $api.post("/admin/admins/reset-password", data);

      notification.success({
        message: "Пароль изменен",
      });

      return data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: AdminState = {
  admins: [],
  amount: 0,
  loading: false,
  error: null,
  params: {
    page: 1,
    skip: 0,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const adminSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetPagination(state) {
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchAdmins, (state: any, action: any) => {
      state.admins = action.payload.items;
      state.amount = action.payload.amount;
    });
    handleAsyncAction(builder, createAdmin, (state: any, action: any) => {});
    handleAsyncAction(builder, deleteAdmin, (state: any, action: any) => {});
    handleAsyncAction(
      builder,
      resetPasswordAdmin,
      (state: any, action: any) => {}
    );
  },
});

export const { setCurrentPage, resetPagination } = adminSlice.actions;
export default adminSlice.reducer;
