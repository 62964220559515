import { Tabs, TabsProps } from "antd";
import Title from "antd/es/typography/Title";
import { useAppDispatch, useURLSearchParams } from "@shared/libs";
import {
  AcceptReplies,
  ActiveReplies,
  RejectReplies,
} from "@features/vacancyReplies";
import { useEffect } from "react";
import { resetParams } from "@store/slices/vacancyReplySlice";

export const tabItems: TabsProps["items"] = [
  {
    label: "Активные отклики",
    children: <ActiveReplies />,
    key: "1",
  },
  {
    label: "Одобренные отклики",
    children: <AcceptReplies />,
    key: "2",
  },
  {
    label: "Отклоненные отклики ",
    children: <RejectReplies />,
    key: "3",
  },
];

const VacanciesReplyPage = () => {
  const dispatch = useAppDispatch();
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <div>
      <Title>Отклики на вакансии</Title>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
        destroyInactiveTabPane
      />
    </div>
  );
};

export default VacanciesReplyPage;
