import { Info } from "@features/info";
import React from "react";

export const InfoPage = () => {
  return (
    <div>
      <Info />
    </div>
  );
};
