import React from "react";
import { Card } from "antd";
import { styled } from "styled-components";
import { FormComponent } from "src/features/auth/form/Form";

const AuthBlock = styled.div`
  margin: 0 auto;
  max-width: 500px;
  display: grid;
  align-items: center;
  height: 100vh;
  padding: 50px 0;
  text-align: center;
`;

const CardBlock = styled(Card)`
  .ant-card-head-title {
    font-size: 20px;
  }
`;

export const AuthLayout: React.FC = () => {
  return (
    <AuthBlock>
      <CardBlock className="auth-card" title="Авторизация">
        <FormComponent />
      </CardBlock>
    </AuthBlock>
  );
};
