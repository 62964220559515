import { useEffect } from "react";
import { Tabs, TabsProps } from "antd";
import Title from "antd/es/typography/Title";
import { CreateNews } from "@features/news/CreateNews";
import { NewsTable } from "@features/news/NewsTable";
import { useAppDispatch, useURLSearchParams } from "@shared/libs";
import { resetParams } from "@store/slices/newsSlice";

export const NewsJalGroup = () => {
  const dispatch = useAppDispatch();
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Таблица новостей",
      children: <NewsTable type="jalgroup" />,
    },
    {
      key: "2",
      label: "Создать новость",
      children: <CreateNews type="jalgroup" />,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <div>
      <Title>Новости JAl Group</Title>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("table")}
        activeKey={activeTabsKey["table"]}
      />
    </div>
  );
};
