/* eslint-disable @typescript-eslint/ban-ts-comment */
export const NEWSTYPES = {
  bishkek: [
    { value: "bishkek", label: "Академия Бишкек" },
    { value: "jalal-abad", label: "Академия Джалал-абад" },
    { value: "jalgroup", label: "Джал Групп" },
    { value: "common", label: "Общий" },
  ],
  jalgroup: [{ value: "jalgroup", label: "Джал Групп" }],
};
