import { useEffect } from "react";
import { Input, Popconfirm, Table, Tooltip } from "antd";
import {
  activateProducts,
  deleteProduct,
  fetchProducts,
  resetPagination,
  setCurrentPage,
  setNameSearch,
  setParamsType,
} from "@store/slices/productsSlice";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { useAppDispatch, useAppSelector, useDebounce } from "@shared/libs";
import { LIMIT } from "@constants/variables";
import { columnsData } from "../columns";

export const DeletedProduct = () => {
  const dispatch = useAppDispatch();
  const { ProductList, loading, amount, params } = useAppSelector(
    (state) => state.products
  );
  const { page, skip, name } = params;

  const debouncedSearchQuery = useDebounce(name, () => {
    dispatch(resetPagination());
  });

  const columns = [
    ...columnsData,
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record) => {
        const handleActivate = () => {
          dispatch(activateProducts({ ids: [record.id] }));
        };

        const deleteHard = () => {
          dispatch(deleteProduct(record.id));
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Popconfirm
              title="Активировать продукт?"
              onConfirm={handleActivate}
            >
              <Tooltip title="Активировать" placement="bottom">
                <CheckCircleOutlined />
              </Tooltip>
            </Popconfirm>

            <Popconfirm
              title="Удалить продукт полностью?"
              onConfirm={deleteHard}
            >
              <Tooltip title="Удалить навсегда" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleSearchChange = (e) => {
    dispatch(setNameSearch(e.target.value));
  };

  const onPageChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    dispatch(
      fetchProducts({
        status: "deleted",
        limit: LIMIT,
        skip: skip,
        name: debouncedSearchQuery,
      })
    );
  }, [page, skip, dispatch, debouncedSearchQuery]);

  useEffect(() => {
    dispatch(setParamsType({ type: "status", value: "deleted" }));
  }, [dispatch]);

  return (
    <div>
      <Input
        placeholder="Поиск по названию"
        onChange={handleSearchChange}
        value={name}
      />
      <br />
      <br />
      <Table
        dataSource={ProductList}
        columns={columns}
        scroll={{ x: true }}
        loading={loading}
        pagination={{
          current: page,
          pageSize: LIMIT,
          total: amount,
          onChange: onPageChange,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
