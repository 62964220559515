import { ActiveTrainersBish } from "@features/trainers/trainersBishkek/TrainersTable/ActiveTrainersBish";
import { ArchivedTrainers } from "@features/trainers/trainersBishkek/TrainersTable/ArchivedTrainersBish";
import { DeletedTrainers } from "@features/trainers/trainersBishkek/TrainersTable/DeletedTrainersBish";
import { useURLSearchParams } from "@shared/libs";
import { Tabs, TabsProps } from "antd";

const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Активные тренера",
    children: <ActiveTrainersBish type="jalal-abad" />,
  },
  {
    key: "2",
    label: "Архив тренеров",
    children: <ArchivedTrainers type="jalal-abad" />,
  },
  {
    key: "3",
    label: "Удаленные тренера",
    children: <DeletedTrainers type="jalal-abad" />,
  },
];

export const TrainersTableJal = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("trainers")}
        activeKey={activeTabsKey["trainers"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
