export const typeBanners = [
  {
    label: "Новости",
    value: "news",
  },
  {
    label: "Сервис",
    value: "service",
  },
  {
    label: "Часть сервиса",
    value: "service_item",
  },
  {
    label: "Главная страница experience",
    value: "experience-mainpage",
  },
];
