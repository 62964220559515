import { useEffect } from "react";
import { Popconfirm, Table, Tooltip } from "antd";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  activateVacancies,
  deleteVacancies,
  fetchVacancies,
  setCurrentPage,
  setParamsType,
} from "@store/slices/vacanciesSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { LIMIT } from "@constants/variables";
import columns from "../columns";
import { useFilter } from "../useFilter";
import { Filters } from "../Filters";

export const DeletedVacancies = ({ type }) => {
  const dispatch = useAppDispatch();
  const { vacanciesList, loading, amount, params } = useAppSelector(
    (state) => state.vacancies
  );
  const { skip, page } = params;

  const { experience, employment, work_type, vacancyCity, ...others } =
    useFilter();

  const updatedColumns = [
    ...columns,
    {
      title: "Действия",
      dataIndex: "",
      key: "",
      render: (record: any) => {
        const deleteHard = () => {
          dispatch(deleteVacancies(record.id));
        };

        const handleActivate = () => {
          dispatch(activateVacancies({ ids: [record.id] }));
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Popconfirm
              title="Активировать вакансию?"
              onConfirm={handleActivate}
            >
              <Tooltip title="Активировать" placement="bottom">
                <CheckCircleOutlined />
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title="Удалить вакансию полностью?"
              onConfirm={deleteHard}
            >
              <Tooltip title="Удалить навсегда" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    dispatch(
      fetchVacancies({
        skip,
        limit: LIMIT,
        type,
        experience,
        employment,
        work_type,
        status: "deleted",
        vacancyCity,
      })
    );
  }, [dispatch, employment, experience, skip, type, vacancyCity, work_type]);

  useEffect(() => {
    dispatch(setParamsType({ type: "vacancy_type", value: type }));
    dispatch(setParamsType({ type: "status", value: "deleted" }));
  }, [dispatch, type]);

  return (
    <div>
      <Filters
        experience={experience}
        employment={employment}
        work_type={work_type}
        vacancyCity={vacancyCity}
        {...others}
      />

      <Table
        dataSource={vacanciesList}
        loading={loading}
        columns={updatedColumns}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
