import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  deleteCollection,
  fetchCollection,
  setCurrentPage,
} from "@store/slices/collectionSlice";
import { Image, Popconfirm, Table, Tag, Tooltip } from "antd";
import { UpdateModalCollection } from "./UpdataeModalCollection";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { LIMIT } from "@constants/variables";
import { MultiLangUI } from "@shared/uikit";

export const CollectionTable = () => {
  const dispatch = useAppDispatch();
  const { loading, collectionList, amount, params } = useAppSelector(
    (state) => state.collection
  );
  const { page, skip } = params;

  const [editingCollection, setEditingCollection] = useState(null);

  useEffect(() => {
    dispatch(fetchCollection({ skip, limit: LIMIT }));
  }, [dispatch, skip]);

  const columns = [
    {
      title: "Фото",
      dataIndex: "image",
      key: "image",
      render: (image) => {
        return <Image src={image} width={120} />;
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (name) => {
        return <MultiLangUI data={name} />;
      },
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      render: (description) => {
        return <MultiLangUI data={description} />;
      },
    },
    {
      title: "Главная",
      dataIndex: "isMain",
      key: "isMain",
      render: (isMain) => {
        return (
          <div>
            {isMain ? <Tag color="green">Да</Tag> : <Tag color="red">Нет</Tag>}
          </div>
        );
      },
    },
    {
      title: "В рекомендациях?",
      dataIndex: "isRecommendation",
      key: "isRecommendation",
      render: (isRecommendation) => {
        return (
          <div>
            {isRecommendation ? (
              <Tag color="green">Да</Tag>
            ) : (
              <Tag color="red">Нет</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record) => {
        const handleEdit = () => {
          setEditingCollection(record);
        };

        const handleDeleteHard = () => {
          dispatch(deleteCollection(record.id));
        };
        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Редактировать" placement="bottom">
              <EditOutlined onClick={handleEdit} />
            </Tooltip>

            <Popconfirm title="Удалить коллекцию?" onConfirm={handleDeleteHard}>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onClose = () => {
    setEditingCollection(null);
  };

  const onChangePage = (page) => {
    dispatch(setCurrentPage(page));
  };

  return (
    <div>
      <Table
        dataSource={collectionList}
        columns={columns}
        loading={loading}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editingCollection && (
        <UpdateModalCollection onClose={onClose} data={editingCollection} />
      )}
    </div>
  );
};
