import { notification } from "antd";

export const photoDescription =
  "Изображение должно соответствовать размеру 16:9, то есть: (1920x1080, 1280x720, 1600x900 и тд)";

export const errorFields = () => {
  notification.error({
    message: "Произошла ошибка, проверьте поля",
  });
};
