import { message, notification } from "antd";

export const getConfigToken = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem("access_token_private")}`,
  },
});

export const messageAuthFail = () => {
  localStorage.setItem("showAuthFailNotification", "true");
  localStorage.removeItem("access_token_private");
  window.location.reload();
};

if (localStorage.getItem("showAuthFailNotification") === "true") {
  notification.error({
    message: "Ошибка входа, сессия завершена. Войдите заново",
  });
  localStorage.removeItem("showAuthFailNotification");
}
export const messageSuccess = (text) => {
  notification.success({
    message: `Успех при ${text}`,
  });
};
export const errorFailCatch = (error: any, rejectWithValue: any) => {
  if (error.response && error.response.status === 403) {
    // messageAuthFail();
    message.error("Недоступный ресурс, войдите снова").then(() => {
      localStorage.removeItem("access_token_private");
      window.location.href = "/";
      window.location.reload();
    });
  } else if (error.response.status === 401) {
    message.error("Ошибка авторизации. Войдите снова").then(() => {
      localStorage.removeItem("access_token_private");
      window.location.href = "/";
      window.location.reload();
    });
  } else {
    message.error(error.response.data.message);
    // notification.error({
    //   message: "Ошибка",
    //   description: "Убедитесь в правильности полей",
    // });
  }
  return rejectWithValue(error.response.data.message);
};
