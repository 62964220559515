/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "@constants/api";
import { SignInType, SignUpType } from "@models/index";
import { SliceConstants } from "@constants/index";
import { activateToken } from "@shared/libs/useToken";
import $api from "src/app/axios/axiosInstance";

const { saveToken, getToken } = activateToken();

interface BarcaState {
  barca: any[];
  profile: any;
  loading: boolean;
  isAuth: boolean;
  accessToken?: string;
  error?: any;
}

export const initialState: BarcaState = {
  profile: null,
  barca: [],
  loading: false,
  isAuth: !!getToken(),
};

export const signIn = createAsyncThunk(
  SliceConstants.SignIn,
  async (props: SignInType, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await $api.post(AuthApi.Login, props);
      const { token } = data;
      window.location.reload();
      saveToken(token);
      dispatch(setAuth(true));
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const signUp = createAsyncThunk(
  SliceConstants.SignUp,
  async function (props: SignUpType, { rejectWithValue }) {
    try {
      const { data } = await $api.post(AuthApi.Register, props);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const barcaSlice = createSlice({
  name: "barca",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        // eslint-disable-next-line no-param-reassign
        state.accessToken = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setAuth } = barcaSlice.actions;

export default barcaSlice.reducer;
