import { Contacts } from "@features/contacts";
import React from "react";

export const ContactPage = () => {
  return (
    <div>
      <Contacts />
    </div>
  );
};
