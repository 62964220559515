/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { ProjectExperienceApi, deleteFile } from "@constants/api";
import { CommonName, LangIds } from "@models/common";
import { VideoType } from "./videoSlice";
import { Banner } from "./bannerSlice";
import $api from "src/app/axios/axiosInstance";

export type ResourceType = {
  title: CommonName;
  text: CommonName;
  btn_link: string;
};

export type ProjectType = {
  id?: string;
  title: CommonName;
  text: CommonName;
  videos: LangIds;
  banners: LangIds;
  images: string[];
  resources: ResourceType[];
};

export type ProjectTypeRes = {
  id?: string;
  title: CommonName;
  text: CommonName;
  videos: {
    ru: VideoType;
    ky: VideoType;
    en: VideoType;
  };
  banners: {
    ru: Banner;
    ky: Banner;
    en: Banner;
  };
  images: string[];
  resources: ResourceType[];
};

interface ProjectState {
  project?: ProjectType;
  loading: boolean;
  error?: string | null;
}

export const fetchProjectExperience = createAsyncThunk(
  SliceConstants.fetchProjectExperience,
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get(
        `${ProjectExperienceApi.projectExperience}`
      );

      return response.data[0];
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createProjectExperience = createAsyncThunk(
  SliceConstants.createProjectExperience,
  async (data: ProjectType, { rejectWithValue }) => {
    try {
      const response = await $api.post(
        ProjectExperienceApi.projectExperience,
        data
      );

      notification.success({
        message: "Проект создан",
      });

      return response.data;
    } catch (error: any) {
      if (data.images?.length) {
        await Promise.all(
          data.images.map(
            async (img) =>
              await deleteFile(img).catch((err) => {
                console.error("Failed to delete file:", img, err);
              })
          )
        );
      }

      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateProjectExperience = createAsyncThunk(
  SliceConstants.updateProjectExperience,
  async ({ id, ...data }: ProjectType, { rejectWithValue }) => {
    try {
      const response = await $api.put(
        `${ProjectExperienceApi.projectExperience}/${id}`,
        data
      );

      notification.success({
        message: "Проект обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteProjectExperience = createAsyncThunk(
  SliceConstants.deleteProjectExperience,
  async (id: string, { rejectWithValue }) => {
    try {
      await $api.delete(
        `${ProjectExperienceApi.projectExperience}/delete/${id}`
      );

      notification.success({
        message: "Проект удален",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);
export const initialState: ProjectState = {
  project: null,
  loading: false,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const projectExperienceSlice = createSlice({
  name: "projectExperience",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(
      builder,
      fetchProjectExperience,
      (state: any, action: any) => {
        state.project = action.payload;
      }
    );

    handleAsyncAction(
      builder,
      createProjectExperience,
      (state: any, action: any) => {
        state.project = action.payload;
      }
    );

    handleAsyncAction(
      builder,
      updateProjectExperience,
      (state: any, action: any) => {
        state.project = action.payload;
      }
    );

    handleAsyncAction(
      builder,
      deleteProjectExperience,
      (state: any, action: any) => {
        state.project = null;
      }
    );
  },
});

export default projectExperienceSlice.reducer;
