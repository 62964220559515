import { FC } from "react";
import { Form, Select, notification } from "antd";
import { LangField, ModalUI, UploadFileUI } from "@shared/uikit";
import { updateinfo } from "@store/slices/infoSlice";
import { Info } from "@models/info";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { authStore } from "@store/authorization/authStore";
import { SELECTED_FILE, SELECT_REQ } from "@constants/validateFields";

type UpdateInfoProps = {
  data: Info;
  onClose: () => void;
};

export const UpdateInfo: FC<UpdateInfoProps> = ({ data, onClose }) => {
  const [form] = Form.useForm<Info>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.info);
  const { userRole } = authStore;

  const {
    fileUpload: { uploadFile, loadingFile, setImgFile, setIsChangedFile },
  } = useFilesUpload();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const image = Array.isArray(values.images)
        ? values.images[0]
        : await uploadFile(values.images);

      const updatedData: Info = {
        ...values,
        id: data.id,
        images: [image],
      };

      if (image) {
        dispatch(updateinfo(updatedData)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            onClose();
          }

          if (data.meta.requestStatus === "rejected") {
            setIsChangedFile(image !== values.images[0]);
            setImgFile(image);
          }
        });
      }
    } catch (error) {
      notification.error({
        message: "Заполните все обязательные поля!",
      });
    }
  };

  return (
    <ModalUI
      title="Редактировать FAQ"
      open
      onCancel={onClose}
      onOk={onFinish}
      loading={loading || loadingFile}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading || loadingFile}
        initialValues={data}
      >
        <div style={{ minWidth: "200px" }}>
          <Form.Item name="title" label="Название" required>
            <LangField name="title" />
          </Form.Item>

          {userRole === "superAdmin" && (
            <Form.Item name="view_type" label="Тип" rules={[SELECT_REQ]}>
              <Select placeholder="Выберите тип">
                <Select.Option value="barca-experience">
                  Barça Academy
                </Select.Option>
                <Select.Option value="store">Barça Store</Select.Option>
              </Select>
            </Form.Item>
          )}

          <Form.Item name="images" label="Изображение" rules={[SELECTED_FILE]}>
            <UploadFileUI form={form} name="images" />
          </Form.Item>
        </div>
      </Form>
    </ModalUI>
  );
};
