import { Tabs, TabsProps } from "antd";
import { ActiveNews } from "./ActiveNews";
import { ArchivedNews } from "./ArchivedNews";
import { DeletedNews } from "./DeletedNews";
import { useURLSearchParams } from "@shared/libs";

export const NewsTable = ({ type }) => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Активные новости",
      children: <ActiveNews type={type} />,
    },
    {
      key: "2",
      label: "Архив новости",
      children: <ArchivedNews type={type} />,
    },
    {
      key: "3",
      label: "Удаленные новости",
      children: <DeletedNews type={type} />,
    },
  ];

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("news")}
        activeKey={activeTabsKey["news"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
