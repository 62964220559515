import { useEffect, useState } from "react";
import { Modal, Table, Button, Popconfirm } from "antd";
import styled from "styled-components";
import {
  activateOrder,
  fetchOrders,
  setStatusOrder,
} from "@store/slices/orderSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { setPagination } from "@store/slices/paramsSlice";
import { columnsData, goodsColumns, recipientColumns } from "../columns";
import { LIMIT } from "@constants/variables";
import { ModalUI } from "@shared/uikit";

const Container = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;

const RecipientModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    width: 1000px;
    flex-direction: column;
  }
`;

export const FinishedOrders = () => {
  const dispatch = useAppDispatch();
  const { ordersList, amount, loading, params } = useAppSelector(
    (state) => state.orders
  );
  const { page, skip } = params;

  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [goods, setGoods] = useState([]);

  const columns = [
    ...columnsData,
    {
      title: "Клиент",
      key: "client",
      render: (_, record) => {
        const onShowRecipient = () => {
          setSelectedRecipient({
            order_number: record.order_number,
            data: record.recepient,
          });
        };

        return <Button onClick={onShowRecipient}>Показать получателя</Button>;
      },
    },
    {
      title: "Товары",
      key: "goods",
      render: (_, record) => {
        const onShowGoods = () => {
          setGoods(record.order_items);
        };

        return <Button onClick={onShowGoods}>Показать товары</Button>;
      },
    },
    {
      title: "Действия",
      key: "actions",
      render: (_, record) => {
        const onActiveOrder = () => {
          dispatch(activateOrder({ ids: [record.id] }));
        };

        return (
          <Popconfirm title="Активировать заказ?" onConfirm={onActiveOrder}>
            <Button type="primary" style={{ background: "#79bd5b" }}>
              Активировать заказ
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const onPageChange = (page: number) => {
    dispatch(setPagination(page));
  };

  const hideRecipientModal = () => {
    setSelectedRecipient(null);
  };

  const hideGoodsModal = () => {
    setGoods([]);
  };

  useEffect(() => {
    dispatch(
      fetchOrders({
        status: "finished",
        skip,
        limit: 10,
      })
    );
  }, [dispatch, skip]);

  useEffect(() => {
    dispatch(setStatusOrder("finished"));
  }, [dispatch]);

  return (
    <Container>
      <Table
        dataSource={ordersList}
        columns={columns}
        loading={loading}
        scroll={{ x: true }}
        pagination={{
          total: amount,
          current: page,
          pageSize: LIMIT,
          onChange: onPageChange,
        }}
      />

      {selectedRecipient && (
        <RecipientModal
          title={`Номер заказа - ${selectedRecipient.order_number}`}
          open
          onCancel={hideRecipientModal}
          footer={null}
          width={1100}
        >
          {selectedRecipient && (
            <Table
              dataSource={[selectedRecipient.data]}
              columns={recipientColumns}
              scroll={{ x: true }}
              pagination={false}
            />
          )}
        </RecipientModal>
      )}

      {goods.length ? (
        <ModalUI
          open
          title="Товары"
          onCancel={hideGoodsModal}
          closable
          width="lg"
          footer={null}
        >
          <Table
            dataSource={goods}
            columns={goodsColumns}
            scroll={{ x: true }}
          />
        </ModalUI>
      ) : null}
    </Container>
  );
};
