import { useEffect, useState } from "react";
import { Button, Empty, Form, Popconfirm, Spin, Tag } from "antd";
import {
  LangBanner,
  LangEditor,
  LangField,
  LangVideo,
  Service,
  UploadFilesUI,
} from "@shared/uikit";
import { MuseumType, deleteMuseum } from "@store/slices/museumSlice";
import { fetchMuseums, updateMuseum } from "@store/slices/museumSlice";
import {
  arraysAreEqual,
  separateImages,
  useAppDispatch,
  useAppSelector,
  useUnloadPage,
} from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { SELECT_BANNERS } from "@constants/validateFields";

export const MuseumInfo = ({ type }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { museum, loading } = useAppSelector((state) => state.museum);

  const {
    filesUpload: { uploadFiles, loadingFiles, setImgFiles, setIsChangedFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [serviceItems, setServiceItems] = useState([]);

  useEffect(() => {
    dispatch(fetchMuseums({ type }));
  }, [dispatch, type]);

  useEffect(() => {
    if (museum) {
      setServiceItems(museum?.service_items || []);
    }
  }, [museum]);

  const onFinish = async (values) => {
    const { strImages, objImages } = separateImages(values.images || []);
    const images = await uploadFiles(objImages);
    const serviceIds = serviceItems.map((item) => item.id);

    const updatedData: MuseumType = {
      ...values,
      id: museum.id,
      images: [...strImages, ...images],
      service_items_ids: serviceIds,
    };

    dispatch(updateMuseum(updatedData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        onFormNotDirty();
      }

      if (data.meta.requestStatus === "rejected") {
        if (!arraysAreEqual(values.images, strImages)) {
          setIsChangedFiles(true);
          setImgFiles(images);
        }
      }
    });
  };

  const onDelete = () => {
    dispatch(deleteMuseum(museum.id));
  };

  return (
    <Spin spinning={loading && !museum}>
      {museum ? (
        <Form
          name="updateMuseum"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: 1000 }}
          disabled={loading || loadingFiles}
          initialValues={museum}
          onValuesChange={onFormDirty}
          scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
          <Form.Item name="title" label="Название" required>
            <LangField name="title" />
          </Form.Item>

          <Form.Item name="text" label="Текст" required>
            <LangEditor form={form} name="text" />
          </Form.Item>
          <br />

          <Form.Item name="images" label="Изображения">
            <UploadFilesUI form={form} name="images" />
          </Form.Item>
          <br />

          <Form.Item name="videos" label="Видео">
            <LangVideo form={form} name="videos" />
          </Form.Item>
          <br />

          <Form.Item name="banners" label="Баннеры" rules={[SELECT_BANNERS]}>
            <LangBanner
              form={form}
              name="banners"
              banner_type="service"
              isRequired
            />
          </Form.Item>
          <br />

          <Form.Item label="Сервисы">
            {serviceItems.length
              ? serviceItems.map((item) => (
                  <Tag>
                    <p key={item.id}>{item?.title.ru}</p>
                  </Tag>
                ))
              : "Не выбраны"}
          </Form.Item>

          <Service
            type="museum"
            selectedItems={serviceItems}
            onSelectServiceItems={(selectedItems) =>
              setServiceItems(selectedItems)
            }
          />

          <Button
            type="primary"
            htmlType="submit"
            loading={loading || loadingFiles}
            disabled={false}
          >
            Обновить Музей
          </Button>

          <Popconfirm
            onConfirm={onDelete}
            title="Вы уверены что хотите удалить Музей?"
          >
            <Button danger style={{ marginLeft: 8 }}>
              Удалить Музей
            </Button>
          </Popconfirm>
        </Form>
      ) : (
        <Empty description="У Академии еще нет Музея" />
      )}
    </Spin>
  );
};
