import { Form, Button, Tag } from "antd";
import { createProjectExperience } from "@store/slices/projectExperienceSlice";
import {
  LangBanner,
  LangEditor,
  LangField,
  LangVideo,
  UploadFilesUI,
} from "@shared/uikit";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { SELECT_BANNERS } from "@constants/validateFields";
import { ResourceBlock } from "./ResouceBlock";
import { useFilesUpload } from "@constants/api";

export const CreateProjectExperience = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.experienceProject);

  const {
    filesUpload: { uploadFiles, loadingFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async (values) => {
    const images = await uploadFiles(values.images);

    dispatch(createProjectExperience({ ...values, images })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        onFormNotDirty();
        form.resetFields();
      }
    });
  };

  return (
    <>
      <h2>Создать Проект для Barça experience</h2>
      <Form
        name="createProject"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading || loadingFiles}
        style={{ maxWidth: 1000 }}
        initialValues={{
          resources: [
            {
              title: { ru: "", ky: "", en: "" },
              text: { ru: "", ky: "", en: "" },
              btn_link: "",
            },
          ],
        }}
        onValuesChange={onFormDirty}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
      >
        <Form.Item name="title" label="Название" required>
          <LangField name="title" />
        </Form.Item>

        <Form.Item name="text" label="Текст" required>
          <LangEditor form={form} name="text" />
        </Form.Item>
        <br />

        <Form.Item name="images" label="Изображения">
          <UploadFilesUI form={form} name="images" />
        </Form.Item>

        <Form.Item name="videos" label="Видео">
          <LangVideo form={form} name="videos" />
        </Form.Item>
        <br />

        <Form.Item name="banners" label="Баннеры" rules={[SELECT_BANNERS]}>
          <LangBanner form={form} name="banners" banner_type={null} isRequired />
        </Form.Item>
        <br />

        <Form.List name="resources">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <div key={index}>
                  <Tag color="purple-inverse" style={{ marginBottom: 10 }}>
                    Ресурс {index + 1}
                  </Tag>
                  <ResourceBlock field={field} />
                  {index === 0 && <div style={{ marginBottom: 10 }} />}
                  {index > 0 && (
                    <Form.Item>
                      <Button danger onClick={() => remove(field.name)}>
                        Удалить
                      </Button>
                    </Form.Item>
                  )}
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  Добавить ресурсы
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading || loadingFiles}
            disabled={false}
          >
            Создать проект
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
