import { useEffect } from "react";
import { Button, Empty, Form, Popconfirm, Spin, Tag } from "antd";
import {
  LangBanner,
  LangEditor,
  LangField,
  LangVideo,
  UploadFilesUI,
} from "@shared/uikit";
import {
  ProjectType,
  deleteProjectExperience,
  fetchProjectExperience,
  updateProjectExperience,
} from "@store/slices/projectExperienceSlice";
import {
  arraysAreEqual,
  separateImages,
  useAppDispatch,
  useAppSelector,
  useUnloadPage,
} from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { SELECT_BANNERS } from "@constants/validateFields";
import { ResourceBlock } from "../CreateProject/ResouceBlock";

export const ExperienceProject = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { project, loading } = useAppSelector(
    (state) => state.experienceProject
  );

  const {
    filesUpload: { uploadFiles, loadingFiles, setImgFiles, setIsChangedFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  useEffect(() => {
    dispatch(fetchProjectExperience());
  }, [dispatch]);

  const onFinish = async (values) => {
    const { strImages, objImages } = separateImages(values.images || []);
    const images = await uploadFiles(objImages);

    const updatedData: ProjectType = {
      ...values,
      id: project.id,
      images: [...strImages, ...images],
    };

    dispatch(updateProjectExperience(updatedData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        onFormNotDirty();
      }

      if (data.meta.requestStatus === "rejected") {
        if (!arraysAreEqual(values.images, strImages)) {
          setIsChangedFiles(true);
          setImgFiles(images);
        }
      }
    });
  };

  const onDelete = () => {
    dispatch(deleteProjectExperience(project.id));
  };

  return (
    <Spin spinning={loading && !project}>
      {project ? (
        <Form
          name="updateProject"
          form={form}
          layout="vertical"
          onFinish={onFinish}
          style={{ maxWidth: 1000 }}
          disabled={loading || loadingFiles}
          initialValues={project}
          onValuesChange={onFormDirty}
          scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
          <Form.Item name="title" label="Название" required>
            <LangField name="title" />
          </Form.Item>

          <Form.Item name="text" label="Текст" required>
            <LangEditor form={form} name="text" />
          </Form.Item>
          <br />

          <Form.Item name="images" label="Изображения">
            <UploadFilesUI form={form} name="images" />
          </Form.Item>

          <Form.Item name="videos" label="Видео">
            <LangVideo form={form} name="videos" />
          </Form.Item>
          <br />

          <Form.Item name="banners" label="Баннеры" rules={[SELECT_BANNERS]}>
            <LangBanner
              form={form}
              name="banners"
              banner_type={null}
              isRequired
            />
          </Form.Item>
          <br />

          <Form.List name="resources">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={index}>
                    <Tag color="purple-inverse" style={{ marginBottom: 10 }}>
                      Ресурс {index + 1}
                    </Tag>
                    <ResourceBlock field={field} />
                    {index === 0 && <div style={{ marginBottom: 10 }} />}
                    {index > 0 && (
                      <Form.Item>
                        <Button danger onClick={() => remove(field.name)}>
                          Удалить
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    Добавить ресурсы
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Button
            type="primary"
            htmlType="submit"
            loading={loading || loadingFiles}
            disabled={false}
          >
            Обновить Проект
          </Button>

          <Popconfirm
            onConfirm={onDelete}
            title="Вы уверены что хотите удалить?"
          >
            <Button danger style={{ marginLeft: 8 }}>
              Удалить Проект
            </Button>
          </Popconfirm>
        </Form>
      ) : (
        <Empty description="У Академии еще нет Проекта" />
      )}
    </Spin>
  );
};
