import { useEffect, useState } from "react";

export const useUnloadPage = () => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  const onFormDirty = () => {
    setIsFormDirty(true);
  };

  const onFormNotDirty = () => {
    setIsFormDirty(false);
  };

  return { onFormDirty, onFormNotDirty };
};
