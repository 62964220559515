import { FC } from "react";
import { SocialNetwork } from "@models/socialNetwork";
import { Form, Input, Select, notification } from "antd";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { ModalUI } from "@shared/uikit";
import { authStore } from "@store/authorization/authStore";
import { REQUIRED, validateURL } from "@constants/validateFields";
import { updateSocialNetwork } from "@store/slices/socialNetworkSlice";

const { Option } = Select;

type UpdateSocialNetworkProps = {
  data: SocialNetwork;
  onClose: () => void;
};

export const UpdateSocialNetwork: FC<UpdateSocialNetworkProps> = ({
  data,
  onClose,
}) => {
  const [form] = Form.useForm<SocialNetwork>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.socialNetworks);
  const { userRole } = authStore;

  const onFinish = async () => {
    try {
      const values = await form.validateFields();

      dispatch(updateSocialNetwork({ id: data.id, ...values })).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onClose();
        }
      });
    } catch (error) {
      notification.error({
        message: "Заполните все обязательные поля!",
      });
    }
  };

  return (
    <ModalUI
      title="Редактировать соц.сеть"
      open
      onOk={onFinish}
      onCancel={onClose}
      loading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading}
        initialValues={data}
      >
        <Form.Item name="title" label="Заголовок" rules={[REQUIRED]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="url"
          label="Ссылка"
          rules={[REQUIRED, { validator: validateURL }]}
        >
          <Input />
        </Form.Item>

        {userRole !== "adminStore" && (
          <Form.Item name="type" label="Тип" required>
            <Select style={{ width: "230px" }}>
              <Option value="jalal-abad">Джалал-Абад</Option>
              <Option value="bishkek">Бишкек</Option>
              {userRole === "superAdmin" && (
                <Option value="store">Store</Option>
              )}
            </Select>
          </Form.Item>
        )}

        <Form.Item name="social_network_type" label="Соц сеть" required>
          <Select>
            <Option value="facebook">Facebook</Option>
            <Option value="instagram">Instagram</Option>
            <Option value="youtube">Youtube</Option>
          </Select>
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
