import { FC, useEffect, useState } from "react";
import { Button, FormInstance, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { getYouTubeVideoId, isYouTubeUrl } from "@shared/libs";
import styles from "./UploadVideoUI.module.scss";

type UploadVideoUIProps = {
  form: FormInstance;
  name: string;
  onChange?: (value?) => void;
};

export const UploadVideoUI: FC<UploadVideoUIProps> = (props) => {
  const { form, name, onChange } = props;
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (form && name) {
      setFile(form.getFieldValue(name));
    }
  }, [form, name]);

  const handleFileChange = async (info) => {
    let files = [...info.fileList];

    if (files.length > 1) {
      files = [files[files.length - 1]];
    }

    if (files[0]?.status) {
      onChange(null);
      setFile(null);
    } else {
      const fileObj = files[0].originFileObj;
      setFile(URL.createObjectURL(fileObj));
      onChange(fileObj);
    }
  };

  const beforeUpload = (file) => {
    const allowedTypes = ["video/mp4", "video/webm"];
    const isAllowed = allowedTypes.includes(file.type);

    if (!isAllowed) {
      message.error("Только файлы MP4 и WEBM разрешены");
      return true;
    }

    if (file.size / 1024 / 1024 > 200) {
      message.error("Размер файла должен быть меньше 200MB");
      return true;
    }

    return false;
  };

  const handleDeleteFile = () => {
    setFile(null);
    onChange(null);
  };

  return (
    <>
      {file ? (
        <div className={styles.block}>
          <div className={styles.videoBlock}>
            <div className={styles.videoPlayer}>
              {isYouTubeUrl(file) ? (
                <iframe
                  src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                    file
                  )}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  className={styles.video}
                  allowFullScreen
                />
              ) : (
                <video controls className={styles.video}>
                  <source src={file} />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          </div>

          <Button
            size="small"
            danger
            onClick={handleDeleteFile}
            className={styles.btn}
          >
            Загрузить другой
          </Button>
        </div>
      ) : (
        <Upload.Dragger
          fileList={[]}
          onChange={handleFileChange}
          accept=".mp4,.webm"
          beforeUpload={beforeUpload}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className={`ant-upload-text ${styles.desc}`}>
            Перетащите видео сюда или нажмите, чтобы выбрать видео
          </p>
        </Upload.Dragger>
      )}
    </>
  );
};
