import { Tabs, TabsProps } from "antd";
import { ActiveInfo } from "./ActiveInfoBish";

const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Активные FAQ",
    children: <ActiveInfo />,
  },
];

export const InfoTableBish = () => {
  return (
    <div>
      <Tabs items={tabItems} destroyInactiveTabPane />
    </div>
  );
};
