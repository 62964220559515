import { Card, Tabs, TabsProps } from "antd";
import { useURLSearchParams } from "@shared/libs";
import { CreateCamp } from "./CreateCamp";
import { CampList } from "./CampList";

export const Camp = ({ type }) => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Лагерь",
      children: <CampList type={type} />,
    },
    {
      key: "2",
      label: "Создать лагерь",
      children: <CreateCamp type={type} />,
    },
  ];

  return (
    <Card>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </Card>
  );
};
