import { FC } from "react";
import { Select } from "antd";
import { authStore } from "@store/authorization/authStore";
import { NetworkType } from "@models/socialNetwork";
import { City } from "@models/common";
import styles from "./FiltersType.module.scss";

const { Option } = Select;

const socialTypes = ["facebook", "instagram", "youtube"];

type FiltersTypeProps = {
  socialType: NetworkType;
  setSocialType: (socialType: NetworkType) => void;
  type: City;
  setType: (socialType: City) => void;
};

export const FiltersType: FC<FiltersTypeProps> = (props) => {
  const { socialType, setSocialType, type, setType } = props;
  const { userRole } = authStore;

  return (
    <div className={styles.header}>
      <div>Соц.сеть</div>
      <Select
        className={styles.select}
        value={socialType}
        onChange={setSocialType}
      >
        <Option>Все соц.сети</Option>
        {socialTypes.map((social) => (
          <Option key={social} value={social}>
            {social}
          </Option>
        ))}
      </Select>

      <div>Тип</div>
      <Select className={styles.select} value={type} onChange={setType}>
        {userRole === "superAdmin" && <Option>Все типы</Option>}
        <Option value="jalal-abad">Джалал-Абад</Option>
        <Option value="bishkek">Бишкек</Option>
        {userRole === "superAdmin" && <Option value="store">Barça Store</Option>}
      </Select>
    </div>
  );
};
