import React from "react";
import { Select } from "antd";
import i18n from "src/i18n";

const { Option } = Select;
export const LanguageSelector = () => {
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const props = {
    defaultValue: "ru",
    style: { width: 70 },
    onChange: changeLanguage,
  };

  return (
    <Select {...props}>
      <Option value="en">EN</Option>
      <Option value="ru">RU</Option>
      <Option value="ky">KY</Option>
    </Select>
  );
};
