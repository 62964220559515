/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { ContactApi } from "@constants/api";
import { Contact } from "@models/contacts";
import { CommonParams } from "@models/common";
import { RootState } from "..";
import { LIMIT } from "@constants/variables";
import { createValidate, deleteValidate } from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

interface contactState {
  contactList: Contact[];
  loading: boolean;
  amount: number;
  error: string | null;
  params: {
    type?: "academy" | "store";
    skip: number;
    page: number;
  };
}

export const fetchContacts = createAsyncThunk(
  SliceConstants.FetchContact,
  async (
    params: { type: Contact["type"] } & CommonParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.get(`${ContactApi.contact}/list`, {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createContact = createAsyncThunk(
  SliceConstants.CreateContact,
  async (data: Contact, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(ContactApi.contact, data);

      const store = getState() as RootState;
      const { type, page } = store.contacts.params;

      if (createValidate([], page)) {
        await dispatch(fetchContacts({ skip: 0, limit: LIMIT, type }));
      } else {
        dispatch(resetPagination());
      }

      notification.success({
        message: "Контакт создан",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateContact = createAsyncThunk(
  SliceConstants.UpdateContact,
  async ({ id, ...data }: Contact, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.put(`${ContactApi.contact}/${id}`, data);

      const store = getState() as RootState;
      const { type, skip } = store.contacts.params;
      await dispatch(fetchContacts({ skip, limit: LIMIT, type }));

      notification.success({
        message: "Контакт обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteContact = createAsyncThunk(
  SliceConstants.DeleteContact,
  async (id: string, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${ContactApi.contact}/${id}`);

      const store = getState() as RootState;
      const { contactList, params } = store.contacts;
      const { type, skip, page } = params;

      const currentPage = deleteValidate(skip, contactList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, contactList);

        await dispatch(fetchContacts({ skip: newSkip, limit: LIMIT, type }));
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Контакт удален",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

const initialState: contactState = {
  contactList: [],
  loading: false,
  amount: 0,
  error: null,
  params: {
    page: 1,
    skip: 0,
    type: null,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params.type = action.payload;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetPagination(state) {
      state.params.page = 1;
      state.params.skip = 0;
    },
    resetAll(state) {
      state.params.type = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchContacts, (state: any, action: any) => {
      state.contactList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(builder, createContact, (state: any, action: any) => {});

    handleAsyncAction(builder, updateContact, (state: any, action: any) => {});

    handleAsyncAction(builder, deleteContact, (state: any, action: any) => {});
  },
});

export const { setParamsType, setCurrentPage, resetPagination, resetAll } =
  contactSlice.actions;
export default contactSlice.reducer;
