// NEWS BARCA EXPERIENCE
import { FC, useState } from "react";
import { createNews } from "@store/slices/newsSlice";
import { Form, Button, Col, DatePicker, Radio, Select } from "antd";
import { NewsType } from "@models/news";
import {
  LangBanner,
  LangEditor,
  LangField,
  LangVideo,
  UploadFilesUI,
} from "@shared/uikit";
import styled from "styled-components";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { NEWSTYPES } from "./consts";
import { useFilesUpload } from "@constants/api";

const InnerForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const newsState: NewsType = {
  title: {
    ru: "",
    en: "",
    ky: "",
  },
  description: {
    ru: "",
    en: "",
    ky: "",
  },
  text: {
    ru: "",
    en: "",
    ky: "",
  },
  news_type: "jalgroup",
  newsDate: null,
  images: [],
  color: "light",
};

type CreateNewsProps = {
  type: NewsType["news_type"];
};

export const CreateNews: FC<CreateNewsProps> = ({ type }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.news);

  const {
    filesUpload: { uploadFiles, loadingFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [displayCol, setDisplayCol] = useState(false);

  const onFinish = async (values: NewsType) => {
    const images = await uploadFiles(values.images);

    dispatch(createNews({ ...values, images })).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        onFormNotDirty();
        form.resetFields();
      }
    });
  };

  return (
    <>
      <h2>Создать новость</h2>
      <div>
        <Form
          name="createNews"
          onFinish={onFinish}
          form={form}
          layout="vertical"
          disabled={loading || loadingFiles}
          style={{ maxWidth: 1000 }}
          initialValues={{
            ...newsState,
            news_type: type === "jalgroup" ? type : "bishkek",
          }}
          onValuesChange={onFormDirty}
          scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
          <InnerForm>
            <Form.Item name="title" label="Название" required>
              <LangField name="title" />
            </Form.Item>

            <Form.Item name="description" label="Описание" required>
              <LangField name="description" isTextArea />
            </Form.Item>

            <Form.Item name="text" label="Текст" required>
              <LangEditor form={form} name="text" />
            </Form.Item>

            <div style={{ paddingBottom: "40px" }}>
              <Form.Item name="news_type" label="Отображение новости" required>
                <Select style={{ width: "250px" }} options={NEWSTYPES[type]} />
              </Form.Item>

              <Form.Item name="newsDate" label="Дата">
                <DatePicker />
              </Form.Item>

              <Form.Item name="color" label="Цвет">
                <Radio.Group>
                  <Radio value="light">Светлый</Radio>
                  <Radio value="dark">Темный</Radio>
                </Radio.Group>
              </Form.Item>

              {!displayCol && (
                <div>
                  <Button onClick={() => setDisplayCol(true)}>
                    Добавить медиафайлы
                  </Button>
                </div>
              )}
              {displayCol && (
                <Col>
                  {/* BANNER AND VIDEO */}
                  <Form.Item name="videos" label="Видео">
                    <LangVideo form={form} name="videos" />
                  </Form.Item>
                  <Form.Item name="banners">
                    <LangBanner form={form} name="banners" />
                  </Form.Item>

                  <Form.Item name="images" label="Изображения">
                    <UploadFilesUI form={form} name="images" />
                  </Form.Item>
                </Col>
              )}
              <br />
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading || loadingFiles}
                  disabled={false}
                >
                  Создать новость
                </Button>
              </Form.Item>
            </div>
          </InnerForm>
        </Form>
      </div>
    </>
  );
};
