import { FC, useEffect, useState } from "react";
import { Button, FormInstance, Tag, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

type FileProps = {
  form: FormInstance;
  name: string;
  onChange?: (value?) => void;
};

export const File: FC<FileProps> = (props) => {
  const { form, name, onChange } = props;
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (form && name) {
      setFile(form.getFieldValue(name));
    }
  }, [form, name]);

  const handleFileChange = async (info) => {
    let files = [...info.fileList];

    if (files.length > 1) {
      files = [files[files.length - 1]];
    }

    if (files[0]?.status) {
      onChange("");
      setFile(null);
    } else {
      setFile(files[0].originFileObj);
      onChange(files[0].originFileObj);
    }
  };

  const beforeUpload = (file) => {
    const isPdfOrDocx = file.type === "application/pdf";

    if (!isPdfOrDocx) {
      message.error("Вы можете загрузить только файл PDF");
      return true;
    }

    if (file.size / 1024 / 1024 > 20) {
      message.error("Размер файла должен быть меньше 20MB");
      return true;
    }

    return false;
  };

  const handleDeleteFile = () => {
    setFile(null);
    onChange("");
  };

  return (
    <>
      {file ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            width: "fit-content",
          }}
        >
          {file?.name ? (
            <Tag color="green">{file.name} загружен</Tag>
          ) : (
            <Tag color="green">Файл получен</Tag>
          )}
          <Button
            size="small"
            danger
            onClick={handleDeleteFile}
            style={{ width: "inherit" }}
          >
            Загрузить другой
          </Button>
        </div>
      ) : (
        <Upload.Dragger
          fileList={[]}
          onChange={handleFileChange}
          accept=".pdf"
          beforeUpload={beforeUpload}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text" style={{ fontSize: "small" }}>
            Перетащите файл сюда или нажмите, чтобы выбрать файл
          </p>
        </Upload.Dragger>
      )}
    </>
  );
};
