import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, FormInstance, Image, Select } from "antd";
import { Banner, BannerType, fetchBanner } from "@store/slices/bannerSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { links } from "@enums/links";
import { LIMIT } from "@constants/variables";
import styles from "./LangBanner.module.scss";
import { CreateBannerOne } from "./CreateBannerOne";

interface LangBannerProps {
  form: FormInstance;
  name: string;
  banner_type?: BannerType;
  isRequired?: boolean;
}

const initialState = { ruId: 0, kyId: 0, enId: 0 };

export const LangBanner: FC<LangBannerProps> = ({
  form,
  name,
  banner_type = "news",
  isRequired = false,
}) => {
  const dispatch = useAppDispatch();
  const { loading, amount } = useAppSelector((state) => state.banners);

  const [skip, setSkip] = useState(0);
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [list, setList] = useState<Banner[]>([]);
  const [selectedLanguagesId, setSelectedLanguagesId] = useState(initialState);
  const [selectedBanners, setSelectedBanners] = useState({
    ru: null,
    ky: null,
    en: null,
  });

  useEffect(() => {
    const initialState = form.getFieldValue(name);

    if (initialState) {
      setSelectedBanners(initialState);
      setSelectedLanguagesId({
        ruId: initialState.ru?.id || 0,
        kyId: initialState.ky?.id || 0,
        enId: initialState.en?.id || 0,
      });
    }
  }, [form, name]);

  const observer = useRef<IntersectionObserver | null>(null);

  const handleBannerSelect = (value, lang) => {
    const selectedBanner = list.find((banner) => banner.id === value) || null;

    if (selectedBanners.ru) {
      setSelectedBanners((prevState) => ({
        ...prevState,
        [lang]: selectedBanner,
      }));
    } else {
      setSelectedBanners({
        ru: selectedBanner,
        ky: selectedBanner,
        en: selectedBanner,
      });
    }

    if (selectedLanguagesId.ruId) {
      setSelectedLanguagesId((prevIds) => ({
        ...prevIds,
        [`${lang}Id`]: value,
      }));
    } else {
      setSelectedLanguagesId({
        ruId: value,
        kyId: value,
        enId: value,
      });
    }
  };

  const lastCommentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && list.length < amount + count) {
          setSkip((prevSkip) => prevSkip + LIMIT + count);
          setCount(0);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, list.length, amount]
  );

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onReset = () => {
    setSelectedBanners({
      ru: null,
      ky: null,
      en: null,
    });
    setSelectedLanguagesId(initialState);
    form.setFieldValue(name, null);
  };

  useEffect(() => {
    if (selectedLanguagesId.ruId !== 0) {
      form.setFieldValue(name, selectedLanguagesId);
      form.validateFields([name]);
    }
  }, [selectedLanguagesId]);

  useEffect(() => {
    dispatch(fetchBanner({ skip, limit: LIMIT, banner_type })).then((data) => {
      if (data.payload) {
        const { items } = data.payload as any;
        setList((prev) => (skip === 0 ? items : [...prev, ...items]));
      }
    });
  }, [dispatch, skip]);

  return (
    <>
      <div className={styles.banners}>
        {["ru", "ky", "en"].map((lang) => (
          <div key={lang} className={styles.banner}>
            <div className={styles.title}>
              Баннер
              <img src={links[lang]} alt="" width={30} />
            </div>

            {selectedBanners[lang] && (
              <Image
                rootClassName={styles.imgBlock}
                className={styles.img}
                src={selectedBanners[lang].banner}
                fallback="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ="
                style={{ objectFit: "contain" }}
              />
            )}

            <Select
              className={styles.select}
              loading={loading}
              placeholder={`Выберите баннеры (${lang})`}
              value={selectedLanguagesId[`${lang}Id`] || null}
              onChange={(value) => handleBannerSelect(value, lang)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {loading && (
                    <div style={{ textAlign: "center" }}>Загрузка...</div>
                  )}
                </>
              )}
            >
              {list.map((banner, index) => (
                <Select.Option key={banner.id} value={banner.id}>
                  <Image
                    src={banner.banner}
                    width={80}
                    height={50}
                    preview={false}
                    fallback="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ="
                    style={{ objectFit: "contain" }}
                  />
                  &nbsp; &nbsp;
                  {banner.title}
                  {index === list.length - 1 ? (
                    <div ref={lastCommentRef} />
                  ) : null}
                </Select.Option>
              ))}
            </Select>
          </div>
        ))}
      </div>
      <Button type="primary" onClick={onToggle}>
        Создать баннер
      </Button>
      {!isRequired && (
        <Button
          type="primary"
          className={styles.btnDelete}
          danger
          onClick={onReset}
          disabled={!selectedBanners.ru}
        >
          Убрать баннеры
        </Button>
      )}

      <CreateBannerOne
        open={isOpen}
        onClose={onToggle}
        setList={setList}
        setCount={setCount}
        banner_type={banner_type}
      />
    </>
  );
};
