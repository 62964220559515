interface SeparatedImagesRes {
  strImages: string[];
  objImages: any[];
}

export const separateImages = (images): SeparatedImagesRes => {
  return images.reduce(
    (acc, item) => {
      if (typeof item === "string") {
        acc.strImages.push(item);
      } else {
        acc.objImages.push(item);
      }
      return acc;
    },
    { strImages: [], objImages: [] }
  );
};
