import validator from "validator";

export const REQUIRED = { required: true, message: "Обязательное поле" };
export const EMAIL_CORRECT: any = {
  type: "email",
  message: "Введите корректную почту",
};
export const SELECT_REQ = { required: true, message: "Выберите опцию" };
export const SELECT_BANNERS = { required: true, message: "Выберите баннеры" };
export const SELECT_VIDEOS = { required: true, message: "Выберите видео" };
export const SELECTED_FILE = { required: true, message: "Выберите файл" };

export const validateURL = (rule, value) => {
  if (value && !validator.isURL(value)) {
    return Promise.reject("Введите корректный URL");
  }
  return Promise.resolve();
};
