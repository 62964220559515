import { useState } from "react";
import { Button, Form, InputNumber, Select } from "antd";
import { createVacancies } from "@store/slices/vacanciesSlice";
import { Vacancy } from "@models/vacancies";
import { LangField } from "@shared/uikit";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { REQUIRED } from "@constants/validateFields";

const vacancyState: Vacancy = {
  title: { ru: "", en: "", ky: "" },
  description: { ru: "", en: "", ky: "" },
  work_conditions: { ru: "", en: "", ky: "" },
  skills: { ru: "", en: "", ky: "" },
  vacancy_type: "jalgroup",
  vacancy_city: "bishkek",
  experience: "none",
  employment: "part-time",
  work_type: "office",
  min_salary: 0,
  max_salary: 0,
};

export const CreateVacanсy = ({ type }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.vacancies);
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [fields, setFields] = useState<Vacancy>(vacancyState);

  const handleFieldsChange = (data, name: string) => {
    setFields((prev) => ({
      ...prev,
      [name]: data,
    }));
    onFormDirty();
  };

  const onFinish = async () => {
    await dispatch(createVacancies({ ...fields, vacancy_type: type })).then(
      (data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          setFields(vacancyState);
          form.setFieldsValue(vacancyState);
        }
      }
    );
  };

  const fieldLabels = {
    title: "Название",
    description: "Описание",
    work_conditions: "Условия работы",
    skills: "Навыки",
  };

  return (
    <div>
      <h2>Создать вакансию</h2>
      <Form
        form={form}
        name="createVacancy"
        onFinish={onFinish}
        layout="vertical"
        disabled={loading}
        style={{ maxWidth: 1000 }}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
      >
        {Object.keys(fieldLabels).map((fieldName) => (
          <Form.Item key={fieldName} label={fieldLabels[fieldName]} required>
            <LangField
              value={fields[fieldName]}
              name={fieldName}
              isTextArea={fieldName !== "title"}
              onChange={handleFieldsChange}
            />
          </Form.Item>
        ))}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Item label="Город" required>
            <Select
              onChange={(e) => handleFieldsChange(e, "vacancy_city")}
              value={fields.vacancy_city}
              options={[
                { value: "bishkek", label: "Бишкек" },
                { value: "jalal-abad", label: "Джалал-Абад" },
              ]}
            />
          </Form.Item>

          <Form.Item label="Опыт" required>
            <Select
              onChange={(e) => handleFieldsChange(e, "experience")}
              value={fields.experience}
              options={[
                { value: "less-one", label: "Менее 1 года" },
                { value: "one_three", label: "1-3 года" },
                { value: "three_six", label: "3-6 лет" },
                { value: "none", label: "Нет" },
              ]}
            />
          </Form.Item>

          <Form.Item label="Тип занятости" required>
            <Select
              onChange={(e) => handleFieldsChange(e, "employment")}
              value={fields.employment}
              options={[
                { value: "full-time", label: "Полный рабочий день" },
                { value: "part-time", label: "Неполный рабочий день" },
              ]}
            />
          </Form.Item>

          <Form.Item label="Тип работы" required>
            <Select
              onChange={(e) => handleFieldsChange(e, "work_type")}
              value={fields.work_type}
              options={[
                { value: "remote", label: "Удаленно" },
                { value: "office", label: "Офис" },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="min_salary"
            label="Минимальная зарплата"
            rules={[REQUIRED]}
          >
            <InputNumber
              type="number"
              min={0}
              value={fields.min_salary}
              onChange={(e) => handleFieldsChange(e, "min_salary")}
            />
          </Form.Item>

          <Form.Item
            name="max_salary"
            label="Максимальная зарплата"
            rules={[REQUIRED]}
          >
            <InputNumber
              type="number"
              min={0}
              value={fields.max_salary}
              onChange={(e) => handleFieldsChange(e, "max_salary")}
            />
          </Form.Item>
        </div>
        <br />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={false}
          >
            Создать вакансию
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
