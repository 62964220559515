import { useState, useEffect, Fragment } from "react";
import { Form, Tag, Spin, Empty, Button, Popconfirm } from "antd";
import {
  deleteExpMainPage,
  fetchExpMainPage,
  updateExpMainPage,
} from "@store/slices/expMainPageSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { Banner, fetchBanner } from "@store/slices/bannerSlice";
import { LIMIT } from "@constants/variables";
import { BANNERS_POSITION } from "./const";
import { LangBannerExp } from "./LangBannerExp";
import { SELECT_BANNERS, SELECT_VIDEOS } from "@constants/validateFields";
import { LangVideo } from "@shared/uikit";

const BannersExperience = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { expMainPage, loading } = useAppSelector((state) => state.expMainPage);

  const [skip, setSkip] = useState(0);
  const [list, setList] = useState<Banner[]>([]);

  useEffect(() => {
    dispatch(
      fetchBanner({ skip, limit: LIMIT, banner_type: "experience-mainpage" })
    ).then((data) => {
      if (data.payload) {
        const { items } = data.payload as any;
        setList((prev) => (skip === 0 ? items : [...prev, ...items]));
      }
    });
  }, [dispatch, skip]);

  useEffect(() => {
    dispatch(fetchExpMainPage());
  }, [dispatch]);

  const onFinish = async (values) => {
    dispatch(updateExpMainPage({ ...values, id: expMainPage.id }));
  };

  const onDelete = () => {
    dispatch(deleteExpMainPage(expMainPage.id));
  };

  return (
    <Spin spinning={loading && !expMainPage}>
      {expMainPage ? (
        <Form
          name="updateExpMainPage"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          style={{ maxWidth: 1000 }}
          disabled={loading}
          initialValues={expMainPage}
        >
          {BANNERS_POSITION.map(({ id, text, field, color }) => (
            <Fragment key={id}>
              <h3 style={{ marginBottom: 5 }}>
                <Tag color={color}>{text}</Tag>
              </h3>
              <Form.Item name={field} label="Баннеры" rules={[SELECT_BANNERS]}>
                <LangBannerExp
                  form={form}
                  name={field}
                  list={list}
                  setSkip={setSkip}
                  setList={setList}
                />
              </Form.Item>
              <br />
            </Fragment>
          ))}

          <Form.Item name="videos" label="Видео" rules={[SELECT_VIDEOS]}>
            <LangVideo form={form} name="videos" isRequired />
          </Form.Item>
          <br />
          <br />

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={false}
          >
            Обновить главную страницу
          </Button>

          <Popconfirm
            onConfirm={onDelete}
            title="Вы уверены что хотите удалить?"
          >
            <Button
              danger
              style={{ marginLeft: 8 }}
              loading={loading}
              disabled={false}
            >
              Удалить данные из главной страницы
            </Button>
          </Popconfirm>
        </Form>
      ) : (
        <Empty description="Баннеры не были созданы на главной странице" />
      )}
    </Spin>
  );
};

export default BannersExperience;
