import { useState } from "react";
import { Form, Button, Input, Space, Select, Tooltip, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { LangField } from "@shared/uikit";
import { createContact } from "@store/slices/contactSlice";
import {
  EMAIL_CORRECT,
  REQUIRED,
  SELECT_REQ,
  validateURL,
} from "@constants/validateFields";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { Contact } from "@models/contacts";
import { authStore } from "@store/authorization/authStore";
import { ROLES_CONTACT } from "../utils";

const { Option } = Select;

export const CreateContact = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.contacts);
  const { userRole } = authStore;

  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [isSite, setIsSite] = useState(false);
  const [isNote, setIsNote] = useState(false);

  const onFinish = (values) => {
    const { siteTitle, type, email, ...data } = values || {};

    const typeC = userRole === "superAdmin" ? type : ROLES_CONTACT[userRole];

    const updatedData: Contact = {
      ...data,
      type: typeC,
      email: email || undefined,
      site: siteTitle
        ? {
            ...values.site,
            title: siteTitle,
          }
        : undefined,
    };

    dispatch(createContact(updatedData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        form.resetFields();
        onFormNotDirty();
        setIsSite(false);
        setIsNote(false);
      }
    });
  };

  return (
    <>
      <h2>Создать контакт</h2>
      <div>
        <Form
          name="CreateContact"
          onFinish={onFinish}
          form={form}
          layout="vertical"
          disabled={loading}
          style={{ maxWidth: 1000 }}
          initialValues={{
            workingHours: [{ ru: "", en: "", ky: "" }],
            phone: [{ phoneNumber: "" }],
          }}
          onValuesChange={onFormDirty}
          scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
          <Form.Item name="title" label="Название" required>
            <LangField name="title" />
          </Form.Item>

          <Form.Item name="adreses" label="Адрес" required>
            <LangField name="adreses" />
          </Form.Item>

          {userRole === "superAdmin" && (
            <Form.Item name="type" label="Тип" rules={[SELECT_REQ]}>
              <Select placeholder="Выберите тип">
                <Option value="academy">Barça Academy</Option>
                <Option value="store">Barça Store</Option>
              </Select>
            </Form.Item>
          )}

          <Form.List name="workingHours">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                  <Space
                    key={key}
                    style={{
                      display: "block",
                      marginBottom: 8,
                      width: "100%",
                    }}
                    align="baseline"
                  >
                    <Tag color="cyan-inverse" style={{ marginBottom: 10 }}>
                      Рабочее время ({idx + 1})
                    </Tag>

                    <Form.Item
                      {...restField}
                      name={[name, "ru"]}
                      fieldKey={[fieldKey, "ru"]}
                      label={
                        <div>
                          <span style={{ marginRight: 10 }}>(RU)</span>
                          <Tooltip title="Время работы: ( по будням, выходным, по праздникам )">
                            <Tag color="green">
                              Пример ( скобки необязательны )
                            </Tag>
                          </Tooltip>
                        </div>
                      }
                      rules={[REQUIRED]}
                      style={{ flex: 1 }}
                    >
                      <Input placeholder="Введите рабочее время (RU)" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "ky"]}
                      fieldKey={[fieldKey, "ky"]}
                      label={
                        <div>
                          <span style={{ marginRight: 10 }}>(KY)</span>
                          <Tooltip title="дүйшөмбү: 09:00 - 18:30">
                            <Tag color="green">Мисал</Tag>
                          </Tooltip>
                        </div>
                      }
                      rules={[REQUIRED]}
                    >
                      <Input placeholder="Введите рабочее время (KY)" />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "en"]}
                      fieldKey={[fieldKey, "en"]}
                      label={
                        <div>
                          <span style={{ marginRight: 10 }}>(EN)</span>
                          <Tooltip title="Mon-Sat: 09:00 - 18:30">
                            <Tag color="green">Example</Tag>
                          </Tooltip>
                        </div>
                      }
                      rules={[REQUIRED]}
                    >
                      <Input placeholder="Введите рабочее время (EN)" />
                    </Form.Item>

                    {idx > 0 && (
                      <Button danger onClick={() => remove(name)}>
                        Удалить
                      </Button>
                    )}
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add({ ru: "", en: "", ky: "" })}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить рабочее время
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item name="email" label="Email" rules={[EMAIL_CORRECT]}>
            <Input />
          </Form.Item>

          <Form.List name="phone">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                  <Space
                    key={key}
                    style={{
                      display: "block",
                      marginBottom: 8,
                      width: "100%",
                    }}
                    align="baseline"
                  >
                    <Tag color="blue-inverse" style={{ marginBottom: 10 }}>
                      Номер телефона ({idx + 1})
                    </Tag>

                    <Form.Item
                      {...restField}
                      name={[name, "phoneNumber"]}
                      fieldKey={[fieldKey, "phoneNumber"]}
                      label="Номер телефона"
                      rules={[REQUIRED]}
                      style={{ flex: 1 }}
                    >
                      <Input placeholder="Пример: +996(555)12-06-56" />
                    </Form.Item>

                    {idx > 0 && (
                      <Button danger onClick={() => remove(name)}>
                        Удалить
                      </Button>
                    )}
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add({ phoneNumber: "" })}
                    block
                    icon={<PlusOutlined />}
                  >
                    Добавить номер
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item name="fax" label="Факс">
            <Input />
          </Form.Item>

          {!isSite && (
            <>
              <Button type="default" onClick={() => setIsSite(true)}>
                Добавить сайт
              </Button>
              <br />
              <br />
            </>
          )}
          {isSite && (
            <>
              <Form.Item name="siteTitle" label="Название сайта" required>
                <LangField name="siteTitle" />
              </Form.Item>

              <Form.Item
                name={["site", "url"]}
                label="Ссылка на сайт"
                rules={[REQUIRED, { validator: validateURL }]}
              >
                <Input />
              </Form.Item>
            </>
          )}

          {!isNote && (
            <>
              <Button type="default" onClick={() => setIsNote(true)}>
                Добавить примечание
              </Button>
              <br />
              <br />
            </>
          )}
          {isNote && (
            <Form.Item name="note" label="Примечание" required>
              <LangField name="note" />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={false}
            >
              Создать контакт
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
