import { Carousel, Image } from "antd";
import { MultiLangUI } from "@shared/uikit";

export const columnsData = [
  {
    title: "Баннеры",
    dataIndex: "banners",
    key: "banners",
    render: (banners) => {
      return banners ? (
        <Carousel style={{ width: "120px" }} autoplay dots={false} arrows>
          {Object.keys(banners).map((key) => (
            <div key={key}>
              <Image
                src={banners[key].banner}
                width={120}
                height={100}
                style={{ objectFit: "contain" }}
              />
            </div>
          ))}
        </Carousel>
      ) : (
        "Нет баннеров"
      );
    },
  },
  {
    title: "Название (RU)",
    dataIndex: "title",
    key: "title",
    render: (title) => {
      return <MultiLangUI data={title} />;
    },
  },
  {
    title: "Описание (RU)",
    dataIndex: "description",
    key: "description",
    render: (description) => {
      return <MultiLangUI data={description} desc />;
    },
  },
];
