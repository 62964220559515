import { Form, Button, InputNumber, Select } from "antd";
import { createProduct } from "@store/slices/productsSlice";
import { LangField, SelectUploadUI, UploadFilesUI } from "@shared/uikit";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import {
  ColorItems,
  SexItems,
  SizeItems,
  SizeShoesItems,
  TypeItems,
} from "../consts";
import { REQUIRED, SELECTED_FILE, SELECT_REQ } from "@constants/validateFields";
import { fetchCategories } from "@store/slices/categorySlice";
import { fetchCollection } from "@store/slices/collectionSlice";
import styled from "styled-components";
import { Product, ProductType } from "@models/products";
import { useFilesUpload } from "@constants/api";
import { useState } from "react";

const FormSize = styled(Form.Item)`
  margin-bottom: 0px;

  .ant-form-item-control-input-content {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
`;

export const CreateProduct = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.products);

  const {
    filesUpload: { uploadFiles, loadingFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [productType, setProductType] = useState<ProductType>(null);

  const onTypeChange = (value: ProductType) => {
    setProductType(value);
  };

  const onFinish = async (values: Product) => {
    const images = await uploadFiles(values.images);

    const updatedValues = { ...values, images };

    if (updatedValues.sizes) {
      Object.keys(updatedValues.sizes).forEach((key) => {
        if (updatedValues.sizes[key] == null) {
          updatedValues.sizes[key] = 0;
        }
      });
    }

    if (images) {
      dispatch(createProduct(updatedValues)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          form.resetFields();
        }
      });
    }
  };

  return (
    <>
      <h2>Создать Товар</h2>
      <Form
        form={form}
        layout="vertical"
        name="createProduct"
        style={{ maxWidth: 1000 }}
        onFinish={onFinish}
        onValuesChange={onFormDirty}
        disabled={loading || loadingFiles}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
      >
        <Form.Item name="name" label="Название" required>
          <LangField name="name" />
        </Form.Item>

        <Form.Item name="description" label="Описание" required>
          <LangField name="description" isTextArea />
        </Form.Item>

        <Form.Item name="color" label="Цвет" rules={[SELECT_REQ]}>
          <Select options={ColorItems} style={{ width: "250px" }} />
        </Form.Item>

        <Form.Item name="product_sex" label="Для кого" rules={[SELECT_REQ]}>
          <Select options={SexItems} style={{ width: "250px" }} />
        </Form.Item>

        <Form.Item name="categoryId" label="Категорий" rules={[SELECT_REQ]}>
          <SelectUploadUI request={fetchCategories} />
        </Form.Item>

        <Form.Item name="collectionId" label="Коллекция" rules={[SELECT_REQ]}>
          <SelectUploadUI request={fetchCollection} />
        </Form.Item>

        <Form.Item
          name="product_type"
          label="Тип продукта"
          rules={[SELECT_REQ]}
          style={{ width: "250px" }}
        >
          <Select options={TypeItems} onChange={onTypeChange} />
        </Form.Item>

        {productType === "clothes" && (
          <FormSize
            label="Количество размеров одежды"
            style={{ display: "flex" }}
          >
            {SizeItems.map((size) => (
              <Form.Item key={size} name={["sizes", size]} label={size}>
                <InputNumber
                  type="number"
                  defaultValue={0}
                  min={0}
                  placeholder={size}
                />
              </Form.Item>
            ))}
          </FormSize>
        )}

        {productType === "shoes" && (
          <FormSize
            label="Количество размеров обуви"
            style={{ display: "flex" }}
          >
            {SizeShoesItems.map((size) => (
              <Form.Item key={size} name={["sizes", size]} label={size}>
                <InputNumber type="number" defaultValue={0} min={0} />
              </Form.Item>
            ))}
          </FormSize>
        )}

        {productType === "none" && (
          <Form.Item name="quantity" label="Общее кол-во" rules={[REQUIRED]}>
            <InputNumber type="number" min={0} />
          </Form.Item>
        )}

        <Form.Item name="images" label="Изображения" rules={[SELECTED_FILE]}>
          <UploadFilesUI form={form} name="images" />
        </Form.Item>

        <Form.Item
          name="old_price"
          label="Старая цена"
        >
          <InputNumber type="number" min={0} />
        </Form.Item>

        <Form.Item
          name="current_price"
          label="Текущая цена"
          rules={[{ required: true, message: "Пожалуйста, введите цену" }]}
        >
          <InputNumber type="number" min={0} />
        </Form.Item>
        <br />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading || loadingFiles}
            disabled={false}
          >
            Создать Товар
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
