import { Popconfirm, Table, Tooltip } from "antd";
import { useEffect, useState } from "react";
import columns from "../columns";
import {
  DeleteOutlined,
  EditOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import {
  archiveVacancies,
  deleteVacanciesSoft,
  fetchVacancies,
  setCurrentPage,
  setParamsType,
} from "@store/slices/vacanciesSlice";
import { LIMIT } from "@constants/variables";
import UpdateVacancy from "@features/vacancies/UpdateVacancy/UpdateVacancy";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { useFilter } from "../useFilter";
import { Filters } from "../Filters";

export const ActiveVacancies = ({ type }) => {
  const dispatch = useAppDispatch();
  const { vacanciesList, loading, amount, params } = useAppSelector(
    (state) => state.vacancies
  );
  const { skip, page } = params;

  const { experience, employment, work_type, vacancyCity, ...others } =
    useFilter();

  const [editingVacancy, setEditingVacancy] = useState(null);

  const updatedColumns = [
    ...columns,
    {
      title: "Действия",
      dataIndex: "",
      key: "",
      render: (record) => {
        const deleteSoft = () => {
          dispatch(deleteVacanciesSoft({ ids: [record.id] }));
        };

        const handleArchive = () => {
          dispatch(archiveVacancies({ ids: [record.id] }));
        };

        const handleEdit = () => {
          setEditingVacancy(record);
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Редактировать" placement="bottom">
              <EditOutlined onClick={handleEdit} />
            </Tooltip>
            <Popconfirm
              title="Архивировать вакансию?"
              onConfirm={handleArchive}
            >
              <Tooltip title="Архивировать" placement="bottom">
                <FileZipOutlined />
              </Tooltip>
            </Popconfirm>
            <Popconfirm title="Удалить вакансию?" onConfirm={deleteSoft}>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onClose = () => {
    setEditingVacancy(null);
  };

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    dispatch(
      fetchVacancies({
        skip,
        limit: LIMIT,
        type,
        experience,
        employment,
        work_type,
        status: "active",
        vacancyCity,
      })
    );
  }, [dispatch, employment, experience, skip, type, vacancyCity, work_type]);
  
  useEffect(() => {
    dispatch(setParamsType({ type: "vacancy_type", value: type }));
    dispatch(setParamsType({ type: "status", value: "active" }));
  }, [dispatch, type]);

  return (
    <div>
      <Filters
        experience={experience}
        employment={employment}
        work_type={work_type}
        vacancyCity={vacancyCity}
        {...others}
      />

      <Table
        dataSource={vacanciesList}
        loading={loading}
        columns={updatedColumns}
        rowKey="id"
        scroll={{ x: true }}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editingVacancy && (
        <UpdateVacancy onClose={onClose} vacancy={editingVacancy} />
      )}
    </div>
  );
};
