export const AuthApi = {
  Login: "/admin/auth/login",
  Register: "/admin/auth/register",
};
export const NewsApi = {
  news: "/admin/news",
};

export const TrainersApi = {
  trainers: "/admin/trainers",
};

export const ContactApi = {
  contact: "/admin/contact",
};

export const InfoApi = {
  info: "/admin/info-pages",
};

export const FaqApi = {
  faq: "/admin/question-answers",
};

export const ProductApi = {
  Products: "/admin/products",
};

export const CategoryApi = {
  Categories: "/admin/product-categories",
};

export const FundersApi = {
  Funders: "/admin/funders",
};

export const ManagementApi = {
  Managements: "/admin/managements",
};

export const CafeApi = {
  cafe: "/admin/cafes",
};

export const ClinicApi = {
  clinic: "/admin/clinics",
};

export const HotelApi = {
  hotels: "/admin/hotels",
};

export const ApplicationApi = {
  applications: "/admin/applications",
};

export const MuseumApi = {
  museum: "/admin/museums",
};

export const CollectionApi = {
  collection: "/admin/product-collections",
};

export const OrderApi = {
  orders: "/admin/orders",
};

export const VacanciesApi = {
  vacancy: "/admin/vacancies",
};

export const UsersApi = {
  users: "/admin/users",
};
export const JalApplicationApi = {
  jalApplications: "/admin/jalgroup-applications",
};

export const ApartmentApi = {
  apartments: "/admin/apartments",
};

export const ProjectBlockApi = {
  blocks: "/admin/project-blocks",
};

export const ProjectApi = {
  projects: "/admin/projects",
};

export const VacancyReplyApi = {
  vacancyReplies: "/admin/vacancy-replies",
};

export const SchoolApi = {
  school: "/admin/schools",
};

export const SocialNetworkApi = {
  socialNetwork: "/admin/social-networks",
};

export const CreateAdminApi = {
  createAdmin: "/admin/auth/register",
};

export const ListAdminApi = {
  createAdmin: "/admin/admins",
};

export const CampApi = {
  camp: "/admin/camps",
};

export const BannerApi = {
  banner: "/admin/banners",
};

export const VideoApi = {
  video: "/admin/videos",
};
export const ServiceItemApi = {
  serviceItem: "/admin/service-items",
};

export const ChampionshipApi = {
  championship: "/admin/championships",
};

export const ProjectExperienceApi = {
  projectExperience: "/admin/the-project",
};

export const FinReport = {
  finReport: "/admin/finreports",
};

export const ExpMainPageApi = {
  expMainPage: "/admin/experience-mainpages",
};
