import { Form, Button, Input } from "antd";
import styled from "styled-components";
import useSubmitData from "../hooks/useHandleSubmit";

const FormBlock = styled.div`
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    align-items: center;
  }
`;

export const FormComponent = () => {
  const [form] = Form.useForm();
  const { handleSubmit } = useSubmitData();

  const requiredPassword = {
    required: true,
    message: "Пожалуйста введите ваш пароль!",
  };

  const requiredUsername = {
    required: true,
    message: "Пожалуйста, введите Ваш логин!",
  };

  return (
    <FormBlock>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        name="signInForm"
      >
        <Form.Item label="Логин" name="username" rules={[requiredUsername]}>
          <Input placeholder="Логин" />
        </Form.Item>

        <Form.Item label="Пароль" name="password" rules={[requiredPassword]}>
          <Input.Password placeholder="*****" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </FormBlock>
  );
};
