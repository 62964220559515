/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { SliceConstants } from "@constants/slices";
import { ClinicApi, deleteFile } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { CommonName, LangIds } from "@models/common";
import { Banner } from "./bannerSlice";
import { VideoType } from "./videoSlice";
import { ServiceItem } from "./serviceItemSlice";
import $api from "src/app/axios/axiosInstance";

export type ClinicType = {
  id?: string;
  title: CommonName;
  text: CommonName;
  banners: LangIds;
  videos: LangIds;
  images: string[];
  service_items_ids: string[];
};

export type ClinicTypeRes = {
  id?: string;
  title: CommonName;
  text: CommonName;
  banners: {
    ru: Banner;
    ky: Banner;
    en: Banner;
  };
  videos: {
    ru: VideoType;
    ky: VideoType;
    en: VideoType;
  };
  images: string[];
  service_items_ids: string[];
  service_items: ServiceItem[];
};

interface ClinicState {
  clinic?: ClinicTypeRes;
  loading: boolean;
  error?: string | null;
}

export const fetchClinic = createAsyncThunk(
  SliceConstants.fetchClinic,
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${ClinicApi.clinic}`);

      return response.data.items[0];
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createClinic = createAsyncThunk(
  SliceConstants.createClinic,
  async (data: ClinicType, { rejectWithValue }) => {
    try {
      const response = await $api.post(ClinicApi.clinic, data);

      notification.success({
        message: "Клиника создана",
      });

      return response.data;
    } catch (error: any) {
      if (data.images?.length) {
        await Promise.all(
          data.images.map(
            async (img) =>
              await deleteFile(img).catch((err) => {
                console.error("Failed to delete file:", img, err);
              })
          )
        );
      }

      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateClinic = createAsyncThunk(
  SliceConstants.updateClinic,
  async ({ id, ...data }: ClinicType, { rejectWithValue }) => {
    try {
      const response = await $api.put(`${ClinicApi.clinic}/${id}`, data);

      notification.success({
        message: "Клиника обновлена",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteClinic = createAsyncThunk(
  SliceConstants.deleteClinic,
  async (id: string, { rejectWithValue }) => {
    try {
      await $api.delete(`${ClinicApi.clinic}/delete/${id}`);

      notification.success({
        message: "Клиника удалена",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: ClinicState = {
  clinic: null,
  loading: false,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const Cliniclice = createSlice({
  name: "clinic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchClinic, (state: any, action: any) => {
      state.clinic = action.payload;
    });

    handleAsyncAction(builder, createClinic, (state: any, action: any) => {
      state.clinic = action.payload;
    });

    handleAsyncAction(builder, updateClinic, (state: any, action: any) => {
      state.clinic = action.payload;
    });

    handleAsyncAction(builder, deleteClinic, (state: any, action: any) => {
      state.clinic = null;
    });
  },
});

export default Cliniclice.reducer;
