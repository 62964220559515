/* eslint-disable no-param-reassign */
import { message, notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { ServiceItemApi, deleteFile } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { CommonName, CommonParams, LangIds } from "@models/common";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import { LIMIT } from "@constants/variables";
import $api from "src/app/axios/axiosInstance";

export type ServiceItemType =
  | "cafe"
  | "museum"
  | "camp"
  | "clinic"
  | "hotel"
  | "championship";

export type ServiceItem = {
  id?: string;
  title: CommonName;
  text: CommonName;
  description?: CommonName;
  table?: CommonName;
  banners: LangIds;
  images: string[];
  service_item_type: ServiceItemType;
};

interface ServiceItemState {
  serviceItemList: ServiceItem[];
  loading: boolean;
  amount: number;
  error: string | null;
  params: {
    type: ServiceItemType;
    skip: number;
    page: number;
  };
}

export const fetchServiceItem = createAsyncThunk(
  SliceConstants.fetchServiceItem,
  async (
    { type, ...params }: { type: ServiceItemType } & CommonParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.get(
        `${ServiceItemApi.serviceItem}?service_item_type=${type}`,
        { params }
      );

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createServiceItem = createAsyncThunk(
  SliceConstants.createServiceItem,
  async (data: ServiceItem, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(ServiceItemApi.serviceItem, data);

      const store = getState() as RootState;
      const { page } = store.serviceItem.params;

      if (createValidate([], page)) {
        await dispatch(
          fetchServiceItem({
            skip: 0,
            limit: LIMIT,
            type: data.service_item_type,
          })
        );
      } else {
        dispatch(resetParams());
      }

      notification.success({
        message: "Сервиc создан",
      });

      return response.data;
    } catch (error: any) {
      if (data.images?.length) {
        await Promise.all(
          data.images.map(
            async (img) =>
              await deleteFile(img).catch((err) => {
                console.error("Failed to delete file:", img, err);
              })
          )
        );
      }

      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateServiceItem = createAsyncThunk(
  SliceConstants.updateServiceItem,
  async (
    { id, ...data }: ServiceItem,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await $api.put(
        `${ServiceItemApi.serviceItem}/${id}`,
        data
      );

      const store = getState() as RootState;
      const { skip } = store.serviceItem.params;
      await dispatch(
        fetchServiceItem({
          skip,
          limit: LIMIT,
          type: data.service_item_type,
        })
      );

      notification.success({
        message: "Сервиc обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteServiceItem = createAsyncThunk(
  SliceConstants.deleteServiceItem,
  async (
    { id, ...data }: ServiceItem,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      await $api.delete(`${ServiceItemApi.serviceItem}/delete/${id}`);

      const store = getState() as RootState;
      const { serviceItemList } = store.serviceItem;
      const { skip, page } = store.serviceItem.params;

      const currentPage = deleteValidate(skip, serviceItemList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, serviceItemList);

        await dispatch(
          fetchServiceItem({
            skip: newSkip,
            limit: LIMIT,
            type: data.service_item_type,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      message.success("Сервиc удален");

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: ServiceItemState = {
  serviceItemList: [],
  amount: 0,
  loading: false,
  error: null,
  params: {
    type: null,
    skip: 0,
    page: 1,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const ServiceItemSlice = createSlice({
  name: "serviceItem",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params[action.payload.type] = action.payload.value;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetParams(state) {
      state.params.type = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchServiceItem, (state: any, action: any) => {
      state.serviceItemList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(
      builder,
      createServiceItem,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      updateServiceItem,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      deleteServiceItem,
      (state: any, action: any) => {}
    );
  },
});

export const { setParamsType, setCurrentPage, resetParams } =
  ServiceItemSlice.actions;
export default ServiceItemSlice.reducer;
