/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { SocialNetworkApi } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { LIMIT } from "@constants/variables";
import { NetworkType, SocialNetwork } from "@models/socialNetwork";
import { City, CommonParams } from "@models/common";
import { RootState } from "..";
import { createValidate } from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

interface SocialState {
  socialNetworks: SocialNetwork[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    type?: City;
    socialType?: NetworkType;
    skip: number;
    page: number;
  };
}

export const fetchNetworks = createAsyncThunk(
  SliceConstants.fetchNetworks,
  async (
    params: { social_network_type?: NetworkType; type?: City } & CommonParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.get(`${SocialNetworkApi.socialNetwork}`, {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createSocialNetwork = createAsyncThunk(
  SliceConstants.createNetwork,
  async (data: SocialNetwork, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(SocialNetworkApi.socialNetwork, data);

      const store = getState() as RootState;
      const { type, socialType, page } = store.socialNetworks.params;

      if (createValidate([socialType], page)) {
        await dispatch(fetchNetworks({ skip: 0, limit: LIMIT, type }));
      } else {
        dispatch(resetParams());
      }

      notification.success({
        message: "Соц.сеть создана",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateSocialNetwork = createAsyncThunk(
  SliceConstants.updateNetwork,
  async (
    { id, ...data }: SocialNetwork,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await $api.put(
        `${SocialNetworkApi.socialNetwork}/${id}`,
        data
      );

      const store = getState() as RootState;
      const { type, skip, socialType } = store.socialNetworks.params;
      await dispatch(
        fetchNetworks({
          skip,
          limit: LIMIT,
          type,
          social_network_type: socialType,
        })
      );

      notification.success({
        message: "Соц.сеть обновлена",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteSocialNetwork = createAsyncThunk(
  SliceConstants.deleteNetwork,
  async ({ id }: SocialNetwork, { rejectWithValue }) => {
    try {
      await $api.delete(`${SocialNetworkApi.socialNetwork}/delete/${id}`);
      notification.success({
        message: "Соц.сеть удалена",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: SocialState = {
  socialNetworks: [],
  loading: false,
  amount: 0,
  error: null,
  params: {
    page: 1,
    skip: 0,
    type: null,
    socialType: null,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const socialNetworkSlice = createSlice({
  name: "socialNetwork",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params.type = action.payload;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setParamsSocialType(state, action) {
      state.params.socialType = action.payload;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetParams(state) {
      state.params.socialType = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
    resetAll(state) {
      state.params.type = null;
      state.params.socialType = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchNetworks, (state: any, action: any) => {
      state.socialNetworks = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(
      builder,
      createSocialNetwork,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      updateSocialNetwork,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      deleteSocialNetwork,
      (state: any, action: any) => {
        state.socialNetworks = state.socialNetworks.filter(
          (social: SocialNetwork) => social.id !== action.payload
        );
      }
    );
  },
});

export const {
  setParamsType,
  setParamsSocialType,
  setCurrentPage,
  resetParams,
  resetAll,
} = socialNetworkSlice.actions;
export default socialNetworkSlice.reducer;
