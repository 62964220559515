import { RuleObject } from "rc-field-form/lib/interface";

export const codeValidates = /^[0-9]{3}-[0-9]{3}$/;
export const passwordValidates = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
const forbiddenChars = /[^A-Za-z0-9!@&()._]/;

export const validatePassword = (
  rule: RuleObject,
  value: string,
  callback: (is?: string) => void
) => {
  const pattern = passwordValidates;
  if (value) {
    if (value.length < 8) {
      callback("Не менее 8 символов");
    } else if (forbiddenChars.test(value)) {
      callback("Используйте только буквы (a–z, A–Z), цифры и символы !@&()._");
    } else if (!pattern.test(value)) {
      callback(
        "Пароль должен содержать минимум одну заглавную букву, цифру и один символ"
      );
    } else {
      callback();
    }
  } else {
    callback();
  }
};
