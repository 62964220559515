import { FC } from "react";
import { Button, Modal, ModalProps } from "antd";
import styles from "./ModalUI.module.scss";

type ModalUIProps = {
  width?: "sm" | "md" | "lg";
  cancelHide?: boolean;
  okHide?: boolean;
} & ModalProps;

export const ModalUI: FC<ModalUIProps> = (props) => {
  const {
    children,
    onCancel,
    cancelHide = false,
    width,
    loading,
    onOk,
    okHide = false,
    okText,
    cancelText,
    className,
    ...others
  } = props;

  return (
    <Modal
      maskClosable={false}
      closable={false}
      cancelText="Отмена"
      onCancel={onCancel}
      footer={[
        <Button
          type="default"
          onClick={onCancel}
          disabled={loading}
          style={
            cancelHide
              ? { display: "none" }
              : { width: okHide ? "100%" : "auto" }
          }
        >
          {cancelText || "Отменить"}
        </Button>,
        <Button
          type="primary"
          onClick={onOk}
          loading={loading}
          style={
            okHide
              ? { display: "none" }
              : cancelHide
              ? { width: "100%", marginInlineStart: 0 }
              : {}
          }
        >
          {okText || "Обновить"}
        </Button>,
      ]}
      className={`${styles.modal} ${className} ${styles[width]}`}
      {...others}
    >
      <div>{children}</div>
    </Modal>
  );
};
