import { Tabs, TabsProps } from "antd";
import { FaqTableBish } from "./FaqTable";
import { CreateFaq } from "./CreateFaqBish";
import { useURLSearchParams } from "@shared/libs";

export const Faq = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Таблица Вопросы - Ответы",
      children: <FaqTableBish />,
    },
    {
      key: "2",
      label: "Создать Вопрос - Ответ",
      children: <CreateFaq />,
    },
  ];

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};
