/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { FaqApi } from "@constants/api";
import { Faq } from "@models/faq";
import { CommonParams } from "@models/common";
import { fetchInfo } from "./infoSlice";
import { LIMIT } from "@constants/variables";
import { RootState } from "..";
import {
  createValidate,
  deleteValidate,
  resetParams,
  setPagination,
} from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

interface faqState {
  // faqList: Faq[];
  loading: boolean;
  amount: number;
  error: string | null;
}

// не используем (вместо него fetchInfo)
export const fetchfaqs = createAsyncThunk(
  SliceConstants.FetchFaq,
  async (params: CommonParams, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${FaqApi.faq}`, {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createfaq = createAsyncThunk(
  SliceConstants.CreateFaq,
  async (data: Faq, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(FaqApi.faq, data);

      const store = getState() as RootState;
      const { page, type, title } = store.params;

      if (createValidate([], page) && !title) {
        await dispatch(fetchInfo({ skip: 0, limit: LIMIT, view_type: type }));
      } else {
        dispatch(resetParams());
      }

      notification.success({
        message: "Успешно создано",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updatefaq = createAsyncThunk(
  SliceConstants.UpdateFaq,
  async ({ id, ...data }: Faq, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.put(`${FaqApi.faq}/${id}`, data);

      const store = getState() as RootState;
      const { skip, type, title } = store.params;
      await dispatch(fetchInfo({ skip, limit: LIMIT, view_type: type, title }));

      notification.success({
        message: "Успешно обновлено",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deletefaq = createAsyncThunk(
  SliceConstants.DeleteFaq,
  async (id: string, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${FaqApi.faq}/delete/${id}`);

      const store = getState() as RootState;
      const { infoList } = store.info;
      const { skip, page, title, type } = store.params;

      const currentPage = deleteValidate(skip, infoList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, infoList);

        await dispatch(
          fetchInfo({ skip: newSkip, limit: LIMIT, title, view_type: type })
        );
      } else {
        dispatch(setPagination(currentPage));
      }

      notification.success({
        message: "Удалено",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

const initialState: faqState = {
  // faqList: [],
  loading: false,
  amount: 0,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchfaqs, (state: any, action: any) => {});

    handleAsyncAction(builder, createfaq, (state: any, action: any) => {});

    handleAsyncAction(builder, updatefaq, (state: any, action: any) => {});

    handleAsyncAction(builder, deletefaq, (state: any, action: any) => {});
  },
});

export default faqSlice.reducer;
