/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { MuseumApi, deleteFile } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { City, ServiceType } from "@models/common";
import { ServiceItem } from "./serviceItemSlice";
import $api from "src/app/axios/axiosInstance";

export type MuseumType = {
  id?: string;
  museum_type: City;
} & ServiceType;

export type MuseumTypeRes = {
  id: string;
  museum_type: City;
  service_items: ServiceItem[];
} & ServiceType;

interface MuseumState {
  museum?: MuseumTypeRes;
  loading: boolean;
  error?: string | null;
}

export type ArgumentsType = {
  type: City;
};

export const fetchMuseums = createAsyncThunk(
  SliceConstants.fetchMuseums,
  async ({ type }: ArgumentsType, { rejectWithValue }) => {
    try {
      const response = await $api.get(
        `${MuseumApi.museum}?museum_type=${type}`
      );

      return response.data.items[0];
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createMuseum = createAsyncThunk(
  SliceConstants.createMuseum,
  async (data: MuseumType, { rejectWithValue }) => {
    try {
      const response = await $api.post(MuseumApi.museum, data);

      notification.success({
        message: "Музей создан",
      });

      return response.data;
    } catch (error: any) {
      if (data.images?.length) {
        await Promise.all(
          data.images.map(
            async (img) =>
              await deleteFile(img).catch((err) => {
                console.error("Failed to delete file:", img, err);
              })
          )
        );
      }

      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateMuseum = createAsyncThunk(
  SliceConstants.updateMuseum,
  async ({ id, ...data }: MuseumType, { rejectWithValue }) => {
    try {
      const response = await $api.put(`${MuseumApi.museum}/${id}`, data);

      notification.success({
        message: "Музей обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteMuseum = createAsyncThunk(
  SliceConstants.deleteMuseum,
  async (id: string, { rejectWithValue }) => {
    try {
      await $api.delete(`${MuseumApi.museum}/delete/${id}`);

      notification.success({
        message: "Музей удален",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: MuseumState = {
  museum: null,
  loading: false,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const museumSlice = createSlice({
  name: "museum",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchMuseums, (state: any, action: any) => {
      state.museum = action.payload;
    });

    handleAsyncAction(builder, createMuseum, (state: any, action: any) => {
      state.museum = action.payload;
    });

    handleAsyncAction(builder, updateMuseum, (state: any, action: any) => {
      state.museum = action.payload;
    });

    handleAsyncAction(builder, deleteMuseum, (state: any, action: any) => {
      state.museum = null;
    });
  },
});

export default museumSlice.reducer;
