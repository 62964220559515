import { useEffect, useState } from "react";
import { Button, Empty, Form, Popconfirm, Spin, Tag } from "antd";
import {
  ChampionShipType,
  deleteChampiohShip,
  fetchChampiohShip,
  updateChampiohShip,
} from "@store/slices/championshipSlice";
import { LangEditor, LangField, LangVideo, Service } from "@shared/uikit";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";

export const Championship = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { championShip, loading } = useAppSelector(
    (state) => state.championShip
  );

  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [serviceItems, setServiceItems] = useState([]);

  useEffect(() => {
    dispatch(fetchChampiohShip());
  }, [dispatch]);

  useEffect(() => {
    if (championShip) {
      setServiceItems(championShip?.service_items || []);
    }
  }, [championShip]);

  const onFinish = async (values) => {
    const serviceIds = serviceItems.map((item) => item.id);

    const updatedData: ChampionShipType = {
      ...values,
      id: championShip.id,
      service_items_ids: serviceIds,
    };

    dispatch(updateChampiohShip(updatedData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        onFormNotDirty();
      }
    });
  };

  const onDelete = () => {
    dispatch(deleteChampiohShip(championShip.id));
  };

  return (
    <Spin spinning={loading && !championShip}>
      {championShip ? (
        <Form
          name="updateChampionship"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          style={{ maxWidth: 1000 }}
          disabled={loading}
          initialValues={championShip}
          onValuesChange={onFormDirty}
          scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
          <Form.Item name="title" label="Название" required>
            <LangField name="title" />
          </Form.Item>

          <Form.Item name="text" label="Текст" required>
            <LangEditor form={form} name="text" />
          </Form.Item>
          <br />

          <Form.Item name="videos" label="Видео">
            <LangVideo form={form} name="videos" />
          </Form.Item>
          <br />

          <Form.Item label="Сервисы">
            {serviceItems.length
              ? serviceItems.map((item) => (
                  <Tag>
                    <p key={item.id}>{item?.title.ru}</p>
                  </Tag>
                ))
              : "Не выбраны"}
          </Form.Item>

          <Service
            type="championship"
            selectedItems={serviceItems}
            onSelectServiceItems={(selectedItems) =>
              setServiceItems(selectedItems)
            }
          />

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={false}
          >
            Обновить чемпионат
          </Button>

          <Popconfirm
            onConfirm={onDelete}
            title="Вы уверены что хотите удалить?"
          >
            <Button
              danger
              style={{ marginLeft: 8 }}
              loading={loading}
              disabled={false}
            >
              Удалить чемпионат
            </Button>
          </Popconfirm>
        </Form>
      ) : (
        <Empty description="Пока нет чемпионата" />
      )}
    </Spin>
  );
};
