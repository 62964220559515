import { Vacancies } from "@features/vacancies";

export const VacanciesPage = () => {
  const type = "jalgroup";

  return (
    <div>
      <Vacancies type={type} />
    </div>
  );
};
