/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { TrainersApi, deleteFile } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { City, CommonName, CommonParams, Status } from "@models/common";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import { LIMIT } from "@constants/variables";
import $api from "src/app/axios/axiosInstance";

export type TrainerRole = "trainer" | "manager" | "coordinator" | "cto";

export type TrainerType = {
  id?: string;
  firstname: CommonName;
  lastname: CommonName;
  description: CommonName;
  trainer_type: City;
  role_type: TrainerRole;
  photo: string;
};

interface TrainerState {
  trainerList: TrainerType[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    status?: Status;
    trainer_type?: City;
    skip: number;
    page: number;
  };
}

interface ArgumentsType extends CommonParams {
  status: Status;
  trainer_type: string;
}

export const fetchTrainers = createAsyncThunk(
  SliceConstants.FetchTrainers,
  async (params: ArgumentsType, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${TrainersApi.trainers}`, {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createTrainer = createAsyncThunk(
  SliceConstants.CreateTrainer,
  async (data: TrainerType, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(TrainersApi.trainers, data);

      const store = getState() as RootState;
      const { page, trainer_type, status } = store.trainers.params;

      if (status && status === "active") {
        if (createValidate([], page)) {
          await dispatch(
            fetchTrainers({
              skip: 0,
              limit: LIMIT,
              status,
              trainer_type,
            })
          );
        } else {
          dispatch(resetParams());
        }
      }

      notification.success({
        message: "Тренер создан",
      });

      return response.data;
    } catch (error: any) {
      await deleteFile(data.photo);
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateTrainer = createAsyncThunk(
  SliceConstants.UpdateTrainer,
  async (
    { id, ...data }: TrainerType,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await $api.put(`${TrainersApi.trainers}/${id}`, data);

      const store = getState() as RootState;
      const { skip, status, trainer_type } = store.trainers.params;
      await dispatch(
        fetchTrainers({
          skip,
          limit: LIMIT,
          status,
          trainer_type,
        })
      );

      notification.success({
        message: "Тренер обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteTrainer = createAsyncThunk(
  SliceConstants.DeleteTrainer,
  async (id: string, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${TrainersApi.trainers}/delete/${id}`);

      const store = getState() as RootState;
      const { trainerList, params } = store.trainers;
      const { skip, page, trainer_type, status } = params;

      const currentPage = deleteValidate(skip, trainerList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, trainerList);

        await dispatch(
          fetchTrainers({
            skip: newSkip,
            limit: LIMIT,
            trainer_type,
            status,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Тренер удален полностью",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const archiveTrainers = createAsyncThunk(
  SliceConstants.ArchiveTrainer,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${TrainersApi.trainers}/archive`, {
        ids,
      });

      const store = getState() as RootState;
      const { trainerList, params } = store.trainers;
      const { skip, page, trainer_type, status } = params;

      const currentPage = deleteValidate(skip, trainerList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, trainerList);

        await dispatch(
          fetchTrainers({
            skip: newSkip,
            limit: LIMIT,
            trainer_type,
            status,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Тренер архивирован",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);
export const activateTrainers = createAsyncThunk(
  SliceConstants.ActivateTrainer,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${TrainersApi.trainers}/active`, {
        ids,
      });

      const store = getState() as RootState;
      const { trainerList, params } = store.trainers;
      const { skip, page, trainer_type, status } = params;

      const currentPage = deleteValidate(skip, trainerList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, trainerList);

        await dispatch(
          fetchTrainers({
            skip: newSkip,
            limit: LIMIT,
            trainer_type,
            status,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Тренер активирован",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);
export const deleteTrainersSoft = createAsyncThunk(
  SliceConstants.DeleteSoftTrainer,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${TrainersApi.trainers}/delete`, {
        ids,
      });

      const store = getState() as RootState;
      const { trainerList, params } = store.trainers;
      const { skip, page, trainer_type, status } = params;

      const currentPage = deleteValidate(skip, trainerList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, trainerList);

        await dispatch(
          fetchTrainers({
            skip: newSkip,
            limit: LIMIT,
            trainer_type,
            status,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Тренер удален",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

const initialState: TrainerState = {
  trainerList: [],
  amount: 0,
  loading: false,
  error: null,
  params: {
    status: null,
    trainer_type: undefined,
    skip: 0,
    page: 1,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const trainerSlice = createSlice({
  name: "trainer",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params[action.payload.type] = action.payload.value;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetParams(state) {
      state.params.status = null;
      state.params.trainer_type = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchTrainers, (state: any, action: any) => {
      state.trainerList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(builder, createTrainer, (state: any, action: any) => {});

    handleAsyncAction(builder, updateTrainer, (state: any, action: any) => {});

    handleAsyncAction(
      builder,
      archiveTrainers,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      activateTrainers,
      (state: any, action: any) => {}
    );

    handleAsyncAction(builder, deleteTrainer, (state: any, action: any) => {});

    handleAsyncAction(
      builder,
      deleteTrainersSoft,
      (state: any, action: any) => {}
    );
  },
});

export const { setParamsType, setCurrentPage, resetParams } =
  trainerSlice.actions;
export default trainerSlice.reducer;
