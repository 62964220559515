import { Tabs, TabsProps } from "antd";
import { TrainersTableBish } from "./ContactTable";
import { CreateContact } from "./CreateContactBish";
import { useURLSearchParams } from "@shared/libs";

export const Contacts = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Таблица контактов",
      children: <TrainersTableBish />,
    },
    {
      key: "2",
      label: "Создать контакты",
      children: <CreateContact />,
    },
  ];

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};
