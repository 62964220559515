import { useEffect, useState } from "react";
import { Input, Popconfirm, Table, Tooltip } from "antd";
import {
  archiveProducts,
  deleteProductsSoft,
  fetchProducts,
  resetPagination,
  setCurrentPage,
  setNameSearch,
  setParamsType,
} from "@store/slices/productsSlice";
import { useAppDispatch, useAppSelector, useDebounce } from "@shared/libs";
import { LIMIT } from "@constants/variables";
import {
  DeleteOutlined,
  EditOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import { UpdateProductModal } from "./UpdateProductModal";
import { columnsData } from "../columns";

export const ActiveProducts = () => {
  const dispatch = useAppDispatch();
  const { ProductList, loading, amount, params } = useAppSelector(
    (state) => state.products
  );
  const { skip, page, name } = params;

  const debouncedSearchQuery = useDebounce(name, () => {
    dispatch(resetPagination());
  });

  const [editingProduct, setEditingProduct] = useState(null);

  const handleSearchChange = (e) => {
    dispatch(setNameSearch(e.target.value));
  };

  const columns = [
    ...columnsData,
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record) => {
        const handleEdit = () => {
          const updatedData = {
            ...record,
            categoryId: {
              value: record.category.id,
              label: record.category.name.ru,
            },
            collectionId: {
              value: record.collection.id,
              label: record.collection.name.ru,
            },
          };

          setEditingProduct(updatedData);
        };

        const handleArchive = () => {
          dispatch(archiveProducts({ ids: [record.id] }));
        };

        const deleteSoft = () => {
          dispatch(deleteProductsSoft({ ids: [record.id] }));
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Редактировать" placement="bottom">
              <EditOutlined onClick={handleEdit} />
            </Tooltip>

            <Popconfirm title="Архивировать продукт?" onConfirm={handleArchive}>
              <Tooltip title="Архивировать" placement="bottom">
                <FileZipOutlined />
              </Tooltip>
            </Popconfirm>

            <Popconfirm title="Удалить продукт?" onConfirm={deleteSoft}>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onClose = () => {
    setEditingProduct(null);
  };

  const onPageChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    dispatch(
      fetchProducts({
        status: "active",
        limit: LIMIT,
        skip: skip,
        name: debouncedSearchQuery,
      })
    );
  }, [page, skip, dispatch, debouncedSearchQuery]);

  useEffect(() => {
    dispatch(setParamsType({ type: "status", value: "active" }));
  }, [dispatch]);

  return (
    <div>
      <Input
        placeholder="Поиск по названию (RU)"
        onChange={handleSearchChange}
        value={name}
      />
      <br />
      <br />
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={ProductList}
        rowKey="id"
        loading={loading}
        pagination={{
          current: page,
          pageSize: LIMIT,
          total: amount,
          onChange: onPageChange,
          showSizeChanger: false,
        }}
      />

      {editingProduct && (
        <UpdateProductModal data={editingProduct} onClose={onClose} />
      )}
    </div>
  );
};
