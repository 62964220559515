// archived trainers
import { useEffect } from "react";
import { Table, Popconfirm, Tooltip } from "antd";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  activateTrainers,
  deleteTrainersSoft,
  fetchTrainers,
  resetParams,
  setCurrentPage,
  setParamsType,
} from "@store/slices/trainerSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { columnsData } from "../columns";
import { LIMIT } from "@constants/variables";

export const ArchivedTrainers = ({ type }) => {
  const dispatch = useAppDispatch();
  const { trainerList, loading, amount, params } = useAppSelector(
    (state) => state.trainers
  );
  const { skip, page } = params;

  const columns = [
    ...columnsData,
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record) => {
        const handleActivate = () => {
          dispatch(activateTrainers({ ids: [record.id] }));
        };

        const deleteSoft = () => {
          dispatch(deleteTrainersSoft({ ids: [record.id] }));
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Popconfirm
              title="Активировать тренера?"
              onConfirm={handleActivate}
            >
              <Tooltip title="Активировать" placement="bottom">
                <CheckCircleOutlined />
              </Tooltip>
            </Popconfirm>

            <Popconfirm title="Удалить тренера?" onConfirm={deleteSoft}>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    dispatch(
      fetchTrainers({
        skip,
        limit: LIMIT,
        status: "archived",
        trainer_type: type,
      })
    );
  }, [dispatch, skip, type]);

  useEffect(() => {
    dispatch(setParamsType({ type: "trainer_type", value: type }));
    dispatch(setParamsType({ type: "status", value: "archived" }));
  }, [dispatch, type]);

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <div>
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={trainerList}
        loading={loading}
        rowKey="id"
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
