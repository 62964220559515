import { useState } from "react";
import { Button, Form, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  LangBanner,
  LangEditor,
  LangField,
  LangVideo,
  Service,
  UploadFilesUI,
} from "@shared/uikit";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { SELECT_BANNERS } from "@constants/validateFields";
import { CampType, createCamp } from "@store/slices/campSlice";

export const CreateCamp = ({ type }) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.camp);

  const {
    filesUpload: { uploadFiles, loadingFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [serviceItems, setServiceItems] = useState([]);

  const onFinish = async (values) => {
    const images = await uploadFiles(values.images);
    const serviceIds = serviceItems.map((item) => item.id);

    const updatedData: CampType = {
      ...values,
      images,
      camp_type: type,
      service_items_ids: serviceIds,
    };

    dispatch(createCamp(updatedData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        onFormNotDirty();
        form.resetFields();
      }
    });
  };

  return (
    <>
      <h2>Создать Лагерь</h2>
      <Form
        name="createCamp"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading || loadingFiles}
        style={{ maxWidth: 1000 }}
        onValuesChange={onFormDirty}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
      >
        <Form.Item name="title" label="Название" required>
          <LangField name="title" />
        </Form.Item>

        <Form.Item name="text" label="Текст" required>
          <LangEditor form={form} name="text" />
        </Form.Item>
        <br />

        <Form.Item name="images" label="Изображения">
          <UploadFilesUI form={form} name="images" />
        </Form.Item>
        <br />

        <Form.Item name="videos" label="Видео">
          <LangVideo form={form} name="videos" />
        </Form.Item>
        <br />

        <Form.Item name="banners" label="Баннеры" rules={[SELECT_BANNERS]}>
          <LangBanner
            form={form}
            name="banners"
            banner_type="service"
            isRequired
          />
        </Form.Item>
        <br />

        <Form.Item label="Сервисы">
          {serviceItems.length
            ? serviceItems.map((item) => (
                <Tag>
                  <p key={item.id}>{item?.title.ru}</p>
                </Tag>
              ))
            : "Не выбраны"}
        </Form.Item>

        <Service
          type="camp"
          onSelectServiceItems={(selectedItems) =>
            setServiceItems(selectedItems)
          }
        />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading || loadingFiles}
            disabled={false}
          >
            Создать Лагерь
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
