import { useEffect, useState } from "react";
import { banUsers, fetchUsers } from "@store/slices/usersSlice";
import { Button, Popconfirm, Table } from "antd";
import { columnsTable } from "../tableItems";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { LIMIT } from "@constants/variables";

export const UsersTable = () => {
  const dispatch = useAppDispatch();
  const { usersList, loading, amount } = useAppSelector((state) => state.users);

  const [skip, setSkip] = useState(0);

  useEffect(() => {
    dispatch(fetchUsers({ skip, limit: LIMIT, status: "active" }));
  }, [dispatch, skip]);

  const onChangePage = (page: number) => {
    setSkip((page - 1) * LIMIT);
  };

  const columns = [
    ...columnsTable,
    {
      title: "Действия",
      dataIndex: "id",
      key: "id",
      render: (id) => {
        const banUser = () => {
          dispatch(banUsers(id));
        };

        return (
          <Popconfirm title="Заблокировать пользователя?" onConfirm={banUser}>
            <Button danger>Заблокировать пользователя </Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <Table
        scroll={{ x: true }}
        dataSource={usersList}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          onChange: onChangePage,
          total: amount,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />
    </>
  );
};
