import { Image } from "antd";
import { MultiLangUI } from "@shared/uikit";
import { TrainerRole } from "@store/slices/trainerSlice";

const TRAINERTYPE: Record<TrainerRole, string> = {
  trainer: "Тренер",
  manager: "Менеджер",
  coordinator: "Координатор",
  cto: "Технический Директор",
};

export const columnsData = [
  {
    title: "Фото",
    dataIndex: "photo",
    key: "photo",
    render: (photo) => (
      <Image
        src={photo}
        width={80}
        style={{ maxHeight: 110, objectFit: "contain" }}
      />
    ),
  },
  {
    title: "Имя (RU)",
    dataIndex: "firstname",
    key: "firstname_ru",
    render: (firstname) => <MultiLangUI data={firstname} />,
  },
  {
    title: "Фамилия (RU)",
    dataIndex: "lastname",
    key: "lastname_ru",
    render: (lastname) => <MultiLangUI data={lastname} />,
  },
  {
    title: "Роль (RU)",
    dataIndex: "role_type",
    key: "role_type",
    render: (role_type) => TRAINERTYPE[role_type],
  },
];
