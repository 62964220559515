import { useEffect } from "react";
import { Tabs } from "antd";
import { CreateVacanсy } from "./CreateVacancy";
import { VacancyTable } from "./VacancyTable";
import Title from "antd/es/typography/Title";
import { useAppDispatch, useURLSearchParams } from "@shared/libs";
import { resetParams } from "@store/slices/vacanciesSlice";

export const Vacancies = ({ type }) => {
  const dispatch = useAppDispatch();
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const typeName = () => {
    if (type === "jalgroup") {
      return <span>Jalgroup</span>;
    } else if ((type = "barca-experience")) {
      return <span>Barça experience</span>;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <div>
      <Title>Вакансии {typeName()}</Title>
      <Tabs
        items={[
          {
            key: "1",
            label: "Таблица вакансий",
            children: <VacancyTable type={type} />,
          },
          {
            key: "2",
            label: "Создать вакансию",
            children: <CreateVacanсy type={type} />,
          },
        ]}
        onChange={setActiveTabs("table")}
        activeKey={activeTabsKey["table"]}
      />
    </div>
  );
};
