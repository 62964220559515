/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { SliceConstants } from "@constants/slices";
import { LIMIT } from "@constants/variables";
import { VacancyReplyApi } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { CommonParams, Type } from "@models/common";
import { deleteValidate } from "./paramsSlice";
import { Vacancy } from "@models/vacancies";
import $api from "src/app/axios/axiosInstance";

export type VacancyReplyStatus = "review" | "accepted" | "rejected";

export interface VacancyReplyType {
  id: string;
  name: string;
  phone: string;
  status: VacancyReplyStatus;
  coverLetter: string | null;
  cv: string;
  vacancy: Vacancy;
  createdAt: string;
  updatedAt: string;
}

interface VacancyReplyState {
  replyList: VacancyReplyType[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    status?: VacancyReplyStatus;
    vacancy_reply_type?: Type;
    skip: number;
    page: number;
  };
}

export const fetchVacanciesReply = createAsyncThunk(
  SliceConstants.fetchVacanciesReply,
  async (
    params: {
      status: VacancyReplyStatus;
      vacancy_reply_type: Type;
    } & CommonParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.get(`${VacancyReplyApi.vacancyReplies}`, {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const acceptVacancies = createAsyncThunk(
  SliceConstants.acceptVacanciesReply,
  async (
    { ids }: { ids: number[] },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await $api.patch(
        `${VacancyReplyApi.vacancyReplies}/accept`,
        {
          ids,
        }
      );

      const store = getState() as RootState;
      const { replyList, params } = store.vacancyReplies;
      const { status, skip, page, vacancy_reply_type } = params;

      const currentPage = deleteValidate(skip, replyList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, replyList);

        await dispatch(
          fetchVacanciesReply({
            skip: newSkip,
            limit: LIMIT,
            status,
            vacancy_reply_type,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Отклик на вакансию одобрен",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const rejectVacancies = createAsyncThunk(
  SliceConstants.rejectVacanciesReply,
  async (
    { ids }: { ids: number[] },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await $api.patch(
        `${VacancyReplyApi.vacancyReplies}/reject`,
        {
          ids,
        }
      );

      const store = getState() as RootState;
      const { replyList, params } = store.vacancyReplies;
      const { status, skip, page, vacancy_reply_type } = params;

      const currentPage = deleteValidate(skip, replyList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, replyList);

        await dispatch(
          fetchVacanciesReply({
            skip: newSkip,
            limit: LIMIT,
            status,
            vacancy_reply_type,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Отклик на вакансию отклонен!",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: VacancyReplyState = {
  replyList: [],
  amount: 0,
  loading: false,
  error: null,
  params: {
    status: null,
    vacancy_reply_type: null,
    page: 1,
    skip: 0,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const vacanciesReplySlice = createSlice({
  name: "vacanciesReply",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params[action.payload.type] = action.payload.value;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetParams(state) {
      state.params.status = null;
      state.params.vacancy_reply_type = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(
      builder,
      fetchVacanciesReply,
      (state: any, action: any) => {
        state.replyList = action.payload.items;
        state.amount = action.payload.amount;
      }
    );
    handleAsyncAction(
      builder,
      acceptVacancies,
      (state: any, action: any) => {}
    );
    handleAsyncAction(
      builder,
      rejectVacancies,
      (state: any, action: any) => {}
    );
  },
});

export const { setParamsType, setCurrentPage, resetParams } =
  vacanciesReplySlice.actions;
export default vacanciesReplySlice.reducer;
