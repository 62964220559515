import { Form, Button, Select } from "antd";
import { LangField, UploadFileUI } from "@shared/uikit";
import { createinfo } from "@store/slices/infoSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { SELECTED_FILE, SELECT_REQ } from "@constants/validateFields";
import { useFilesUpload } from "@constants/api";
import { authStore } from "@store/authorization/authStore";
import { Info } from "@models/info";
import { ROLES_INFO } from "../utils";

export const CreateInfo = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.info);
  const { userRole } = authStore;

  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async (values: Info) => {
    const image = await uploadFile(values.images);

    const view_type =
      userRole === "superAdmin" ? values.view_type : ROLES_INFO[userRole];

    const updatedData: Info = {
      ...values,
      view_type,
      text: { ru: "ru", ky: "ky", en: "en" },
      info_page_type: "faq",
      images: [image],
    };

    if (image) {
      dispatch(createinfo(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          form.resetFields();
          onFormNotDirty();
        }
      });
    }
  };

  return (
    <>
      <h2>Создать FAQ</h2>
      <div>
        <Form
          name="CreateContact"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          style={{ maxWidth: 1000 }}
          disabled={loading || loadingFile}
          onValuesChange={onFormDirty}
        >
          <div style={{ minWidth: "200px" }}>
            <Form.Item name="title" label="Название" required>
              <LangField name="title" />
            </Form.Item>

            {userRole === "superAdmin" && (
              <Form.Item name="view_type" label="Тип" rules={[SELECT_REQ]}>
                <Select placeholder="Выберите тип">
                  <Select.Option value="barca-experience">
                  Barça Academy
                  </Select.Option>
                  <Select.Option value="store">Barça Store</Select.Option>
                </Select>
              </Form.Item>
            )}

            <Form.Item
              name="images"
              label="Изображение"
              rules={[SELECTED_FILE]}
            >
              <UploadFileUI form={form} name="images" />
            </Form.Item>
          </div>
          <br />
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading || loadingFile}
              disabled={false}
            >
              Создать
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
