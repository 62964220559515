import { useState } from "react";
import { Button, Form, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import {
  ChampionShipType,
  createChampiohShip,
} from "@store/slices/championshipSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { LangEditor, LangField, LangVideo, Service } from "@shared/uikit";

export const CreateChampionship = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.championShip);

  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [serviceItems, setServiceItems] = useState([]);

  const onFinish = (values) => {
    const serviceIds = serviceItems.map((item) => item.id);

    const updatedData: ChampionShipType = {
      ...values,
      service_items_ids: serviceIds,
    };

    dispatch(createChampiohShip(updatedData)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        onFormNotDirty();
        form.resetFields();
      }
    });
  };

  return (
    <>
      <h2>Создать Чемпионат для Barça experience</h2>
      <Form
        name="createChampionship"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        disabled={loading}
        style={{ maxWidth: 1000 }}
        onValuesChange={onFormDirty}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
      >
        <Form.Item name="title" label="Название" required>
          <LangField name="title" />
        </Form.Item>

        <Form.Item name="text" label="Текст" required>
          <LangEditor form={form} name="text" />
        </Form.Item>
        <br />

        <Form.Item name="videos" label="Видео">
          <LangVideo form={form} name="videos" />
        </Form.Item>
        <br />

        <Form.Item label="Сервисы">
          {serviceItems.length
            ? serviceItems.map((item) => (
                <Tag>
                  <p key={item.id}>{item?.title.ru}</p>
                </Tag>
              ))
            : "Не выбраны"}
        </Form.Item>

        <Service
          type="championship"
          onSelectServiceItems={(selectedItems) =>
            setServiceItems(selectedItems)
          }
        />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={false}
          >
            Создать чемпионат
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
