import { FC } from "react";
import { Select } from "antd";
import styled from "styled-components";

const { Option } = Select;

const SelectWrapper = styled.div`
  display: flex;
  overflow: auto;
`;

const SelectSection = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 20px;
  padding: 10px 20px;
  align-items: center;
  max-width: 400px;
  min-width: 200px;
  margin: 10px;
`;

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

type FiltersProps = {
  experience: string;
  employment: string;
  work_type: string;
  vacancyCity: string;
  handleExperienceChange: (val: string) => void;
  handleEmploymentChange: (val: string) => void;
  handleCityChange: (val: string) => void;
  handleWorkTypeChange: (val: string) => void;
};

export const Filters: FC<FiltersProps> = (props) => {
  const {
    experience,
    employment,
    work_type,
    vacancyCity,
    handleExperienceChange,
    handleEmploymentChange,
    handleWorkTypeChange,
    handleCityChange,
  } = props;

  return (
    <SelectWrapper>
      <SelectSection>
        <SectionTitle>Опыт работы</SectionTitle>
        <Select
          style={{ width: "100%" }}
          placeholder="Выберите опыт работы"
          onChange={handleExperienceChange}
          value={experience}
        >
          <Option>Все параметры</Option>
          <Option value="none">Без опыта</Option>
          <Option value="less-one">Менее 1 года</Option>
          <Option value="one_three">1-3 года</Option>
          <Option value="three_six">3-6 лет</Option>
        </Select>
      </SelectSection>
      <SelectSection>
        <SectionTitle>Занятость</SectionTitle>
        <Select
          style={{ width: "100%" }}
          placeholder="Выберите занятость"
          onChange={handleEmploymentChange}
          value={employment}
        >
          <Option>Все параметры</Option>
          <Option value="full-time">Полная занятость</Option>
          <Option value="part-time">Частичная занятость</Option>
        </Select>
      </SelectSection>
      <SelectSection>
        <SectionTitle>Тип работы</SectionTitle>
        <Select
          style={{ width: "100%" }}
          placeholder="Выберите тип работы"
          onChange={handleWorkTypeChange}
          value={work_type}
        >
          <Option>Все параметры</Option>
          <Option value="remote">Удаленная работа</Option>
          <Option value="office">Офисная работа</Option>
        </Select>
      </SelectSection>
      <SelectSection>
        <SectionTitle>Город</SectionTitle>
        <Select
          style={{ width: "100%" }}
          placeholder="Выберите город"
          onChange={handleCityChange}
          value={vacancyCity}
        >
          <Option>Все параметры</Option>
          <Option value="bishkek">Бишкек</Option>
          <Option value="jalal-abad">Джалал-Абад</Option>
        </Select>
      </SelectSection>
    </SelectWrapper>
  );
};
