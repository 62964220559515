import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, FormInstance, Image, Select } from "antd";
import { Banner } from "@store/slices/bannerSlice";
import { useAppSelector } from "@shared/libs";
import { links } from "@enums/links";
import { LIMIT } from "@constants/variables";
import styles from "./LangBannerExp.module.scss";
import { CreateBannerOne } from "@shared/uikit";

interface LangBannerExpProps {
  form: FormInstance;
  name: string;
  list: Banner[];
  setSkip: Dispatch<SetStateAction<number>>;
  setList: Dispatch<SetStateAction<Banner[]>>;
}

const initialState = { ruId: 0, kyId: 0, enId: 0 };

export const LangBannerExp: FC<LangBannerExpProps> = (props) => {
  const { form, name, list, setSkip, setList } = props;

  const { loading, amount } = useAppSelector((state) => state.banners);

  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedLanguagesId, setSelectedLanguagesId] = useState(initialState);
  const [selectedBanners, setSelectedBanners] = useState({
    ru: null,
    ky: null,
    en: null,
  });

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const initialState = form.getFieldValue(name);

    if (initialState) {
      setSelectedBanners(initialState);
      setSelectedLanguagesId({
        ruId: initialState.ru?.id || 0,
        kyId: initialState.ky?.id || 0,
        enId: initialState.en?.id || 0,
      });
    }
  }, [form, name]);

  const observer = useRef<IntersectionObserver | null>(null);

  const handleBannerSelect = (value, lang) => {
    const selectedBanner = list.find((banner) => banner.id === value) || null;

    if (selectedBanners.ru) {
      setSelectedBanners((prevState) => ({
        ...prevState,
        [lang]: selectedBanner,
      }));
    } else {
      setSelectedBanners({
        ru: selectedBanner,
        ky: selectedBanner,
        en: selectedBanner,
      });
    }

    if (selectedLanguagesId.ruId) {
      setSelectedLanguagesId((prevIds) => ({
        ...prevIds,
        [`${lang}Id`]: value,
      }));
    } else {
      setSelectedLanguagesId({
        ruId: value,
        kyId: value,
        enId: value,
      });
    }
  };

  const lastCommentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && list.length < amount + count) {
          setSkip((prevSkip) => prevSkip + LIMIT + count);
          setCount(0);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, list.length, amount]
  );

  useEffect(() => {
    if (selectedLanguagesId.ruId !== 0) {
      form.setFieldValue(name, selectedLanguagesId);
      form.validateFields([name]);
    }
  }, [selectedLanguagesId]);

  return (
    <>
      <div className={styles.banners}>
        {["ru", "ky", "en"].map((lang) => (
          <div key={lang} className={styles.banner}>
            <div className={styles.title}>
              Баннер
              <img src={links[lang]} alt="" width={30} />
            </div>

            {selectedBanners[lang] && (
              <Image
                rootClassName={styles.imgBlock}
                className={styles.img}
                src={selectedBanners[lang].banner}
                fallback="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ="
                style={{ objectFit: "contain" }}
              />
            )}

            <Select
              className={styles.select}
              loading={loading}
              placeholder={`Выберите баннеры (${lang})`}
              value={selectedLanguagesId[`${lang}Id`] || null}
              onChange={(value) => handleBannerSelect(value, lang)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  {loading && (
                    <div style={{ textAlign: "center" }}>Загрузка...</div>
                  )}
                </>
              )}
            >
              {list.map((banner, index) => (
                <Select.Option key={banner.id} value={banner.id}>
                  <Image
                    src={banner.banner}
                    width={80}
                    height={50}
                    preview={false}
                    fallback="https://media.istockphoto.com/id/1409329028/vector/no-picture-available-placeholder-thumbnail-icon-illustration-design.jpg?s=612x612&w=0&k=20&c=_zOuJu755g2eEUioiOUdz_mHKJQJn-tDgIAhQzyeKUQ="
                    style={{ objectFit: "contain" }}
                  />
                  &nbsp; &nbsp;
                  {banner.title}
                  {index === list.length - 1 ? (
                    <div ref={lastCommentRef} />
                  ) : null}
                </Select.Option>
              ))}
            </Select>
          </div>
        ))}
      </div>
      <Button type="primary" onClick={onToggle}>
        Создать баннер
      </Button>

      <CreateBannerOne
        open={isOpen}
        onClose={onToggle}
        setList={setList}
        setCount={setCount}
        banner_type="experience-mainpage"
      />
    </>
  );
};
