import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import barcaSlice from "./slices/signInSlice";
import tokenSlice from "./slices/tokenSlice";
import newsSlice from "./slices/newsSlice";
import trainerSlice from "./slices/trainerSlice";
import productsSlice from "./slices/productsSlice";
import categorySlice from "./slices/categorySlice";
import cafeSlice from "./slices/cafeSlice";
import museumSlice from "./slices/museumSlice";
import collectionSlice from "./slices/collectionSlice";
import orderSlice from "./slices/orderSlice";
import vacanciesSlice from "./slices/vacanciesSlice";
import usersSlice from "./slices/usersSlice";
import vacancyReplySlice from "./slices/vacancyReplySlice";
import socialNetworkSlice from "./slices/socialNetworkSlice";
import adminSlice from "./slices/adminSlice";
import campSlice from "./slices/campSlice";
import bannerSlice from "./slices/bannerSlice";
import videoSlice from "./slices/videoSlice";
import clinicSlice from "./slices/clinicSlice";
import championshipSlice from "./slices/championshipSlice";
import serviceItemSlice from "./slices/serviceItemSlice";
import contactSlice from "./slices/contactSlice";
import faqSlice from "./slices/faqSlice";
import infoSlice from "./slices/infoSlice";
import finReportSlice from "./slices/finReportSlice";
import projectExperienceSlice from "./slices/projectExperienceSlice";
import paramsSlice from "./slices/paramsSlice";
import expMainPageSlice from "./slices/expMainPageSlice";

const rootReducer = combineReducers({
  barca: barcaSlice,
  token: tokenSlice,
  news: newsSlice,
  trainers: trainerSlice,
  contacts: contactSlice,
  products: productsSlice,
  category: categorySlice,
  faq: faqSlice,
  info: infoSlice,
  cafe: cafeSlice,
  museum: museumSlice,
  collection: collectionSlice,
  orders: orderSlice,
  vacancies: vacanciesSlice,
  users: usersSlice,
  vacancyReplies: vacancyReplySlice,
  socialNetworks: socialNetworkSlice,
  banners: bannerSlice,
  admins: adminSlice,
  camp: campSlice,
  video: videoSlice,
  clinic: clinicSlice,
  championShip: championshipSlice,
  serviceItem: serviceItemSlice,
  finReport: finReportSlice,
  experienceProject: projectExperienceSlice,
  expMainPage: expMainPageSlice,
  params: paramsSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
