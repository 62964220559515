import Clinics from "@features/clinic";

const ClinicPage = () => {
  return (
    <div>
      <Clinics />
    </div>
  );
};

export default ClinicPage;
