import { FC, useEffect, useState } from "react";
import { Button, FormInstance, Image, Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

type UploadFileUIProps = {
  form: FormInstance;
  name: string;
  onChange?: (value?) => void;
  withFormat?: boolean;
};

export const UploadFileUI: FC<UploadFileUIProps> = (props) => {
  const { form, name, onChange, withFormat = false } = props;
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (form && name) {
      setFile(form.getFieldValue(name));
    }
  }, [form, name]);

  const handleFileChange = async (info) => {
    let files = [...info.fileList];

    if (files.length > 1) {
      files = [files[files.length - 1]];
    }

    if (files[0]?.status) {
      onChange && onChange(null);
      setFile(null);
    } else {
      if (withFormat) {
        const originFileObj = files[0].originFileObj;
        const isValid = await checkAspectRatio(originFileObj, 16, 9);

        if (!isValid) {
          message.error("Соотношение сторон должно быть 16:9");
          return;
        }
      }

      setFile(URL.createObjectURL(files[0].originFileObj));
      onChange && onChange(files[0].originFileObj);
    }
  };

  const beforeUpload = (file) => {
    const allowedTypes = ["image/png", "image/webp", "image/jpeg", "image/jpg"];
    const isAllowed = allowedTypes.includes(file.type);

    if (!isAllowed) {
      message.error("Только файлы PNG, WEBP, JPEG и JPG разрешены");
      return true;
    }

    if (file.size / 1024 / 1024 > 30) {
      message.error("Размер файла должен быть меньше 30MB");
      return true;
    }

    return false;
  };

  const handledeleteFile = () => {
    setFile(null);
    onChange && onChange(null);
  };

  const checkAspectRatio = (
    file: File,
    widthRatio: number,
    heightRatio: number
  ) => {
    return new Promise((resolve) => {
      const img = document.createElement("img");
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const isValid =
          (img.width / img.height).toFixed(2) ===
          (widthRatio / heightRatio).toFixed(2);
        resolve(isValid);
      };
    });
  };

  return (
    <>
      {file ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            width: "fit-content",
          }}
        >
          <Image src={file} style={{ objectFit: "contain", maxHeight: 400 }} />
          <Button
            size="small"
            danger
            onClick={handledeleteFile}
            style={{ width: "inherit" }}
          >
            Загрузить другой
          </Button>
        </div>
      ) : (
        <Upload.Dragger
          fileList={[]}
          onChange={handleFileChange}
          accept=".png,.webp,.jpeg,.jpg"
          beforeUpload={beforeUpload}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text" style={{ fontSize: "small" }}>
            Перетащите файл сюда или нажмите, чтобы выбрать файл
          </p>
        </Upload.Dragger>
      )}
    </>
  );
};
