import { FC, useState } from "react";
import { Button, Tabs } from "antd";
import ServiceList from "./ServiceList/ServiceList";
import CreateService from "./CreateService/CreateService";
import { ModalUI } from "../ModalUI";
import { ServiceItem, ServiceItemType } from "@store/slices/serviceItemSlice";

type ServiceProps = {
  isModalVisible?: boolean;
  handleCancel?: () => void;
  type: ServiceItemType;
  onSelectServiceItems: any;
  selectedItems?: ServiceItem[];
};

export const Service: FC<ServiceProps> = ({
  type,
  onSelectServiceItems,
  selectedItems,
}) => {
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [isOpen, setIsOpen] = useState(false);

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button onClick={onToggleOpen}>Добавить сервис</Button>
      <br />
      <br />

      <ModalUI
        title="Создать сервис"
        open={isOpen}
        onCancel={onToggleOpen}
        cancelText="Закрыть"
        width="lg"
        okHide
      >
        <Tabs
          onChange={setActiveTabKey}
          activeKey={activeTabKey}
          items={[
            {
              label: "Выбрать сервис из текущих",
              children: (
                <ServiceList
                  type={type}
                  onSelect={onSelectServiceItems}
                  items={selectedItems}
                  handleCancel={onToggleOpen}
                />
              ),
              key: "1",
            },
            {
              label: "Создать сервис",
              children: <CreateService type={type} />,
              key: "2",
            },
          ]}
        />
      </ModalUI>
    </>
  );
};
