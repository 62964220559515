import React, { useEffect, useMemo, useState } from "react";
import { Button, Popconfirm, Table } from "antd";
import {
  deleteSocialNetwork,
  fetchNetworks,
  resetAll,
  setCurrentPage,
  setParamsSocialType,
  setParamsType,
} from "@store/slices/socialNetworkSlice";
import { LIMIT } from "@constants/variables";
import { authStore } from "@store/authorization/authStore";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { FiltersType } from "./FiltersType";
import { UpdateSocialNetwork } from "./UpdateSocialNetwork";
import { ROLES_SOCIAL } from "../utils";

const CityType = {
  "jalal-abad": "Джалал-Абад",
  bishkek: "Бишкек",
  store: "Store",
};

const SocialNetworkList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { socialNetworks, loading, amount, params } = useAppSelector(
    (state) => state.socialNetworks
  );
  const { page, skip, socialType, type } = params;
  const { userRole } = authStore;

  const role = useMemo(
    () => type || ROLES_SOCIAL[userRole],
    [userRole, type]
  ) as any;

  const [currentNetwork, setCurrentNetwork] = useState(null);

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title) => <div style={{ minWidth: 150 }}>{title}</div>,
    },
    {
      title: "Ссылка",
      dataIndex: "url",
      key: "url",
      render: (url) => (
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Соц сеть",
      dataIndex: "social_network_type",
      key: "social_network_type",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      width: 150,
      render: (type) => (
        <div style={{ minWidth: 120 }}>{CityType[type] || ""}</div>
      ),
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record: any) => {
        const showModal = () => {
          setCurrentNetwork(record);
        };

        const handleDelete = () => {
          dispatch(deleteSocialNetwork(record));
        };

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "fit-content",
            }}
          >
            <Button onClick={showModal}>Редактировать</Button>
            <Popconfirm title="Удалить соц.сеть?" onConfirm={handleDelete}>
              <Button danger>Удалить</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeType = (value) => {
    dispatch(setParamsType(value));
  };

  const onChangeSocialType = (value) => {
    dispatch(setParamsSocialType(value));
  };

  const onClose = () => {
    setCurrentNetwork(null);
  };

  useEffect(() => {
    dispatch(
      fetchNetworks({
        skip,
        limit: LIMIT,
        social_network_type: socialType,
        type: role,
      })
    );
  }, [dispatch, skip, socialType, role]);

  useEffect(() => {
    dispatch(setParamsType(role));
  }, [dispatch, role]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch]);

  return (
    <>
      {userRole !== "adminStore" && (
        <FiltersType
          socialType={socialType}
          setSocialType={onChangeSocialType}
          type={type}
          setType={onChangeType}
        />
      )}

      <Table
        scroll={{ x: true }}
        dataSource={socialNetworks}
        columns={columns}
        loading={loading}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {currentNetwork && (
        <UpdateSocialNetwork data={currentNetwork} onClose={onClose} />
      )}
    </>
  );
};

export default SocialNetworkList;
