import { FC } from "react";
import { Form, InputNumber, Select } from "antd";
import { LangField, ModalUI } from "@shared/uikit";
import { Vacancy } from "@models/vacancies";
import { fieldLabels } from "./tableItems";
import styles from "./styles.module.scss";

type VacancyModalProps = {
  data: Vacancy;
  onClose: () => void;
};

export const VacancyModal: FC<VacancyModalProps> = ({ data, onClose }) => {
  return (
    <ModalUI
      open
      closable
      maskClosable
      title="Подробнее о вакансии"
      onCancel={onClose}
      footer={null}
    >
      <Form
        className={styles.formVacancy}
        layout="vertical"
        initialValues={data}
        disabled
      >
        {Object.keys(fieldLabels).map((fieldName) => (
          <Form.Item
            key={fieldName}
            name={fieldName}
            label={fieldLabels[fieldName]}
          >
            <LangField name={fieldName} isTextArea={fieldName !== "title"} />
          </Form.Item>
        ))}

        <Form.Item name="vacancy_city" label="Город">
          <Select
            options={[
              { value: "bishkek", label: "Бишкек" },
              { value: "jalal-abad", label: "Джалал-Абад" },
            ]}
          />
        </Form.Item>
        <Form.Item name="experience" label="Опыт">
          <Select
            options={[
              { value: "less-one", label: "Менее 1 года" },
              { value: "one_three", label: "1-3 года" },
              { value: "three_six", label: "3-6 лет" },
              { value: "none", label: "Нет" },
            ]}
          />
        </Form.Item>
        <Form.Item name="employment" label="Тип занятости">
          <Select
            options={[
              { value: "full-time", label: "Полный рабочий день" },
              { value: "part-time", label: "Неполный рабочий день" },
            ]}
          />
        </Form.Item>
        <Form.Item name="work_type" label="Тип работы">
          <Select
            options={[
              { value: "remote", label: "Удаленно" },
              { value: "office", label: "Офис" },
            ]}
          />
        </Form.Item>
        <Form.Item name="min_salary" label="Минимальная зарплата">
          <InputNumber type="number" min={0} />
        </Form.Item>
        <Form.Item name="max_salary" label="Максимальная зарплата">
          <InputNumber type="number" min={0} />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
