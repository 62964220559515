import { FC, useState } from "react";
import { Button, Form, Popconfirm, notification } from "antd";
import { useFilesUpload } from "@constants/api";
import { SELECT_BANNERS } from "@constants/validateFields";
import {
  arraysAreEqual,
  separateImages,
  useAppDispatch,
  useAppSelector,
  useUnloadPage,
} from "@shared/libs";
import {
  LangBanner,
  LangEditor,
  LangField,
  ModalUI,
  UploadFilesUI,
  EditableTable,
} from "@shared/uikit";
import {
  ServiceItem,
  ServiceItemType,
  updateServiceItem,
} from "@store/slices/serviceItemSlice";
import styles from "./UpdateService.module.scss";

type UpdateServiceProps = {
  onClose: () => void;
  data: ServiceItem;
  type: ServiceItemType;
};

export const UpdateService: FC<UpdateServiceProps> = ({
  data,
  onClose,
  type,
}) => {
  const [form] = Form.useForm<ServiceItem>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.serviceItem);

  const {
    filesUpload: { uploadFiles, loadingFiles, setImgFiles, setIsChangedFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [showDesc, setShowDesc] = useState(!!data.description);
  const [displayTable, setDisplayTable] = useState(false);
  const [deleteTable, setDeleteTable] = useState(false);
  const [table, setTable] = useState({
    ru: "",
    en: "",
    ky: "",
  });

  const handleTableHTML = (html, language) => {
    setTable((prevTable) => ({
      ...prevTable,
      [language]: html,
    }));
  };

  const onToggleDesc = () => {
    setShowDesc(!showDesc);
  };

  const onToggleTable = () => {
    setDisplayTable(!displayTable);
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();

      const { strImages, objImages } = separateImages(values.images || []);
      const images = await uploadFiles(objImages);

      const updatedData: ServiceItem = {
        ...values,
        description: showDesc ? values.description : null,
        id: data.id,
        table:
          type !== "championship"
            ? null
            : displayTable && !deleteTable
            ? table
            : !displayTable && !deleteTable
            ? data.table
            : null,
        service_item_type: data.service_item_type,
        images: [...strImages, ...images],
      };

      dispatch(updateServiceItem(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          onClose();
        }
        if (data.meta.requestStatus === "rejected") {
          if (!arraysAreEqual(values.images, strImages)) {
            setIsChangedFiles(true);
            setImgFiles(images);
          }
        }
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message:
          error?.outOfDate === false
            ? "Заполните все обязательные поля!"
            : "Что-то пошло не так!",
      });
    }
  };

  return (
    <ModalUI
      title="Редактирование сервиса"
      open
      onOk={onFinish}
      onCancel={onClose}
      width="lg"
      loading={loading || loadingFiles}
    >
      <Form
        name="updateService"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading || loadingFiles}
        initialValues={data}
        onValuesChange={onFormDirty}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
      >
        <Form.Item name="title" label="Название" required>
          <LangField name="title" />
        </Form.Item>

        <Button htmlType="button" type="dashed" onClick={onToggleDesc}>
          {showDesc ? "Убрать описание" : "Добавить описание"}
        </Button>
        <br />
        <br />

        {type !== "championship" && showDesc && (
          <Form.Item name="description" label="Описание" required>
            <LangField name="description" />
          </Form.Item>
        )}

        <Form.Item name="text" label="Текст" required>
          <LangEditor form={form} name="text" />
        </Form.Item>

        {type === "championship" && (
          <Form.Item name="images" label="Изображения">
            <UploadFilesUI form={form} name="images" />
          </Form.Item>
        )}

        <Form.Item name="banners" label="Баннеры" rules={[SELECT_BANNERS]}>
          <LangBanner form={form} name="banners" banner_type="service_item" />
        </Form.Item>
        <br />

        {type === "championship" && !deleteTable && (
          <>
            {!displayTable && !data?.table && (
              <Button htmlType="button" onClick={onToggleTable}>
                Добавить таблицу
              </Button>
            )}

            {displayTable && (
              <>
                <Popconfirm
                  title="Данные таблицы будут утеряны, продолжить?"
                  onConfirm={onToggleTable}
                >
                  <Button htmlType="button">Убрать таблицу</Button>
                </Popconfirm>

                <div
                  style={{
                    border: "1px solid #8080802e",
                    borderRadius: 8,
                    padding: "20px 20px 0px",
                    margin: "20px 0px",
                  }}
                >
                  <h3>Таблицы</h3>
                  <Form.Item
                    label={
                      <>
                        Таблица <strong className={styles.label}>(RU)</strong>
                      </>
                    }
                  >
                    <EditableTable
                      onSendHtml={(html) => handleTableHTML(html, "ru")}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        Таблица <strong className={styles.label}>(KY)</strong>
                      </>
                    }
                  >
                    <EditableTable
                      onSendHtml={(html) => handleTableHTML(html, "ky")}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <>
                        Таблица <strong className={styles.label}>(EN)</strong>
                      </>
                    }
                  >
                    <EditableTable
                      onSendHtml={(html) => handleTableHTML(html, "en")}
                    />
                  </Form.Item>
                </div>
              </>
            )}

            {data?.table && !displayTable && (
              <>
                <Button
                  htmlType="button"
                  onClick={onToggleTable}
                  style={{ marginBottom: 10 }}
                >
                  Заменить таблицу
                </Button>

                <Popconfirm
                  title="Вы уверены?"
                  onConfirm={() => setDeleteTable(true)}
                >
                  <Button htmlType="button" style={{ margin: "0px 10px 10px" }}>
                    Удалить таблицу полностью
                  </Button>
                </Popconfirm>

                <div className={styles.tables}>
                  <h2>Таблицы (ru - ky - en)</h2>
                  <div
                    className={styles.tableContent}
                    dangerouslySetInnerHTML={{ __html: data?.table?.ru || "" }}
                  />
                  <div
                    className={styles.tableContent}
                    dangerouslySetInnerHTML={{ __html: data?.table?.ky || "" }}
                  />
                  <div
                    className={styles.tableContent}
                    dangerouslySetInnerHTML={{ __html: data?.table?.en || "" }}
                  />
                </div>
              </>
            )}
            <br />
            <br />
          </>
        )}
      </Form>
    </ModalUI>
  );
};
