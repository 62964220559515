import React from "react";
import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { colors } from "@theme/colors";
import App from "./App";
import { store } from "./store";
import "./i18n";
import ErrorBoundary from "./app/ErrorBoundary/ErrorBoundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: colors.barca_color_primary,
          },
        }}
      >
        <BrowserRouter>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);
