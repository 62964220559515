import { useState, useEffect, Fragment } from "react";
import { Form, Button, Tag } from "antd";
import { LangVideo } from "@shared/uikit";
import { SELECT_BANNERS, SELECT_VIDEOS } from "@constants/validateFields";
import { Banner, fetchBanner } from "@store/slices/bannerSlice";
import { LIMIT } from "@constants/variables";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { LangBannerExp } from "./LangBannerExp";
import { createExpMainPage } from "@store/slices/expMainPageSlice";
import { BANNERS_POSITION } from "./const";

const CreateBanner = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.expMainPage);

  const [skip, setSkip] = useState(0);
  const [list, setList] = useState<Banner[]>([]);

  useEffect(() => {
    dispatch(
      fetchBanner({ skip, limit: LIMIT, banner_type: "experience-mainpage" })
    ).then((data) => {
      if (data.payload) {
        const { items } = data.payload as any;
        setList((prev) => (skip === 0 ? items : [...prev, ...items]));
      }
    });
  }, [dispatch, skip]);

  const onFinish = async (values) => {
    dispatch(createExpMainPage(values)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        form.resetFields();
      }
    });
  };

  return (
    <>
      <h2>Создание баннеров на главной странице</h2>
      <Form
        name="createBannersMainForm"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading}
        style={{ maxWidth: 1000 }}
      >
        {BANNERS_POSITION.map(({ id, text, field, color }) => (
          <Fragment key={id}>
            <h3 style={{ marginBottom: 5 }}>
              <Tag color={color}>{text}</Tag>
            </h3>
            <Form.Item name={field} label="Баннеры" rules={[SELECT_BANNERS]}>
              <LangBannerExp
                form={form}
                name={field}
                list={list}
                setSkip={setSkip}
                setList={setList}
              />
            </Form.Item>
            <br />
          </Fragment>
        ))}

        <Form.Item name="videos" label="Видео" rules={[SELECT_VIDEOS]}>
          <LangVideo form={form} name="videos" isRequired />
        </Form.Item>
        <br />
        <br />

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={false}
          >
            Создать
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CreateBanner;
