import { Tabs, TabsProps } from "antd";
import { CreateFinReport } from "./CreateFinReport";
import { FinReportTableList } from "./FinReportTable/FinReport";
import { useURLSearchParams } from "@shared/libs";

export const FinReport = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Таблица Фин отчетности",
      children: <FinReportTableList />,
    },
    {
      key: "2",
      label: "Создать Фин отчет",
      children: <CreateFinReport />,
    },
  ];
  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("table")}
        activeKey={activeTabsKey["table"]}
      />
    </div>
  );
};
