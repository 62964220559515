import { Tabs, TabsProps } from "antd";

const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Barça Bishkek academy",
    children: "Content of Tab Pane 1",
  },
  {
    key: "2",
    label: "Barça Jalal-Abad academy",
    children: "Content of Tab Pane 2",
  },
];

const GoodsPage = () => {
  return (
    <div>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </div>
  );
};

export default GoodsPage;
