import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LIMIT } from "@constants/variables";

interface PaginationState {
  skip: number;
  page: number;
  type?: any;
  title: string;
}

const initialState: PaginationState = {
  skip: 0,
  page: 1,
  type: null,
  title: "",
};

export const createValidate = (args: string[], page: number) => {
  const allValues = args.every(
    (value) => value === null || value === undefined
  );

  if (allValues && page === 1) {
    return true;
  }

  return false;
};

export const deleteValidate = <T>(skip: number, list: T[], page?: number) => {
  if (page) {
    return skip === 0 ? page : list.length - 1 >= 1 ? page : skip / LIMIT;
  }

  return skip === 0 ? skip : list.length - 1 >= 1 ? skip : skip - LIMIT;
};

const ParamsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setType(state, action: PayloadAction<any>) {
      state.type = action.payload;
      state.page = 1;
      state.skip = 0;
    },
    setSkip: (state, action: PayloadAction<number>) => {
      state.skip = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPagination(state, action: PayloadAction<number>) {
      state.page = action.payload;
      state.skip = (action.payload - 1) * LIMIT;
    },
    setTitleSearch(state, action) {
      state.title = action.payload;
    },
    resetPagination(state) {
      state.page = 1;
      state.skip = 0;
    },
    resetParams(state) {
      state.title = "";
      state.skip = 0;
      state.page = 1;
    },
    resetAll: (state) => {
      state.type = null;
      state.title = "";
      state.skip = 0;
      state.page = 1;
    },
  },
});

export const {
  setSkip,
  setPage,
  setType,
  setPagination,
  setTitleSearch,
  resetPagination,
  resetParams,
  resetAll,
} = ParamsSlice.actions;

export default ParamsSlice.reducer;
