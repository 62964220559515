import { Form, InputNumber, Select, notification } from "antd";
import {
  LangField,
  ModalUI,
  SelectUploadUI,
  UploadFilesUI,
} from "@shared/uikit";
import {
  arraysAreEqual,
  separateImages,
  useAppDispatch,
  useAppSelector,
  useUnloadPage,
} from "@shared/libs";
import { Product, ProductType } from "@models/products";
import { useFilesUpload } from "@constants/api";
import {
  ColorItems,
  SexItems,
  SizeItems,
  SizeShoesItems,
  TypeItems,
} from "@features/shop/consts";
import { REQUIRED, SELECTED_FILE, SELECT_REQ } from "@constants/validateFields";
import { fetchCategories } from "@store/slices/categorySlice";
import { fetchCollection } from "@store/slices/collectionSlice";
import styled from "styled-components";
import { updateProduct } from "@store/slices/productsSlice";
import { useState } from "react";

interface ProductUpdate extends Omit<Product, "categoryId" | "collectionId"> {
  categoryId: { value: number; label: string };
  collectionId: { value: number; label: string };
}

interface IProps {
  data: ProductUpdate;
  onClose: () => void;
}

const FormSize = styled(Form.Item)`
  margin-bottom: 0px;

  .ant-form-item-control-input-content {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
`;

export const UpdateProductModal = ({ data, onClose }: IProps) => {
  const [form] = Form.useForm<ProductUpdate>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.products);

  const {
    filesUpload: { uploadFiles, loadingFiles, setImgFiles, setIsChangedFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [productType, setProductType] = useState<ProductType>(
    data.product_type
  );

  const onTypeChange = (value: ProductType) => {
    setProductType(value);
  };

  const onFinish = async () => {
    try {
      const values = await form.validateFields();

      const { strImages, objImages } = separateImages(values?.images || []);
      const images = await uploadFiles(objImages);

      const updatedData: Product = {
        ...values,
        id: data.id,
        categoryId:
          typeof values.categoryId === "number"
            ? values.categoryId
            : values.categoryId.value,
        collectionId:
          typeof values.collectionId === "number"
            ? values.collectionId
            : values.collectionId.value,
        images: [...strImages, ...images],
      };

      if (updatedData.sizes) {
        Object.keys(updatedData.sizes).forEach((key) => {
          if (updatedData.sizes[key] == null) {
            updatedData.sizes[key] = 0;
          }
        });
      }

      dispatch(updateProduct(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          onClose();
        }

        if (data.meta.requestStatus === "rejected") {
          if (!arraysAreEqual(values.images, strImages)) {
            setIsChangedFiles(true);
            setImgFiles(images);
          }
        }
      });
    } catch (error) {
      notification.error({
        message:
          error?.outOfDate === false
            ? "Заполните все обязательные поля!"
            : "Что-то пошло не так!",
      });
    }
  };

  return (
    <ModalUI
      title="Редактировать продукт"
      open
      onOk={onFinish}
      onCancel={onClose}
      loading={loading || loadingFiles}
      width="sm"
    >
      <Form
        name="updateProduct"
        form={form}
        layout="vertical"
        initialValues={data}
        disabled={loading || loadingFiles}
        onValuesChange={onFormDirty}
      >
        <Form.Item name="name" label="Название" required>
          <LangField name="name" />
        </Form.Item>

        <Form.Item name="description" label="Описание" required>
          <LangField name="description" isTextArea />
        </Form.Item>

        <Form.Item name="color" label="Цвет" rules={[SELECT_REQ]}>
          <Select options={ColorItems} style={{ width: "250px" }} />
        </Form.Item>

        <Form.Item name="product_sex" label="Для кого" rules={[SELECT_REQ]}>
          <Select options={SexItems} style={{ width: "250px" }} />
        </Form.Item>

        <Form.Item name="categoryId" label="Категорий" rules={[SELECT_REQ]}>
          <SelectUploadUI request={fetchCategories} />
        </Form.Item>

        <Form.Item name="collectionId" label="Коллекция" rules={[SELECT_REQ]}>
          <SelectUploadUI request={fetchCollection} />
        </Form.Item>

        <Form.Item
          name="product_type"
          label="Тип продукта"
          rules={[SELECT_REQ]}
          style={{ width: "250px" }}
        >
          <Select options={TypeItems} onChange={onTypeChange} />
        </Form.Item>

        {productType === "clothes" && (
          <FormSize
            label="Количество размеров одежды"
            style={{ display: "flex" }}
          >
            {SizeItems.map((size) => (
              <Form.Item key={size} name={["sizes", size]} label={size}>
                <InputNumber
                  type="number"
                  defaultValue={0}
                  min={0}
                  placeholder={size}
                />
              </Form.Item>
            ))}
          </FormSize>
        )}

        {productType === "shoes" && (
          <FormSize
            label="Количество размеров обуви"
            style={{ display: "flex" }}
          >
            {SizeShoesItems.map((size) => (
              <Form.Item key={size} name={["sizes", size]} label={size}>
                <InputNumber type="number" defaultValue={0} min={0} />
              </Form.Item>
            ))}
          </FormSize>
        )}

        <Form.Item name="quantity" label="Общее кол-во" rules={[REQUIRED]}>
          <InputNumber
            type="number"
            min={0}
            disabled={productType !== "none"}
          />
        </Form.Item>

        <Form.Item name="images" label="Изображения" rules={[SELECTED_FILE]}>
          <UploadFilesUI form={form} name="images" />
        </Form.Item>

        <Form.Item
          name="old_price"
          label="Старая цена"
        >
          <InputNumber type="number" min={0} />
        </Form.Item>

        <Form.Item
          name="current_price"
          label="Текущая цена"
          rules={[{ required: true, message: "Пожалуйста, введите цену" }]}
        >
          <InputNumber type="number" min={0} />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
