import { useEffect, useState } from "react";
import { message } from "antd";
import $api from "src/app/axios/axiosInstance";

export const useFilesUpload = () => {
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [imgFile, setImgFile] = useState("");
  const [imgFile2, setImgFile2] = useState("");
  const [imgFiles, setImgFiles] = useState([]);
  const [isChangedFile, setIsChangedFile] = useState(false);
  const [isChangedFile2, setIsChangedFile2] = useState(false);
  const [isChangedFiles, setIsChangedFiles] = useState(false);

  useEffect(() => {
    if (isChangedFile && imgFile) {
      setLoadingFile(true);

      deleteFile(imgFile).finally(() => {
        setLoadingFile(false);
        setIsChangedFile(false);
        setImgFile("");
      });
    }
  }, [imgFile, isChangedFile]);

  useEffect(() => {
    if (isChangedFile2 && imgFile2) {
      setLoadingFile(true);

      deleteFile(imgFile2).finally(() => {
        setLoadingFile(false);
        setIsChangedFile2(false);
        setImgFile2("");
      });
    }
  }, [imgFile2, isChangedFile2]);

  useEffect(() => {
    if (isChangedFiles && imgFiles.length) {
      const handleDeleteFiles = async () => {
        try {
          setLoadingFiles(true);

          await Promise.all(
            imgFiles.map(
              async (file) =>
                await deleteFile(file).catch((err) => {
                  console.error("Failed to delete file:", file, err);
                })
            )
          );
        } catch (error) {
          console.error("Failed to delete files:", error);
        } finally {
          setLoadingFiles(false);
          setIsChangedFiles(false);
          setImgFiles([]);
        }
      };

      handleDeleteFiles();
    }
  }, [imgFiles, isChangedFiles]);

  const uploadFile = async (file) => {
    try {
      if (typeof file !== "string" && file !== null) {
        setLoadingFile(true);

        const formData = new FormData();
        formData.append("file", file);

        const response = await $api.post(`/upload/file`, formData);

        setLoadingFile(false);

        if (response.data.url) {
          return response.data.url;
        } else {
          message.error("Ошибка при загрузке файла.");
          return "";
        }
      } else {
        return file || "";
      }
    } catch (error) {
      setLoadingFile(false);
      console.error("Upload failed:", error);
      message.error("Ошибка при загрузке файла.");
      return file || "";
    }
  };

  const uploadFiles = async (files) => {
    try {
      // Проверяем, что files это массив и не пустой
      if (Array.isArray(files) && files.length) {
        // Если есть новые файлы для загрузки
        setLoadingFiles(true); // Устанавливаем состояние загрузки

        const formData = new FormData();
        files.forEach((file) => formData.append("files", file));

        const response = await $api.post(`/upload/files`, formData);

        setLoadingFiles(false); // Сбрасываем состояние загрузки

        // Проверяем, что запрос успешен и имеются новые URL-адреса файлов
        if (response.data.urls && response.data.urls.length > 0) {
          // Возвращаем новых URL-адресов
          return response.data.urls;
        } else {
          message.error("Ошибка при загрузке файла.");
          return [];
        }
      } else {
        // Если нет новых файлов для загрузки, просто возвращаем исходные файлы
        return files || [];
      }
    } catch (error) {
      setLoadingFiles(false); // Сбрасываем состояние загрузки в случае ошибки
      console.error("Upload failed:", error);
      message.error("Ошибка при загрузке файла.");
      return [];
    }
  };

  return {
    fileUpload: {
      uploadFile,
      loadingFile,
      setImgFile,
      setImgFile2,
      setIsChangedFile,
      setIsChangedFile2,
    },
    filesUpload: {
      uploadFiles,
      loadingFiles,
      setImgFiles,
      setImgFile2,
      setIsChangedFiles,
      setIsChangedFile2,
    },
  };
};

export const deleteFile = async (file: string) => {
  try {
    const regex = /[^/]+$/;
    // Отправлять эту часть
    const fileName = file.match(regex);

    await $api.delete(`/upload/${fileName}`);
  } catch (error) {
    console.error("Upload failed:", error);
  }
};
