import { MenuProps } from "antd";
import { Link } from "react-router-dom";
import { Routes } from "@enums/routes";

const {
  MAIN,
  EXPERIENCE_NEWS,
  SHOP,
  CATEGORIES,
  COLLECTIONS,
  BISHKEK_ACADEMY,
  JALAL_ABAD_ACADEMY,
  ORDERS,
  USERS,
  EXPERIENCE_VACANCIES,
  EXPERIENCE_MAIN,
  SOCIAL_NETWORKS,
  JAL_GROUP,
  VACANCIES_REPLY,
  INFO,
  FAQ,
  CONTACTS,
  BANNERS,
  VIDEO,
  CHAMPIONSHIP,
  CLINIC,
  EXPERIENCE_PROJECT,
} = Routes;

type MenuItem = Required<MenuProps>["items"][number];

const PROJECT = EXPERIENCE_PROJECT;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    children,
    label,
  } as MenuItem;
}

export const userRole = localStorage.getItem("user_role");

export let items: MenuItem[] = [];

if (userRole === "adminExperience") {
  items = [
    getItem(<Link to={MAIN}>Руководство</Link>, "15"),
    getItem("Barça experience", "1", [
      getItem(<Link to={EXPERIENCE_MAIN}>Главная страница</Link>, "1-4"),
      getItem(<Link to={EXPERIENCE_NEWS}>Новости</Link>, "1-1"),
      getItem(<Link to={EXPERIENCE_VACANCIES}>Вакансии</Link>, "1-3"),
      getItem(<Link to={CLINIC}>Клиника</Link>, "1-6"),
      getItem(<Link to={PROJECT}>Проект</Link>, "1-7"),
      getItem(<Link to={CHAMPIONSHIP}>Чемпионат</Link>, "1-5"),
    ]),
    getItem("Barça Academy", "2", [
      getItem(<Link to={BISHKEK_ACADEMY}>Академия Бишкека</Link>, "2-1"),
      getItem(<Link to={JALAL_ABAD_ACADEMY}>Академия Джалал-Абада</Link>, "2-2"),
    ]),
    getItem(<Link to={VIDEO}>Видео</Link>, "10"),
    getItem(<Link to={BANNERS}>Баннеры</Link>, "9"),
    getItem(<Link to={SOCIAL_NETWORKS}>Соц сети</Link>, "6"),
    getItem(<Link to={CONTACTS}>Контакты</Link>, "5"),
    getItem(<Link to={USERS}>Пользователи</Link>, "8"),
    getItem(<Link to={INFO}>FAQ</Link>, "12"),
    getItem(<Link to={FAQ}>FAQ Вопрос-ответ</Link>, "13"),
    getItem(<Link to={VACANCIES_REPLY}>Отклики на вакансию</Link>, "11"),
  ];
} else if (userRole === "adminJalgroup") {
  items = [
    getItem(<Link to={MAIN}>Руководство</Link>, "15"),
    getItem(<Link to={JAL_GROUP}>Jal Group</Link>, "5"),
    getItem(<Link to={VIDEO}>Видео</Link>, "10"),
    getItem(<Link to={BANNERS}>Баннеры</Link>, "6"),
    getItem(<Link to={VACANCIES_REPLY}>Отклики на вакансию</Link>, "4"),
  ];
} else if (userRole === "adminStore") {
  items = [
    getItem(<Link to={MAIN}>Руководство</Link>, "15"),
    getItem("Barça Shop", "4", [
      getItem(<Link to={SHOP}>Товары</Link>, "4-1"),
      getItem(<Link to={CATEGORIES}>Категории</Link>, "4-2"),
      getItem(<Link to={COLLECTIONS}>Коллекции</Link>, "4-3"),
      getItem(<Link to={ORDERS}>Заказы</Link>, "4-4"),
      getItem(<Link to={USERS}>Пользователи</Link>, "4-5"),
    ]),
    getItem(<Link to={VIDEO}>Видео</Link>, "10"),
    getItem(<Link to={BANNERS}>Баннеры</Link>, "6"),
    getItem(<Link to={SOCIAL_NETWORKS}>Соц сети</Link>, "5"),
    getItem(<Link to={CONTACTS}>Контакты</Link>, "4"),
    getItem(<Link to={INFO}>FAQ</Link>, "12"),
    getItem(<Link to={FAQ}>FAQ Вопрос-ответ</Link>, "13"),
  ];
} else if (userRole === "superAdmin") {
  items = [
    getItem(<Link to={MAIN}>Руководство</Link>, "15"),
    getItem(<Link to={JAL_GROUP}>Jal Group</Link>, "5"),
    getItem("Barça experience", "1", [
      getItem(<Link to={EXPERIENCE_MAIN}>Главная страница</Link>, "1-4"),
      getItem(<Link to={EXPERIENCE_NEWS}>Новости</Link>, "1-1"),
      getItem(<Link to={EXPERIENCE_VACANCIES}>Вакансии</Link>, "1-3"),
      getItem(<Link to={CLINIC}>Клиника</Link>, "1-6"),
      getItem(<Link to={PROJECT}>Проект</Link>, "1-7"),
      getItem(<Link to={CHAMPIONSHIP}>Чемпионат</Link>, "1-5"),
    ]),
    getItem("Barça Academy", "2", [
      getItem(<Link to={BISHKEK_ACADEMY}>Академия Бишкека</Link>, "2-1"),
      getItem(<Link to={JALAL_ABAD_ACADEMY}>Академия Джалал-Абада</Link>, "2-2"),
    ]),
    getItem("Barça Shop", "4", [
      getItem(<Link to={SHOP}>Товары</Link>, "4-1"),
      getItem(<Link to={CATEGORIES}>Категории</Link>, "4-2"),
      getItem(<Link to={COLLECTIONS}>Коллекции</Link>, "4-3"),
      getItem(<Link to={ORDERS}>Заказы</Link>, "4-4"),
    ]),
    getItem(<Link to={VIDEO}>Видео</Link>, "10"),
    getItem(<Link to={BANNERS}>Баннеры</Link>, "9"),
    getItem(<Link to={SOCIAL_NETWORKS}>Соц сети</Link>, "8"),
    getItem(<Link to={USERS}>Пользователи</Link>, "14"),
    getItem(<Link to={CONTACTS}>Контакты</Link>, "11"),
    getItem(<Link to={INFO}>FAQ</Link>, "12"),
    getItem(<Link to={FAQ}>FAQ Вопрос-ответ</Link>, "13"),
    getItem(<Link to={VACANCIES_REPLY}>Отклики на вакансию</Link>, "6"),
  ];
}

export const headerItems: MenuItem[] = [
  getItem(<Link to={USERS}>Пользователи</Link>, "7"),
];
