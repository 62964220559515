import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import { JalalAbadTrainers } from "..";
import { Cafe } from "@features/cafe";
import { Camp } from "@features/camp";
import { useURLSearchParams } from "@shared/libs";

export const JalalAbadAcademy = () => {
  const { activeTabsKey, setAndResetTabs } = useURLSearchParams();

  const onChangeTab = (key: string) => {
    setAndResetTabs("page", key);
  };

  const type = "jalal-abad";

  return (
    <div>
      <Title>Академия Джалал-Абада</Title>
      <Tabs
        items={[
          {
            label: "Тренера",
            key: "1",
            children: <JalalAbadTrainers />,
          },
          {
            label: "Кафе",
            key: "2",
            children: <Cafe type={type} />,
          },
          {
            label: "Лагерь",
            key: "3",
            children: <Camp type={type} />,
          },
        ]}
        onChange={onChangeTab}
        activeKey={activeTabsKey["page"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
