import { Tag } from "antd";
import { VacancyReplyStatus } from "@store/slices/vacancyReplySlice";
import { UserRole } from "@store/authorization/authStore";
import styles from "./styles.module.scss";

export const ROLES: Record<UserRole, string> = {
  superAdmin: null,
  adminExperience: "barca-experience",
  adminJalgroup: "jalgroup",
  adminStore: null,
};

export const fieldLabels = {
  title: "Название",
  description: "Описание",
  work_conditions: "Условия работы",
  skills: "Навыки",
};

export const typeVacancyReplies = [
  {
    label: "JalGroup",
    value: "jalgroup",
  },
  {
    label: "Barça Academy",
    value: "barca-experience",
  },
];

export const tableItems = (setLetter, setVacancy) => [
  {
    title: "Имя",
    dataIndex: "name",
    key: "name",
    render: (name) => <div className={styles.name}>{name}</div>,
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Почта",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (status: VacancyReplyStatus) => {
      const statusRu: Record<VacancyReplyStatus, any> = {
        review: { color: "cyan-inverse", text: "На рассмотрении" },
        accepted: { color: "green-inverse", text: "Одобрено" },
        rejected: { color: "red-inverse", text: "Отклонено" },
      };

      return <Tag color={statusRu[status].color}>{statusRu[status].text}</Tag>;
    },
  },
  {
    title: "Резюме",
    dataIndex: "cv",
    key: "cv",
    render: (cv) => {
      const getFileExtension = (filename) => {
        return filename.split(".").pop().toLowerCase();
      };

      const extension = getFileExtension(cv);

      const renderLinkText = () => {
        if (extension === "pdf") {
          return "Посмотреть (PDF)";
        } else if (extension === "docx") {
          return "Скачать (DOCX)";
        } else {
          return "Файл не поддерживается";
        }
      };

      return (
        <a href={cv} target="_blank" rel="noreferrer">
          {cv ? (
            <Tag color={extension === "pdf" ? "red" : "blue"}>
              {renderLinkText()}
            </Tag>
          ) : (
            "Резюме не найдено"
          )}
        </a>
      );
    },
  },
  {
    title: "Письмо",
    dataIndex: "coverLetter",
    key: "coverLetter",
    render: (letter, record) => {
      const onShowLetter = () => {
        setLetter({ name: record.name, letter });
      };

      return letter ? (
        <Tag className={styles.letter} onClick={onShowLetter}>
          Посмотреть письмо
        </Tag>
      ) : (
        "Нет"
      );
    },
  },
  {
    title: "О вакансии",
    dataIndex: "vacancy",
    key: "vacancy",
    render: (vacancy) => {
      const onShowVacancy = () => {
        setVacancy(vacancy);
      };

      return (
        <Tag className={styles.letter} onClick={onShowVacancy}>
          Подробнее
        </Tag>
      );
    },
  },
  {
    title: "Дата",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (d) => <div className={styles.date}>{d.slice(0, 10)}</div>,
  },
];
