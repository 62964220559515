import { Faq } from "@features/faq";
import React from "react";

export const FaqPage = () => {
  return (
    <div>
      <Faq />
    </div>
  );
};
