import { FC } from "react";
import { Form, Switch, notification, Alert } from "antd";
import { updateCollection } from "@store/slices/collectionSlice";
import { CollectionType } from "@models/collection";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { LangField, ModalUI, UploadFileUI } from "@shared/uikit";
import { photoDescription } from "@shared/consts/common";
import { SELECTED_FILE } from "@constants/validateFields";

interface IProps {
  data: CollectionType;
  onClose: () => void;
}

export const UpdateModalCollection: FC<IProps> = ({ data, onClose }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<CollectionType>();
  const { loading } = useAppSelector((state) => state.collection);

  const {
    fileUpload: { uploadFile, loadingFile, setImgFile, setIsChangedFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const image = await uploadFile(values.image);

      const updatedData: CollectionType = {
        ...values,
        id: data.id,
        image,
      };

      if (image) {
        dispatch(updateCollection(updatedData)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            onFormNotDirty();
            onClose();
          }

          if (data.meta.requestStatus === "rejected") {
            setIsChangedFile(image !== values.image);
            setImgFile(image);
          }
        });
      }
    } catch (error) {
      notification.error({
        message:
          error?.outOfDate === false
            ? "Заполните все обязательные поля!"
            : "Что-то пошло не так!",
      });
    }
  };

  return (
    <ModalUI
      title="Обновить коллекцию"
      open
      onCancel={onClose}
      onOk={onFinish}
      cancelText="Отмена"
      loading={loading || loadingFile}
    >
      <Form
        name="updateCollection"
        form={form}
        layout="vertical"
        initialValues={data}
        disabled={loading || loadingFile}
        onValuesChange={onFormDirty}
      >
        <Form.Item name="name" label="Название" required>
          <LangField name="name" />
        </Form.Item>

        <Form.Item name="description" label="Описание" required>
          <LangField name="description" isTextArea />
        </Form.Item>

        <Form.Item name="isMain" label="Основная">
          <Switch />
        </Form.Item>

        <Form.Item name="isRecommendation" label="Рекомендуемая">
          <Switch />
        </Form.Item>

        <Alert description={photoDescription} showIcon />
        <Form.Item name="image" label="Изображение" rules={[SELECTED_FILE]}>
          <UploadFileUI form={form} name="image" withFormat />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
