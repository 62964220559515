import { UsersComponent } from '@features/users/UsersComponent';

export const UsersPage = () => {
    return (
        <div>
            <h2>Пользователи</h2>
            <UsersComponent/>
        </div>
    );
};
