import { useEffect } from "react";
import { Input, Popconfirm, Table, Tooltip } from "antd";
import { CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  activateProducts,
  deleteProductsSoft,
  fetchProducts,
  resetPagination,
  setCurrentPage,
  setNameSearch,
  setParamsType,
} from "@store/slices/productsSlice";
import { useAppDispatch, useAppSelector, useDebounce } from "@shared/libs";
import { LIMIT } from "@constants/variables";
import { columnsData } from "../columns";

export const ArchivedProducts = () => {
  const dispatch = useAppDispatch();
  const { ProductList, loading, amount, params } = useAppSelector(
    (state) => state.products
  );
  const { page, skip, name } = params;

  const debouncedSearchQuery = useDebounce(name, () => {
    dispatch(resetPagination());
  });

  const columns = [
    ...columnsData,
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record) => {
        const handleActivate = () => {
          dispatch(activateProducts({ ids: [record.id] }));
        };

        const deleteSoft = () => {
          dispatch(deleteProductsSoft({ ids: [record.id] }));
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Popconfirm title="Активировать товар?" onConfirm={handleActivate}>
              <Tooltip title="Активировать" placement="bottom">
                <CheckCircleOutlined />
              </Tooltip>
            </Popconfirm>

            <Popconfirm title="Удалить товар?" onConfirm={deleteSoft}>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleSearchChange = (e) => {
    dispatch(setNameSearch(e.target.value));
  };

  const onPageChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    dispatch(
      fetchProducts({
        status: "archived",
        limit: LIMIT,
        skip: skip,
        name: debouncedSearchQuery,
      })
    );
  }, [page, skip, dispatch, debouncedSearchQuery]);

  useEffect(() => {
    dispatch(setParamsType({ type: "status", value: "archived" }));
  }, [dispatch]);

  return (
    <div>
      <Input
        placeholder="Поиск по названию (RU)"
        onChange={handleSearchChange}
        value={name}
      />
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={ProductList}
        scroll={{ x: true }}
        loading={loading}
        pagination={{
          current: page,
          pageSize: LIMIT,
          total: amount,
          onChange: onPageChange,
          showSizeChanger: false,
        }}
      />
    </div>
  );
};
