// ActiveNews.js
import { useEffect, useMemo, useState } from "react";
import { Input, Popconfirm, Select, Table, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import {
  archiveNews,
  deleteNewsSoft,
  fetchNews,
  resetPagination,
  setCurrentPage,
  setParamsType,
  setTitleSearch,
} from "@store/slices/newsSlice";
import { UpdateNews } from "./UpdateNews";
import { LIMIT } from "@constants/variables";
import { columnsData } from "../columns";
import { useAppDispatch, useAppSelector, useDebounce } from "@shared/libs";
import { useLocation } from "react-router-dom";

export const ActiveNews = ({ type }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { newsList, amount, loading, params } = useAppSelector(
    (state) => state.news
  );
  const { skip, page, news_type, title } = params;

  const debouncedSearchQuery = useDebounce(title, () => {
    dispatch(resetPagination());
  });

  const [editingNews, setEditingNews] = useState(null);
  const types = useMemo(
    () =>
      pathname.includes("jal-group")
        ? "jalgroup"
        : news_type === undefined || news_type === "jalgroup"
        ? null
        : news_type,
    [news_type, pathname]
  );

  const handleSearchChange = (e) => {
    dispatch(setTitleSearch(e.target.value));
  };

  const handleTypeChange = (value) => {
    dispatch(setParamsType({ type: "news_type", value }));
  };

  const columns = [
    ...columnsData,
    {
      title: "Действия",
      dataIndex: "",
      key: "",
      render: (record) => {
        const handleEdit = () => {
          setEditingNews(record);
        };

        const handleArchive = () => {
          dispatch(archiveNews({ ids: [record.id] }));
        };

        const deleteSoft = () => {
          dispatch(deleteNewsSoft({ ids: [record.id] }));
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Редактировать" placement="bottom">
              <EditOutlined onClick={handleEdit} />
            </Tooltip>

            <Popconfirm title="Архивировать новость?" onConfirm={handleArchive}>
              <Tooltip title="Архивировать" placement="bottom">
                <FileZipOutlined />
              </Tooltip>
            </Popconfirm>

            <Popconfirm title="Удалить новость?" onConfirm={deleteSoft}>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onClose = () => {
    setEditingNews(null);
  };

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    dispatch(
      fetchNews({
        skip,
        limit: LIMIT,
        status: "active",
        news_type: types,
        title: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch, skip, types]);

  useEffect(() => {
    dispatch(setParamsType({ type: "news_type", value: types }));
    dispatch(setParamsType({ type: "status", value: "active" }));
  }, [dispatch, types]);

  return (
    <div>
      {type === "bishkek" ? (
        <Select
          style={{ width: 190, marginBottom: 10 }}
          value={news_type}
          onChange={handleTypeChange}
        >
          <>
            <Select.Option>Все новости</Select.Option>
            <Select.Option value="jalal-abad">Джалал-Абад</Select.Option>
            <Select.Option value="bishkek">Бишкек</Select.Option>
            <Select.Option value="common">Общие новости</Select.Option>
          </>
        </Select>
      ) : null}
      <Input
        placeholder="Поиск по названию (RU)"
        onChange={handleSearchChange}
        value={title}
      />
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={newsList}
        rowKey="id"
        loading={loading}
        scroll={{ x: true }}
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editingNews && <UpdateNews data={editingNews} onClose={onClose} />}
    </div>
  );
};
