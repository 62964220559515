import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import BannersExperience from "@features/experienceMain/BannersExperience";
import CreateBanner from "@features/experienceMain/СreateBanner";
import { useURLSearchParams } from "@shared/libs";

const ExperienceMainPage = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <>
      <Title>Главная страница Experience</Title>
      <Tabs
        items={[
          {
            label: "Баннеры на главной стр.",
            key: "1",
            children: <BannersExperience />,
          },
          {
            label: "Создать баннеры на главной стр.",
            key: "2",
            children: <CreateBanner />,
          },
        ]}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </>
  );
};

export default ExperienceMainPage;
