import { Form, Button, Select } from "antd";
import { LangEditor, LangField, UploadFileUI } from "@shared/uikit";
import { TrainerType, createTrainer } from "@store/slices/trainerSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { SELECTED_FILE, SELECT_REQ } from "@constants/validateFields";

export const CreateTrainer = ({ type }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.trainers);

  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async (values: TrainerType) => {
    const photo = await uploadFile(values.photo);

    const updatedData: TrainerType = {
      ...values,
      photo,
      trainer_type: type,
    };

    if (photo) {
      dispatch(createTrainer(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          form.resetFields();
        }
      });
    }
  };

  return (
    <>
      <h2>Создать тренера</h2>
      <Form
        name="CreateTrainer"
        onFinish={onFinish}
        form={form}
        layout="vertical"
        disabled={loading || loadingFile}
        style={{ maxWidth: 1000 }}
        onValuesChange={onFormDirty}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
      >
        <Form.Item name="firstname" label="Имя" required>
          <LangField name="firstname" />
        </Form.Item>

        <Form.Item name="lastname" label="Фамилия" required>
          <LangField name="lastname" />
        </Form.Item>

        <Form.Item name="description" label="Описание" required>
          <LangEditor form={form} name="description" />
        </Form.Item>

        <Form.Item name="role_type" label="Роль" rules={[SELECT_REQ]}>
          <Select
            style={{ width: "220px" }}
            options={[
              { value: "trainer", label: "Тренер" },
              { value: "manager", label: "Менеджер" },
              { value: "coordinator", label: "Координатор" },
              { value: "cto", label: "Технический Директор" },
            ]}
          />
        </Form.Item>

        <Form.Item name="photo" label="Фотография" rules={[SELECTED_FILE]}>
          <UploadFileUI form={form} name="photo" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading || loadingFile}
            disabled={false}
          >
            Создать тренера
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
