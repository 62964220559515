/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { City, ServiceType } from "@models/index";
import { CafeApi, deleteFile } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { ServiceItem } from "./serviceItemSlice";
import $api from "src/app/axios/axiosInstance";

export type CafeType = {
  id?: string;
  cafe_type: City;
} & ServiceType;

export type CafeTypeRes = {
  id: string;
  cafe_type: City;
  service_items: ServiceItem[];
} & ServiceType;

interface CafeState {
  cafe?: CafeTypeRes;
  loading: boolean;
  error?: string | null;
}

interface ArgumentsType {
  type: City;
}

export const fetchCafe = createAsyncThunk(
  SliceConstants.FetchCafe,
  async ({ type }: ArgumentsType, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${CafeApi.cafe}?cafe_type=${type}`);

      return response.data.items[0];
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createCafe = createAsyncThunk(
  SliceConstants.CreateCafe,
  async (data: CafeType, { rejectWithValue }) => {
    try {
      const response = await $api.post(CafeApi.cafe, data);

      notification.success({
        message: "Кафе создано",
      });

      return response.data;
    } catch (error: any) {
      if (data.images?.length) {
        await Promise.all(
          data.images.map(
            async (img) =>
              await deleteFile(img).catch((err) => {
                console.error("Failed to delete file:", img, err);
              })
          )
        );
      }

      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateCafe = createAsyncThunk(
  SliceConstants.UpdateCafe,
  async ({ id, ...data }: CafeType, { rejectWithValue }) => {
    try {
      const response = await $api.put(`${CafeApi.cafe}/${id}`, data);

      notification.success({
        message: "Кафе обновлено",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);
export const deleteCafe = createAsyncThunk(
  SliceConstants.DeleteCafe,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await $api.delete(`${CafeApi.cafe}/delete/${id}`);

      notification.success({
        message: "Кафе удалено",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: CafeState = {
  cafe: null,
  loading: false,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const cafeSlice = createSlice({
  name: "cafe",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchCafe, (state: any, action: any) => {
      state.cafe = action.payload;
    });

    handleAsyncAction(builder, createCafe, (state: any, action: any) => {
      state.cafe = action.payload;
    });

    handleAsyncAction(builder, updateCafe, (state: any, action: any) => {
      state.cafe = action.payload;
    });

    handleAsyncAction(builder, deleteCafe, (state: any, action: any) => {
      state.cafe = null;
    });
  },
});

export default cafeSlice.reducer;
