/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useURLSearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getInitialParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  const [activeTabsKey, setActiveTabsKey] = useState(getInitialParams);

  const setActiveTabs = (key: string) => (value: string) => {
    setActiveTabsKey((prevParams) => {
      const newParams = { ...prevParams, [key]: value };
      const newSearchParams = new URLSearchParams(newParams);

      if (newSearchParams.toString() !== location.search.substring(1)) {
        navigate(
          {
            search: `?${newSearchParams.toString()}`,
          },
          { replace: true }
        );
      }

      return newParams;
    });
  };

  const resetTabs = () => {
    setActiveTabsKey({});
    navigate(
      {
        search: "",
      },
      { replace: true }
    );
  };

  const setAndResetTabs = (tab: string, value: string) => {
    const newParams = { [tab]: value };
    setActiveTabsKey(newParams);

    navigate(
      {
        search: `?${tab}=${value}`,
      },
      { replace: true }
    );
  };

  useEffect(() => {
    setActiveTabsKey(getInitialParams());
  }, [location.search]);

  return {
    activeTabsKey,
    setActiveTabs,
    setAndResetTabs,
    resetTabs,
  };
};
