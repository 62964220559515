interface UseTokenResult {
  saveToken: any;
  getToken: () => string | null;
  removeToken: () => void;
}

const tokenMain = "access_token_private";
const userRole = "user_role";
interface IResData {
  accessToken: string;
  role: string;
}
export const activateToken = (): UseTokenResult => {
  const saveToken = (token: IResData) => {
    localStorage.setItem(tokenMain, token.accessToken);
    localStorage.setItem(userRole, token.role);
  };
  const removeToken = () => localStorage.removeItem(tokenMain);
  const getToken = () => localStorage.getItem(tokenMain);

  return {
    saveToken,
    getToken,
    removeToken,
  };
};
