import { OrdersList } from "@features/orders";
import React from "react";

export const OrdersPage = () => {
  return (
    <div >
      <OrdersList />
    </div>
  );
};
