import { Form, Input, Button, notification } from "antd";
import { VideoType, updateVideo } from "@store/slices/videoSlice";
import { FC, useState } from "react";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { ModalUI, UploadFileUI, UploadVideoUI } from "@shared/uikit";
import { useFilesUpload } from "@constants/api";
import { REQUIRED, validateURL } from "@constants/validateFields";

type UpdateModalVideoProps = {
  data: VideoType;
  onClose: () => void;
};

export const UpdateVideo: FC<UpdateModalVideoProps> = ({ data, onClose }) => {
  const [form] = Form.useForm<VideoType>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.video);

  const {
    fileUpload: {
      uploadFile,
      loadingFile,
      setImgFile,
      setImgFile2,
      setIsChangedFile,
      setIsChangedFile2,
    },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const [uploadVideoMode, setUploadVideoMode] = useState(true);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const video = await uploadFile(values.video);
      const preview_path = await uploadFile(values.preview_path);
      
      const updatedData: VideoType = {
        id: data.id,
        video,
        preview_path: preview_path || null,
      };

      if (video) {
        dispatch(updateVideo(updatedData)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            onFormNotDirty();
            onClose();
          }

          if (data.meta.requestStatus === "rejected") {
            setIsChangedFile(video !== values.video);
            setImgFile(video);
            if (preview_path) {
              setIsChangedFile2(preview_path !== values.preview_path);
              setImgFile2(preview_path);
            }
          }
        });
      }
    } catch (error) {
      notification.error({
        message: "Заполните все обязательные поля!",
      });
    }
  };

  const toggleUploadVideoMode = () => {
    setUploadVideoMode(!uploadVideoMode);
  };

  const onCancel = () => {
    onFormNotDirty();
    onClose();
  };

  return (
    <ModalUI
      title="Редактировать видео"
      open
      onOk={onFinish}
      onCancel={onCancel}
      loading={loading || loadingFile}
    >
      <Form
        form={form}
        layout="vertical"
        name="createvideoForm"
        onFinish={onFinish}
        disabled={loading || loadingFile}
        initialValues={data}
        onValuesChange={onFormDirty}
      >
        <Button onClick={toggleUploadVideoMode}>
          {uploadVideoMode
            ? "Сменить на ссылку на YOUTUBE"
            : "Сменить на Файл обработчик"}
        </Button>
        <br />
        <br />
        {uploadVideoMode ? (
          <Form.Item name="video" label="Видео" rules={[REQUIRED]}>
            <UploadVideoUI form={form} name="video" />
          </Form.Item>
        ) : (
          <Form.Item
            name="video"
            label="Видео"
            rules={[
              REQUIRED,
              {
                validator: validateURL,
              },
            ]}
          >
            <Input placeholder="Введите url" />
          </Form.Item>
        )}

        <Form.Item label="Превью" name="preview_path">
          <UploadFileUI form={form} name="preview_path" />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
