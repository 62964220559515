import { useEffect, useMemo, useState } from "react";
import { Popconfirm, Select, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  deleteContact,
  fetchContacts,
  resetAll,
  setCurrentPage,
  setParamsType,
} from "@store/slices/contactSlice";
import { UpdateContact } from "@shared/uikit/UpdateContact/UpdateContact";
import { Contact } from "@models/contacts";
import { LIMIT } from "@constants/variables";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { authStore } from "@store/authorization/authStore";
import styles from "./ActiveContactBish.module.scss";
import { ROLES_CONTACT } from "@features/contacts/utils";

const { Option } = Select;

export const ActiveContact = () => {
  const dispatch = useAppDispatch();
  const { contactList, amount, loading, params } = useAppSelector(
    (state) => state.contacts
  );
  const { page, skip, type } = params;
  const { userRole } = authStore;

  const role = useMemo(
    () => type || ROLES_CONTACT[userRole],
    [userRole, type]
  ) as any;

  const [editingContact, setEditingContact] = useState<Contact>(null);

  const columns = [
    {
      title: "Название (RU)",
      dataIndex: ["title", "ru"],
      key: "title_ru",
      render: (title) => <div className={styles.title}>{title}</div>,
    },
    {
      title: "Адрес (RU)",
      dataIndex: ["adreses", "ru"],
      key: "adreses_ru",
      render: (address) => <div className={styles.address}>{address}</div>,
    },
    {
      title: "Рабочее время (RU)",
      dataIndex: "workingHours",
      key: "workingHours",
      render: (workingHours) =>
        workingHours.map((elem, index) => (
          <div key={index} className={styles.workHour}>
            {elem.ru}
          </div>
        )),
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      render: (type) => <div className={styles.title}>{type}</div>,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (phone) =>
        phone.map((elem, index) => <div key={index}>{elem.phoneNumber}</div>),
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "actions",
      render: (record) => {
        const handleEdit = () => {
          const updatedData = {
            ...record,
            siteTitle: record.site
              ? {
                  ...record.site.title,
                }
              : undefined,
          };

          setEditingContact(updatedData);
        };

        const deleteSoft = () => {
          dispatch(deleteContact(record.id));
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <EditOutlined onClick={handleEdit} />
            <Popconfirm title="Удалить контакт?" onConfirm={deleteSoft}>
              <DeleteOutlined />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeType = (value: Contact["type"]) => {
    dispatch(setParamsType(value));
  };

  const onClose = () => {
    setEditingContact(null);
  };

  useEffect(() => {
    dispatch(fetchContacts({ skip, limit: LIMIT, type: role }));
  }, [dispatch, role, skip]);

  useEffect(() => {
    dispatch(setParamsType(role));
  }, [dispatch, role]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch]);

  return (
    <div>
      {userRole === "superAdmin" && (
        <div className={styles.header}>
          <div>Фильтр</div>
          <Select
            className={styles.selectType}
            value={type}
            onChange={onChangeType}
            aria-label="Выберите тип"
          >
            <Option>Все типы</Option>
            <Option value="academy">Barça Academy</Option>
            <Option value="store">Barça Store</Option>
          </Select>
        </div>
      )}

      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={contactList}
        loading={loading}
        rowKey="id"
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editingContact && (
        <UpdateContact onClose={onClose} data={editingContact} />
      )}
    </div>
  );
};
