import SociaNetworks from "@features/socialNetworks/SociaNetworks";
import React from "react";

const SocialNetworkPage = () => {
  return (
    <div>
      <SociaNetworks />
    </div>
  );
};

export default SocialNetworkPage;
