import { makeAutoObservable } from "mobx";
import { activateToken } from "@shared/libs/useToken";
import { SignInType } from "@models/login";
import { AuthService } from "./auth.service";
import { useAction } from "@shared/libs/useAction";
import { notification } from "antd";

const { saveToken, getToken, removeToken } = activateToken();

export type UserRole =
  | "superAdmin"
  | "adminStore"
  | "adminJalgroup"
  | "adminExperience";

class AuthStore {
  isAuth = !!getToken();
  userRole: UserRole | null = localStorage.getItem("user_role") as UserRole;

  constructor() {
    makeAutoObservable(this);
  }

  setIsAuth() {
    this.isAuth = !!getToken();
  }

  signOut = async () => {
    removeToken();
    localStorage.removeItem("user_role");
    // @ts-ignore
    window.location.reload();
  };

  signIn = async (payload: SignInType) => {
    try {
      const { data } = await useAction(AuthService.signIn(payload), {
        show: true,
      });

      saveToken(data);
      this.setIsAuth();

      return data;
    } catch (err) {
      if (err.response.status) {
        switch (err.response.status) {
          case 401:
            notification.error({
              message: "Введены неверные данные",
            });
            break;
          case 403:
            notification.error({
              message: "Неверный логин или пароль",
            });
            break;
          default:
            notification.error({
              message: `Ошибка с кодом ${err.status}`,
            });
        }
      } else {
        throw new Error(err as string);
      }
    }
  };
}

export const authStore = new AuthStore();
