import { useEffect, useMemo, useState } from "react";
import { Button, Image, Input, Popconfirm, Select, Table } from "antd";
import {
  deleteinfo,
  fetchInfo,
  resetPagination,
  resetAll,
  setCurrentPage,
  setParamsType,
  setTitleSearch,
} from "@store/slices/infoSlice";
import { UpdateInfo } from "@shared/uikit/UpdateInfo";
import { useAppDispatch, useAppSelector, useDebounce } from "@shared/libs";
import { authStore } from "@store/authorization/authStore";
import { LIMIT } from "@constants/variables";
import styles from "./styles.module.scss";
import { ROLES_INFO } from "@features/info/utils";

export const ActiveInfo = () => {
  const dispatch = useAppDispatch();
  const { infoList, loading, amount, params } = useAppSelector(
    (state) => state.info
  );
  const { page, skip, title, view_type } = params;
  const { userRole } = authStore;

  const role = useMemo(
    () => view_type || ROLES_INFO[userRole],
    [userRole, view_type]
  ) as any;

  const debouncedSearchQuery = useDebounce(title, () => {
    dispatch(resetPagination());
  });

  const [editFaq, setEditFaq] = useState(null);

  const columns = [
    {
      title: "Название (RU)",
      dataIndex: ["title", "ru"],
      key: "title",
    },
    {
      title: "Название (KY)",
      dataIndex: ["title", "ky"],
      key: "title",
    },
    {
      title: "Название (EN)",
      dataIndex: ["title", "en"],
      key: "title",
    },
    {
      title: "Изображение",
      dataIndex: "images",
      key: "images",
      render: (photo) => (
        <Image
          src={photo[0]}
          width={80}
          height={80}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record) => {
        const handleEdit = () => {
          setEditFaq(record);
        };

        const deleteSoft = () => {
          dispatch(deleteinfo(record.id));
        };

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              width: "fit-content",
            }}
          >
            <Button onClick={handleEdit}>Редактировать</Button>
            <Popconfirm title="Удалить?" onConfirm={deleteSoft}>
              <Button danger>Удалить</Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const handleSearchChange = (e) => {
    dispatch(setTitleSearch(e.target.value));
  };

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const onChangeType = (value) => {
    dispatch(setParamsType(value));
  };

  const onClose = () => {
    setEditFaq(null);
  };

  useEffect(() => {
    dispatch(
      fetchInfo({
        skip,
        limit: LIMIT,
        title: debouncedSearchQuery,
        view_type: role,
      })
    );
  }, [debouncedSearchQuery, dispatch, skip, role]);

  useEffect(() => {
    dispatch(setParamsType(role));
  }, [dispatch, role]);

  useEffect(() => {
    return () => {
      dispatch(resetAll());
    };
  }, [dispatch]);

  return (
    <div>
      {userRole === "superAdmin" && (
        <div className={styles.header}>
          <div>Фильтр</div>
          <Select
            className={styles.selectType}
            value={view_type}
            onChange={onChangeType}
          >
            <Select.Option>Все типы</Select.Option>
            <Select.Option value="barca-experience">
              Barça Academy
            </Select.Option>
            <Select.Option value="store">Barça Store</Select.Option>
          </Select>
        </div>
      )}

      <Input
        placeholder="Поиск по названию (RU)"
        onChange={handleSearchChange}
        value={title}
      />
      <br />
      <br />
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={infoList}
        loading={loading}
        rowKey="id"
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editFaq && <UpdateInfo onClose={onClose} data={editFaq} />}
    </div>
  );
};
