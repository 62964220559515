import { Form, Button, Select, InputNumber } from "antd";
import { LangField } from "@shared/uikit";
import { FinReportType, createFinreport } from "@store/slices/finReportSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { REQUIRED, SELECTED_FILE } from "@constants/validateFields";
import { File } from "./File";
import { useFilesUpload } from "@constants/api";

const finReportState: FinReportType = {
  title: {
    ru: "",
    ky: "",
    en: "",
  },
  finreport_type: "pdf",
  quarter_type: "I",
  year: new Date().getFullYear(),
  url: "",
};

export const CreateFinReport = () => {
  const [form] = Form.useForm<FinReportType>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.finReport);
  
  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async (data: FinReportType) => {
    const url = await uploadFile(data.url);

    if (url) {
      dispatch(createFinreport({ ...data, url })).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          form.resetFields();
          onFormNotDirty();
        }
      });
    }
  };

  return (
    <>
      <h2>Создать фин отчетность</h2>
      <div className="">
        <Form
          name="CreateFinReport"
          onFinish={onFinish}
          form={form}
          layout="vertical"
          disabled={loading || loadingFile}
          style={{ maxWidth: 1000 }}
          initialValues={finReportState}
          onValuesChange={onFormDirty}
        >
          <Form.Item label="Название" name="title" required>
            <LangField name="title" />
          </Form.Item>
          <Form.Item name="quarter_type" label="Квартал" rules={[REQUIRED]}>
            <Select
              options={[
                {
                  label: "I",
                  value: "I",
                },
                {
                  label: "II",
                  value: "II",
                },
                {
                  label: "III",
                  value: "III",
                },
                {
                  label: "IV",
                  value: "IV",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="finreport_type"
            label="Тип документа(фин отчета)"
            rules={[REQUIRED]}
          >
            <Select
              options={[
                {
                  label: "PDF",
                  value: "pdf",
                },
              ]}
            />
          </Form.Item>

          <Form.Item name="url" label="URL" rules={[SELECTED_FILE]}>
            <File form={form} name="url" />
          </Form.Item>

          <Form.Item name="year" label="Год" rules={[REQUIRED]}>
            <InputNumber min={1945} maxLength={4} />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading || loadingFile}
              disabled={false}
            >
              Создать фин отчет
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
