/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { SliceConstants } from "@constants/slices";
import { CommonParams, Vacancy } from "@models/index";
import { UsersApi } from "@constants/api";
import $api from "src/app/axios/axiosInstance";

interface UsersState {
  usersList: Vacancy[];
  loading: boolean;
  amount: number;
  error: string | null;
}

export const fetchUsers = createAsyncThunk(
  SliceConstants.fetchUsers,
  async (params: { status: string } & CommonParams, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${UsersApi.users}`, {
        params,
      });
      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const activateUsers = createAsyncThunk(
  SliceConstants.activateUsers,
  async (id: string, { rejectWithValue }) => {
    try {
      await $api.patch(`${UsersApi.users}/active`, {
        ids: [id],
      });
      notification.success({
        message: "Пользователь активирован",
      });
      return id;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const banUsers = createAsyncThunk(
  SliceConstants.banUsers,
  async (id: string, { rejectWithValue }) => {
    try {
      await $api.patch(`${UsersApi.users}/ban`, {
        ids: [id],
      });
      notification.success({
        message: "Пользователь заблокирован",
      });
      return id;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: UsersState = {
  usersList: [],
  loading: false,
  amount: 0,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const usersSlice = createSlice({
  name: "vacancies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchUsers, (state: any, action: any) => {
      state.usersList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(builder, banUsers, (state: any, action: any) => {
      state.usersList = state.usersList.filter(
        (user) => user.id !== action.payload
      );
    });

    handleAsyncAction(builder, activateUsers, (state: any, action: any) => {
      state.usersList = state.usersList.filter(
        (user) => user.id !== action.payload
      );
    });
  },
});

export default usersSlice.reducer;
