import { Vacancies } from "@features/vacancies";

const ExperienceVacanciesPage = () => {
  const type = "barca-experience";

  return (
    <div>
      <Vacancies type={type} />
    </div>
  );
};

export default ExperienceVacanciesPage;
