import { Product, ProductColors } from "@models/products";
import styles from "./style.module.scss";
import { Carousel, Image } from "antd";
import { MultiLangUI } from "@shared/uikit";

const Colors: Record<ProductColors, string> = {
  white: "Белый",
  black: "Черный",
  purple: "Фиолетовый",
  yellow: "Желтый",
  red: "Красный",
  grey: "Серый",
};

export const columnsData = [
  {
    title: "Номер заказа",
    dataIndex: "order_number",
    key: "order_number",
    render: (num) => <div className={styles.orderNumber}>{num}</div>,
  },
  {
    title: "Общая сумма",
    dataIndex: "cost",
    key: "cost",
    render: (cost) => <div className={styles.orderNumber}>{cost}</div>,
  },
  {
    title: "Дата",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (d) => <div className={styles.orderNumber}>{d.slice(0, 10)}</div>,
  },
];

export const recipientColumns = [
  {
    title: "Имя",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Фамилия",
    dataIndex: "lastname",
    key: "lastname",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
  },
];

export const goodsColumns = [
  {
    title: "Изображения",
    dataIndex: "product",
    key: "product",
    render: (product: Product) => (
      <Carousel
        style={{ width: "120px" }}
        autoplay
        dots={false}
        arrows={product.images.length > 1}
      >
        {product.images.map((image) => (
          <div key={image}>
            <Image
              src={image}
              width={120}
              height={100}
              style={{ objectFit: "contain" }}
            />
          </div>
        ))}
      </Carousel>
    ),
  },
  {
    title: "Название",
    dataIndex: "product",
    key: "name",
    render: (product) => {
      return <MultiLangUI data={product.name} />;
    },
  },
  {
    title: "Цвет",
    dataIndex: "product",
    key: "color",
    render: (product) => {
      return Colors[product.color];
    },
  },
  {
    title: "Размер",
    dataIndex: "size",
    key: "size",
    render: (size) => {
      return <div className={styles.center}>{size}</div>;
    },
  },
  {
    title: "Количество",
    dataIndex: "quantity",
    key: "quantity",
    render: (quantity) => {
      return <div className={styles.center}>{quantity}</div>;
    },
  },
  {
    title: "Старая цена",
    dataIndex: "product",
    key: "old_price",
    render: (product) => {
      return <div className={styles.price}>{product.old_price}</div>;
    },
  },
  {
    title: "Текущая цена",
    dataIndex: "product",
    key: "current_price",
    render: (product) => {
      return <div className={styles.price}>{product.current_price}</div>;
    },
  },
];
