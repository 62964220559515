import { FC } from "react";
import { Button, Form, Input, Modal, notification } from "antd";
import { resetPasswordAdmin } from "@store/slices/adminSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { validatePassword } from "@shared/libs/validatePassword";

type UpdatePasswordProps = {
  idAdmin: string;
  onClose: () => void;
};

export const UpdatePassword: FC<UpdatePasswordProps> = ({
  idAdmin,
  onClose,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.admins);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();

      dispatch(resetPasswordAdmin({ id: idAdmin, ...values })).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onClose();
        }
      });
    } catch (error) {
      notification.error({
        message:
          error?.outOfDate === false
            ? "Заполните правильно!"
            : "Что-то пошло не так!",
      });
    }
  };

  return (
    <Modal
      open
      title="Сбросить пароль"
      maskClosable={false}
      closable={false}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={loading}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onFinish}
          loading={loading}
        >
          Сбросить
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading}
      >
        <Form.Item
          label="Новый пароль"
          name="password"
          rules={[
            { required: true, message: "Пожалуйста, введите пароль" },
            {
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};
