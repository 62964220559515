import { useEffect, useMemo, useState } from "react";
import { Modal, Button, Input, message } from "antd";
import "./Editable.scss";
import { CheckCircleFilled, DeleteFilled, EditFilled } from "@ant-design/icons";

export const EditableTable = ({ onSendHtml }: any) => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [nextId, setNextId] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newColumnName, setNewColumnName] = useState("");
  const [addHeader, setAddHeader] = useState(false);
  const [headerContent, setHeaderContent] = useState("");
  const [edit, setEdit] = useState({ index: null, text: "" });

  const showModal = () => {
    setIsModalVisible(true);
    setNewColumnName("");
    setEdit({ index: null, text: "" });
  };

  const handleOk = () => {
    if (newColumnName) {
      setColumns([...columns, newColumnName]);
      setRows(rows.map((row) => ({ ...row, [newColumnName]: "" })));
      setNewColumnName("");
    }
    setIsModalVisible(false);
  };

  const handleSave = () => {
    const newColumns = columns.map((item) =>
      item === edit.text ? newColumnName : item
    );

    setColumns(newColumns);
    setNewColumnName("");
    setEdit({ index: null, text: "" });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewColumnName("");
  };

  const addRow = () => {
    const newRow = { id: nextId };
    columns.forEach((column) => {
      newRow[column] = "";
    });
    setRows([...rows, newRow]);
    setNextId(nextId + 1);
  };

  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const deleteColumn = (columnToDelete) => {
    setColumns(columns.filter((column) => column !== columnToDelete));
    setRows(
      rows.map((row) => {
        const { [columnToDelete]: _, ...rest } = row;
        return rest;
      })
    );
    setNewColumnName("");
    setEdit({ index: null, text: "" });
  };

  const updateCell = (id, column, value) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [column]: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const clearTable = () => {
    setHeaderContent("");
    setAddHeader(false);
    setColumns([]);
    setRows([]);
    setNewColumnName("");
    setEdit({ index: null, text: "" });
  };

  const generateHTML = useMemo(() => {
    let html = ""; // Initialize html string
    if (addHeader) {
      // Add <h1> tag if addHeader is true
      html += `<h1>${headerContent}</h1>`;
    }
    html += "<table><tr>"; // Opening table tag
    columns.forEach((column) => {
      html += `<th>${column}</th>`; // Add table headers
    });
    html += "</tr>";
    rows.forEach((row) => {
      html += "<tr>";
      columns.forEach((column) => {
        html += `<td>${row[column]}</td>`; // Add table data
      });
      html += "</tr>";
    });
    html += "</table>"; // Closing table tag
    return html;
  }, [addHeader, columns, headerContent, rows]);

  useEffect(() => {
    const sendHTML = () => {
      if (typeof onSendHtml === "function") {
        onSendHtml(generateHTML);
      } else {
        message.error("Error: No function provided to send HTML");
      }
    };

    sendHTML();
  }, [generateHTML]);

  return (
    <div className="editable-table-container">
      <Button type="primary" onClick={showModal} className="add-column-btn">
        Добавить столбец
      </Button>
      <Button type="primary" onClick={() => setAddHeader(!addHeader)}>
        {addHeader ? "Убрать заголовок" : "Добавить заголовок"}
      </Button>
      <br />
      {addHeader && (
        <Input
          value={headerContent}
          onChange={(e) => setHeaderContent(e.target.value)}
          placeholder="Заголовок"
        />
      )}
      <Modal
        title="Введите название столбца"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          value={newColumnName}
          onChange={(e) => setNewColumnName(e.target.value)}
          placeholder="Название"
        />
      </Modal>
      <table className="editable-table">
        <thead>
          <tr>
            {columns.map((column, idx) => (
              <th key={column}>
                {edit.index === idx ? (
                  <input
                    type="text"
                    value={newColumnName}
                    onChange={(e) => setNewColumnName(e.target.value)}
                  />
                ) : (
                  column
                )}
                <button
                  type="button"
                  onClick={() => deleteColumn(column)}
                  className="delete-column-btn"
                >
                  <DeleteFilled />
                </button>
                {!edit.index && (
                  <button
                    type="button"
                    onClick={() => {
                      setEdit({ index: idx, text: column });
                      setNewColumnName(column);
                    }}
                    className="delete-column-btn"
                  >
                    <EditFilled />
                  </button>
                )}
                {edit.index === idx && (
                  <button
                    type="button"
                    onClick={handleSave}
                    className="delete-column-btn"
                  >
                    <CheckCircleFilled />
                  </button>
                )}
              </th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
              {columns.map((column) => (
                <td key={column}>
                  <input
                    type="text"
                    value={row[column]}
                    onChange={(e) => updateCell(row.id, column, e.target.value)}
                    className="editable-cell"
                  />
                </td>
              ))}
              <td>
                <Button type="primary" danger onClick={() => deleteRow(row.id)}>
                  Удалить
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <div className="btns-flex">
        <Button onClick={addRow} className="add-row-btn">
          Добавить поле
        </Button>
        <Button
          type="primary"
          onClick={clearTable}
          disabled={
            !columns.length && !headerContent && !rows.length && !addHeader
          }
          className="send-html-btn"
        >
          Очистить таблицу
        </Button>
      </div>
    </div>
  );
};
