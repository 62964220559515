import { FC, useEffect, useMemo, useState } from "react";
import { Button, Form, Select, notification } from "antd";
import { LangField, ModalUI } from "@shared/uikit";
import { fetchInfoOne } from "@store/slices/infoSlice";
import { updatefaq } from "@store/slices/faqSlice";
import { Faq } from "@models/faq";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { REQUIRED } from "@constants/validateFields";
import { authStore } from "@store/authorization/authStore";
import { Info } from "@models/info";
import { ROLES_INFO } from "@features/info/utils";
import { LIMIT } from "@constants/variables";

interface FaqChanged extends Omit<Faq, "infoPageId"> {
  infoPageId: {
    value: number;
    label: string;
  };
}

type UpdateFaqProps = {
  data: FaqChanged;
  onClose: () => void;
};

export const UpdateFaq: FC<UpdateFaqProps> = ({ data, onClose }) => {
  const [form] = Form.useForm<FaqChanged | Faq>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.faq);
  const { amount, loading: infoLoading } = useAppSelector(
    (state) => state.info
  );
  const { userRole } = authStore;
  const view_type = userRole === "superAdmin" ? null : ROLES_INFO[userRole];

  const [skip, setSkip] = useState(0);
  const [list, setList] = useState<Info[]>([]);

  const onChangeSkip = () => {
    setSkip((prev) => prev + LIMIT);
  };

  const options = useMemo(
    () =>
      list.map((item) => ({
        value: item.id,
        label: item.title.ru,
      })),
    [list]
  );

  useEffect(() => {
    dispatch(fetchInfoOne({ skip, limit: LIMIT, view_type })).then((data) => {
      const { items } = data.payload as any;
      setList((prev) => (skip === 0 ? items : [...prev, ...items]));
    });
  }, [dispatch, skip]);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();

      const updatedData: Faq = {
        ...values,
        id: data.id,
        infoPageId:
          typeof values.infoPageId === "object"
            ? values.infoPageId.value
            : values.infoPageId,
      };

      dispatch(updatefaq(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onClose();
        }
      });
    } catch (error) {
      notification.error({
        message: "Заполните все обязательные поля!",
      });
    }
  };

  return (
    <ModalUI
      title="Редактировать Вопрос-Ответ"
      open
      onCancel={onClose}
      onOk={onFinish}
      loading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading}
        initialValues={data}
      >
        <Form.Item name="infoPageId" label="FAQ" rules={[REQUIRED]}>
          <Select
            style={{ width: "200px" }}
            options={options}
            dropdownRender={(menu) => (
              <>
                {menu}
                {list.length < amount && (
                  <Button
                    type="text"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    loading={infoLoading}
                    onClick={onChangeSkip}
                  >
                    Ещё...
                  </Button>
                )}
              </>
            )}
          />
        </Form.Item>

        <Form.Item name="question" label="Вопрос" required>
          <LangField name="question" />
        </Form.Item>

        <Form.Item name="answer" label="Ответ" required>
          <LangField name="answer" isTextArea />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
