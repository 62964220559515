import { ChangeEvent, FC, useEffect, useState } from "react";
import { Form, Input } from "antd";
import styled from "styled-components";
import { links } from "@enums/links";
import { REQUIRED } from "@constants/validateFields";
import { CommonName } from "@models/common";

const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

type LangFieldProps = {
  value?: CommonName;
  onChange?: (data: CommonName, name: string) => void;
  name: string;
  isTextArea?: boolean;
  isRequired?: boolean;
};

export const LangField: FC<LangFieldProps> = ({
  value,
  onChange,
  name = "",
  isTextArea = false,
  isRequired = true,
}) => {
  const [fields, setFields] = useState(value);

  const handleFieldsChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
    language: string
  ) => {
    const { value: inputValue } = event.target;

    const updatedValue = {
      ...value,
      [language]: inputValue,
    };

    setFields((prev) => ({
      ...prev,
      [language]: inputValue,
    }));
    onChange && onChange(updatedValue, name);
  };

  const Field = isTextArea ? Input.TextArea : Input;

  useEffect(() => {
    if (value) {
      setFields(value);
    }
  }, [value]);

  return (
    <WrapperInputs>
      {["ru", "ky", "en"].map((lang) => (
        <StyledFormItem
          key={lang}
          name={[name, lang]}
          rules={isRequired && [REQUIRED]}
        >
          <div style={{ display: "flex", gap: "20px" }}>
            <img src={links[lang]} alt="" width={30} height={30} />
            <Field
              placeholder={lang}
              value={fields?.[lang]}
              style={isTextArea ? { minHeight: 120 } : {}}
              onChange={(event) =>
                onChange && value && handleFieldsChange(event, lang)
              }
            />
          </div>
        </StyledFormItem>
      ))}
    </WrapperInputs>
  );
};
