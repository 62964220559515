import React from "react";
import { Input } from "antd";
import styled from "styled-components";
import { links } from "@enums/links";

const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const DescriptionInput = ({
  value,
  onChange,
}: {
  value: {
    ru: string;
    en: string;
    ky: string;
  };
  onChange: any;
}) => {
  const defaultValue = value ?? { ru: "", en: "", ky: "" };

  const handleChange = (key, event) => {
    onChange({ ...defaultValue, [key]: event.target.value });
  };

  return (
    <WrapperInputs>
      {["ru", "ky", "en"].map((key) => (
        <div
          key={key}
          style={{ display: "flex", gap: "20px", alignItems: "center" }}
        >
          <img src={links[key]} alt={key} width={30} height={30} />
          <Input.TextArea
            placeholder={key.toUpperCase()}
            value={defaultValue[key]}
            onChange={(event) => handleChange(key, event)}
          />
        </div>
      ))}
    </WrapperInputs>
  );
};

export default DescriptionInput;
