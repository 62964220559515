/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { Vacancy } from "@models/index";
import { VacanciesApi } from "@constants/api";
import { errorFailCatch, getConfigToken } from "@constants/api/tokenCheck";
import { LIMIT } from "@constants/variables";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

interface VacanciesState {
  vacanciesList: Vacancy[];
  loading: boolean;
  amount: number;
  error: string | null;
  params: {
    status?: Vacancy["status"];
    experience?: Vacancy["experience"];
    employment?: Vacancy["employment"];
    work_type?: Vacancy["work_type"];
    vacancyCity?: Vacancy["vacancy_city"];
    vacancy_type: Vacancy["vacancy_type"];
    skip: number;
    page: number;
  };
}

interface ArgumentsType {
  skip?: number;
  limit?: number;
  status: "active" | "archived" | "deleted";
  type: string;
  experience?: string;
  employment?: string;
  work_type?: string;
  vacancyCity?: string;
}

export const fetchVacancies = createAsyncThunk(
  SliceConstants.fetchVacancies,
  async (
    {
      type,
      experience,
      employment,
      work_type,
      status,
      vacancyCity,
      ...params
    }: ArgumentsType,
    { rejectWithValue }
  ) => {
    try {
      let url = `${VacanciesApi.vacancy}?`;

      if (experience) {
        url += `experience=${experience}&`;
      }

      if (employment) {
        url += `employment=${employment}&`;
      }

      if (type) {
        url += `vacancy_type=${type}&`;
      }
      if (vacancyCity) {
        url += `vacancy_city=${vacancyCity}&`;
      }

      if (work_type) {
        url += `work_type=${work_type}&`;
      }
      if (status) {
        url += `status=${status}&`;
      }

      const response = await $api.get(url.slice(0, -1), {
        params,
        ...getConfigToken(),
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createVacancies = createAsyncThunk(
  SliceConstants.createVacancy,
  async (data: Vacancy, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(VacanciesApi.vacancy, data);

      const store = getState() as RootState;
      const { skip, page, vacancy_type, status, ...params } =
        store.vacancies.params;

      if (status && status === "active") {
        if (createValidate(Object.values(params), page)) {
          await dispatch(
            fetchVacancies({
              skip: 0,
              limit: LIMIT,
              status,
              type: vacancy_type,
            })
          );
        } else {
          dispatch(resetParams());
        }
      }

      notification.success({
        message: "Вакансия создана",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

// не используется
export const fetchVacancyId = createAsyncThunk(
  SliceConstants.fetchVacancyId,
  async (id, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${VacanciesApi.vacancy}/${id}`);

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateVacancies = createAsyncThunk(
  SliceConstants.updateVacancy,
  async ({ id, ...data }: Vacancy, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.put(`${VacanciesApi.vacancy}/${id}`, data);

      const store = getState() as RootState;
      const { skip, status, vacancy_type, page, ...params } =
        store.vacancies.params;
      await dispatch(
        fetchVacancies({
          skip,
          limit: LIMIT,
          type: vacancy_type,
          status,
          ...params,
        })
      );

      notification.success({
        message: "Вакансия обновлена",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const archiveVacancies = createAsyncThunk(
  SliceConstants.archiveVacancy,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${VacanciesApi.vacancy}/archive`, {
        ids,
      });

      const store = getState() as RootState;
      const { vacanciesList } = store.vacancies;
      const { skip, page, vacancy_type, status, ...params } =
        store.vacancies.params;

      const currentPage = deleteValidate(skip, vacanciesList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, vacanciesList);

        await dispatch(
          fetchVacancies({
            skip: newSkip,
            limit: LIMIT,
            type: vacancy_type,
            status,
            ...params,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Вакансия архивирована",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const activateVacancies = createAsyncThunk(
  SliceConstants.activateVacancy,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${VacanciesApi.vacancy}/active`, {
        ids,
      });

      const store = getState() as RootState;
      const { vacanciesList } = store.vacancies;
      const { skip, page, vacancy_type, status, ...params } =
        store.vacancies.params;

      const currentPage = deleteValidate(skip, vacanciesList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, vacanciesList);

        await dispatch(
          fetchVacancies({
            skip: newSkip,
            limit: LIMIT,
            type: vacancy_type,
            status,
            ...params,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Вакансия активирована",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteVacanciesSoft = createAsyncThunk(
  SliceConstants.deleteVacancySoft,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${VacanciesApi.vacancy}/delete`, {
        ids,
      });

      const store = getState() as RootState;
      const { vacanciesList } = store.vacancies;
      const { skip, page, vacancy_type, status, ...params } =
        store.vacancies.params;

      const currentPage = deleteValidate(skip, vacanciesList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, vacanciesList);

        await dispatch(
          fetchVacancies({
            skip: newSkip,
            limit: LIMIT,
            type: vacancy_type,
            status,
            ...params,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Вакансия удалена",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteVacancies = createAsyncThunk(
  SliceConstants.deleteVacancy,
  async (id: number, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${VacanciesApi.vacancy}/delete/${id}`);

      const store = getState() as RootState;
      const { vacanciesList } = store.vacancies;
      const { skip, page, vacancy_type, status, ...params } =
        store.vacancies.params;

      const currentPage = deleteValidate(skip, vacanciesList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, vacanciesList);

        await dispatch(
          fetchVacancies({
            skip: newSkip,
            limit: LIMIT,
            type: vacancy_type,
            status,
            ...params,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Вакансия полностью удалена",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: VacanciesState = {
  vacanciesList: [],
  loading: false,
  amount: 0,
  error: null,
  params: {
    status: null,
    experience: null,
    employment: null,
    work_type: null,
    vacancyCity: null,
    vacancy_type: null,
    page: 1,
    skip: 0,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const vacanciesSlice = createSlice({
  name: "vacancies",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params[action.payload.type] = action.payload.value;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetParams(state) {
      state.params.experience = null;
      state.params.employment = null;
      state.params.work_type = null;
      state.params.vacancyCity = null;
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchVacancies, (state: any, action: any) => {
      state.vacanciesList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(
      builder,
      createVacancies,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      updateVacancies,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      deleteVacancies,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      deleteVacanciesSoft,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      activateVacancies,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      archiveVacancies,
      (state: any, action: any) => {}
    );
  },
});

export const { setParamsType, setCurrentPage, resetParams } =
  vacanciesSlice.actions;
export default vacanciesSlice.reducer;
