import { Tabs, TabsProps } from "antd";
import { ActiveProducts } from "./ActiveProducts";
import { ArchivedProducts } from "./ArchivedProducts";
import { DeletedProduct } from "./DeletedProducts";
import { useURLSearchParams } from "@shared/libs";

const tabItems: TabsProps["items"] = [
  {
    key: "1",
    label: "Активные товары",
    children: <ActiveProducts />,
  },
  {
    key: "2",
    label: "Архив",
    children: <ArchivedProducts />,
  },
  {
    key: "3",
    label: "Удаленные товары",
    children: <DeletedProduct />,
  },
];

export const ProductTable = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("good")}
        activeKey={activeTabsKey["good"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
