import {
  ProductColors,
  ProductSex,
  ProductShoesSize,
  ProductSize,
  ProductType,
} from "@models/products";

export const ColorItems: { value: ProductColors; label: string }[] = [
  {
    value: "white",
    label: "Белый",
  },
  {
    value: "black",
    label: "Черный",
  },
  {
    value: "purple",
    label: "Фиолетовый",
  },
  {
    value: "yellow",
    label: "Желтый",
  },
  {
    value: "red",
    label: "Красный",
  },
  {
    value: "grey",
    label: "Серый",
  },
];

export const SexItems: { value: ProductSex; label: string }[] = [
  {
    value: "men",
    label: "Для мужчин",
  },
  {
    value: "women",
    label: "Для женщин",
  },
  {
    value: "boy",
    label: "Для мальчиков",
  },
  {
    value: "girl",
    label: "Для девочек",
  },
  {
    value: "none",
    label: "Другое",
  },
];

export const TypeItems: { value: ProductType; label: string }[] = [
  {
    value: "clothes",
    label: "Одежда",
  },
  {
    value: "shoes",
    label: "Обувь",
  },
  {
    value: "none",
    label: "Другое",
  },
];

export const SizeItems: ProductSize[] = ["XS", "S", "M", "L", "XL", "XXL"];
export const SizeShoesItems: ProductShoesSize[] = [
  "28",
  "28.5",
  "29",
  "29.5",
  "30",
  "30.5",
  "31",
  "31.5",
  "32",
  "32.5",
  "33",
  "33.5",
  "34",
  "34.5",
  "35",
  "35.5",
  "36",
  "36.5",
  "37",
  "37.5",
  "38",
  "38.5",
  "39",
  "39.5",
  "40",
  "40.5",
  "41",
  "41.5",
  "42",
  "42.5",
  "43",
  "43.5",
  "44",
  "44.5",
  "45",
  "45.5",
  "46",
];
