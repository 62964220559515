/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { ChampionshipApi } from "@constants/api";
import { CommonName, LangIds } from "@models/common";
import { VideoType } from "./videoSlice";
import { ServiceItem } from "./serviceItemSlice";
import $api from "src/app/axios/axiosInstance";

export type ChampionShipType = {
  id?: string;
  title: CommonName;
  text: CommonName;
  videos: LangIds;
  service_items_ids: string[];
};

export type ChampionShipTypeRes = {
  id: string;
  title: CommonName;
  text: CommonName;
  videos: {
    ru: VideoType;
    ky: VideoType;
    en: VideoType;
  };
  service_items_ids: string[];
  service_items: ServiceItem[];
};

interface ChampionShipState {
  championShip?: ChampionShipTypeRes;
  loading: boolean;
  error?: string | null;
}

export const fetchChampiohShip = createAsyncThunk(
  SliceConstants.fetchChampiohship,
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${ChampionshipApi.championship}`);

      return response.data.items[0];
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createChampiohShip = createAsyncThunk(
  SliceConstants.createChampionship,
  async (data: ChampionShipType, { rejectWithValue }) => {
    try {
      const response = await $api.post(ChampionshipApi.championship, data);

      notification.success({
        message: "Чемпионат создан",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateChampiohShip = createAsyncThunk(
  SliceConstants.updateChampionship,
  async ({ id, ...data }: ChampionShipType, { rejectWithValue }) => {
    try {
      const response = await $api.put(
        `${ChampionshipApi.championship}/${id}`,
        data
      );

      notification.success({
        message: "Чемпионат обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteChampiohShip = createAsyncThunk(
  SliceConstants.deleteChampionship,
  async (id: string, { rejectWithValue }) => {
    try {
      await $api.delete(`${ChampionshipApi.championship}/delete/${id}`);

      notification.success({
        message: "Чемпионат удален",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);
export const initialState: ChampionShipState = {
  championShip: null,
  loading: false,
  error: null,
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const champiohShipslice = createSlice({
  name: "champiohShip",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchChampiohShip, (state: any, action: any) => {
      state.championShip = action.payload;
    });

    handleAsyncAction(
      builder,
      createChampiohShip,
      (state: any, action: any) => {
        state.championShip = action.payload;
      }
    );

    handleAsyncAction(
      builder,
      updateChampiohShip,
      (state: any, action: any) => {
        state.championShip = action.payload;
      }
    );

    handleAsyncAction(
      builder,
      deleteChampiohShip,
      (state: any, action: any) => {
        state.championShip = null;
      }
    );
  },
});

export default champiohShipslice.reducer;
