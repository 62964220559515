import { Dispatch, FC, SetStateAction, useState } from "react";
import { VideoType, createVideoOne } from "@store/slices/videoSlice";
import { Button, Form, Input } from "antd";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { ModalUI } from "../ModalUI";
import { UploadFileUI } from "../UploadFileUI";
import { REQUIRED, validateURL } from "@constants/validateFields";
import { UploadVideoUI } from "../UploadVideoUI";

type CreateVideoOneProps = {
  open: boolean;
  onClose: () => void;
  setList: Dispatch<SetStateAction<VideoType[]>>;
  setCount: Dispatch<SetStateAction<number>>;
};

const videoState: VideoType = {
  video: "",
  preview_path: "",
};

export const CreateVideoOne: FC<CreateVideoOneProps> = ({
  open,
  onClose,
  setList,
  setCount,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.video);

  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();

  const [uploadVideoMode, setUploadVideoMode] = useState(true);

  const onFinish = async (values: VideoType) => {
    const video = await uploadFile(values.video);
    const preview_path = await uploadFile(values.preview_path);

    const updatedData: VideoType = {
      video,
      preview_path: preview_path || undefined,
    };

    if (video) {
      dispatch(createVideoOne(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          setList((prev) => [data.payload, ...prev]);
          setCount((prev) => prev + 1);
          form.resetFields();
          onClose();
        }
      });
    }
  };

  const toggleUploadVideoMode = () => {
    setUploadVideoMode(!uploadVideoMode);
    form.setFieldValue("video", undefined);
    form.validateFields(["video"]);
  };

  return (
    <ModalUI
      title="Создать видео"
      open={open}
      onOk={form.submit}
      onCancel={onClose}
      loading={loading || loadingFile}
      okText="Создать"
    >
      <Form
        form={form}
        name="createVideoForm"
        onFinish={onFinish}
        layout="vertical"
        disabled={loading || loadingFile}
        initialValues={videoState}
      >
        <Button onClick={toggleUploadVideoMode}>
          {uploadVideoMode
            ? "Сменить на ссылку на YOUTUBE"
            : "Сменить на Файл обработчик"}
        </Button>
        <br />
        <br />
        {uploadVideoMode ? (
          <Form.Item name="video" label="Видео" rules={[REQUIRED]}>
            <UploadVideoUI form={form} name="video" />
          </Form.Item>
        ) : (
          <Form.Item
            name="video"
            label="Видео"
            rules={[
              REQUIRED,
              {
                validator: validateURL,
              },
            ]}
          >
            <Input placeholder="Введите url" />
          </Form.Item>
        )}
        <Form.Item label="Превью" name="preview_path">
          <UploadFileUI form={form} name="preview_path" />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
