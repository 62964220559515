import { links } from "@enums/links";
import { Input } from "antd";
import React from "react";
import styled from "styled-components";

const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
export const NameInput = ({ value, onChange }) => {
  const handleNameChange = (event, language) => {
    const { value: inputValue } = event.target;
    const updatedValue = {
      ...value,
      [language]: inputValue,
    };
    onChange(updatedValue);
  };
  return (
    <WrapperInputs>
      <div style={{ display: "flex", gap: "20px" }}>
        <img src={links.ru} alt="" width={30} />
        &nbsp;
        <Input
          placeholder={"ru"}
          value={value?.ru}
          onChange={(event) => handleNameChange(event, "ru")}
        />
      </div>
      <div style={{ display: "flex", gap: "20px" }}>
        <img src={links.ky} alt="" width={30} />
        &nbsp;
        <Input
          placeholder={"ky"}
          value={value?.ky}
          onChange={(event) => handleNameChange(event, "ky")}
        />
      </div>
      <div style={{ display: "flex", gap: "20px" }}>
        <img src={links.en} alt="" width={30} />
        &nbsp;
        <Input
          placeholder={"en"}
          value={value?.en}
          onChange={(event) => handleNameChange(event, "en")}
        />
      </div>
    </WrapperInputs>
  );
};
