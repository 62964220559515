import { Tabs } from "antd";
import { UsersTable } from "./ActiveUsers";
import { BannedUsers } from "./BannedUsers/BannedUsers";
import { useURLSearchParams } from "@shared/libs";

const tabItems = [
  {
    key: "1",
    label: "Активные пользователи",
    children: <UsersTable />,
  },
  {
    key: "2",
    label: "Забаненные пользователи",
    children: <BannedUsers />,
  },
];

export const UsersComponent = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
