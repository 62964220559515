import { FC, useEffect, useState } from "react";
import {
  Table,
  Button,
  Checkbox,
  Tag,
  message,
  Popconfirm,
  Tooltip,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  ServiceItem,
  ServiceItemType,
  deleteServiceItem,
  fetchServiceItem,
  setCurrentPage,
} from "@store/slices/serviceItemSlice";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { UpdateService } from "./UpdateService";
import { LIMIT } from "@constants/variables";

type ServiceListProps = {
  type: ServiceItemType;
  onSelect: any;
  items?: ServiceItem[];
  handleCancel?: () => void;
};

const ServiceList: FC<ServiceListProps> = ({
  type,
  onSelect,
  items,
  handleCancel,
}) => {
  const dispatch = useAppDispatch();
  const { serviceItemList, loading, amount, params } = useAppSelector(
    (state) => state.serviceItem
  );

  const { skip, page } = params;

  const [language, setLanguage] = useState("ru");
  const [disableConfirm, setIsConfirm] = useState(true);
  const [selectedItems, setSelectedItems] = useState(items || []);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    dispatch(fetchServiceItem({ skip, limit: LIMIT, type }));
  }, [dispatch, skip, type]);

  const handleChangeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const handleSelect = (e, record) => {
    const { checked } = e.target;
    const recordIdAsString = record.id;

    if (checked) {
      setSelectedItems((prevSelected) => [
        ...prevSelected,
        { ...record, id: recordIdAsString },
      ]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((item) => item.id !== recordIdAsString)
      );
    }
    setIsConfirm(false);
  };

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleConfirm = () => {
    onSelect(selectedItems);
    handleCancel();
    setIsConfirm(true);
    selectedItems.length && message.success("Сервис выбран");
  };

  const onClose = () => {
    setSelectedService(null);
  };

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <p>{record.title[language]}</p>,
    },
    {
      title: "Выбрать",
      key: "select",
      render: (text, record) => {
        let checked = false;
        if (items && items.length) {
          checked = items.some((item) => item.id === record.id);
        }

        return (
          <Checkbox
            defaultChecked={checked}
            onChange={(e) => handleSelect(e, record)}
          />
        );
      },
    },
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record) => {
        const handleEdit = () => {
          setSelectedService(record);
        };

        const handleDeleteHard = () => {
          dispatch(deleteServiceItem(record)).then((data) => {
            if (data.meta.requestStatus === "fulfilled") {
              const deletedItem = selectedItems.filter(
                (item) => item.id !== record.id
              );
              setSelectedItems(deletedItem);
              onSelect(deletedItem);
            }
          });
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Редактировать" placement="bottom">
              <EditOutlined onClick={handleEdit} />
            </Tooltip>

            <Popconfirm
              title="Вы уверены, что хотите удалить?"
              onConfirm={handleDeleteHard}
            >
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: "16px" }}>
        <Button onClick={() => handleChangeLanguage("ru")}>RU</Button>
        <Button onClick={() => handleChangeLanguage("en")}>EN</Button>
        <Button onClick={() => handleChangeLanguage("ky")}>KY</Button>
      </div>
      <h4>
        Выбранный язык: <Tag>{language}</Tag>
      </h4>
      <div style={{ marginBottom: "16px" }}>
        <h3>Выбранные сервисы:</h3>
        {!selectedItems.length && "Не выбраны"}
        {selectedItems.map((item) => (
          <Tag key={item.id}>
            <div>
              <p>{item.title && item.title[language]}</p>
            </div>
          </Tag>
        ))}
      </div>

      <Table
        dataSource={serviceItemList}
        columns={columns}
        loading={loading}
        scroll={{ x: true }}
        rowKey="id"
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />
      <Button
        type="primary"
        onClick={handleConfirm}
        style={{ margin: "16px 0px" }}
        disabled={disableConfirm}
      >
        Подтвердить выбор
      </Button>

      {selectedService && (
        <UpdateService data={selectedService} type={type} onClose={onClose} />
      )}
    </div>
  );
};

export default ServiceList;
