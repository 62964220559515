import { FC } from "react";
import { Form, Input, Select, notification } from "antd";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { ModalUI, UploadFileUI } from "@shared/uikit";
import { Banner, updateBanner } from "@store/slices/bannerSlice";
import { SELECTED_FILE, validateURL } from "@constants/validateFields";
import { useFilesUpload } from "@constants/api";
import { typeBanners } from "./consts";

type UpdateBannerModalProps = {
  data: Banner;
  onClose: () => void;
};

export const UpdateBannerModal: FC<UpdateBannerModalProps> = ({
  data,
  onClose,
}) => {
  const [form] = Form.useForm<Banner>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.banners);

  const {
    fileUpload: { uploadFile, loadingFile, setImgFile, setIsChangedFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinishEdit = async () => {
    try {
      const values = await form.validateFields();
      const banner = await uploadFile(values.banner);

      const updatedData: Banner = {
        ...values,
        id: data.id,
        banner,
        link: values.link || undefined,
      };

      if (banner) {
        dispatch(updateBanner(updatedData)).then((data) => {
          if (data.meta.requestStatus === "fulfilled") {
            onFormNotDirty();
            onClose();
          }

          if (data.meta.requestStatus === "rejected") {
            setIsChangedFile(banner !== values.banner);
            setImgFile(banner);
          }
        });
      }
    } catch (error) {
      notification.error({
        message: "Заполните все обязательные поля!",
      });
    }
  };

  const onCancel = () => {
    onFormNotDirty();
    onClose();
  };

  return (
    <ModalUI
      title="Редактирование баннера"
      open
      onCancel={onCancel}
      onOk={onFinishEdit}
      loading={loading || loadingFile}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinishEdit}
        disabled={loading || loadingFile}
        initialValues={data}
        onValuesChange={onFormDirty}
      >
        <Form.Item label="Название" name="title">
          <Input />
        </Form.Item>

        <Form.Item label="Описание" name="description">
          <Input.TextArea style={{ height: 120 }} />
        </Form.Item>

        <Form.Item
          label="Текущее фото баннера:"
          name="banner"
          rules={[SELECTED_FILE]}
        >
          <UploadFileUI form={form} name="banner" />
        </Form.Item>

        <Form.Item
          label="Ссылка"
          name="link"
          rules={[
            {
              validator: validateURL,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Тип баннера" name="banner_type" required>
          <Select options={typeBanners} />
        </Form.Item>
      </Form>
    </ModalUI>
  );
};
