export const BANNERS_POSITION = [
  { id: 1, text: "Левый баннер", field: "leftBanner", color: "processing" },
  {
    id: 2,
    text: "Центральный баннер",
    field: "centerBanner",
    color: "success",
  },
  { id: 3, text: "Правый баннер", field: "rightBanner", color: "volcano" },
];
