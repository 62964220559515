// ActiveTrainers
import { useEffect, useState } from "react";
import { Popconfirm, Table, Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileZipOutlined,
} from "@ant-design/icons";
import {
  archiveTrainers,
  deleteTrainersSoft,
  fetchTrainers,
  resetParams,
  setCurrentPage,
  setParamsType,
} from "@store/slices/trainerSlice";
import { UpdateTrainer } from "@shared/uikit/UpdateTrainer";
import { useAppDispatch, useAppSelector } from "@shared/libs";
import { LIMIT } from "@constants/variables";
import { columnsData } from "../columns";

export const ActiveTrainersBish = ({ type }) => {
  const dispatch = useAppDispatch();
  const { trainerList, loading, amount, params } = useAppSelector(
    (state) => state.trainers
  );
  const { skip, page } = params;

  const [editingTrainer, setEditingTrainer] = useState(null);

  const columns = [
    ...columnsData,
    {
      title: "Действия",
      dataIndex: "",
      key: "action",
      render: (record) => {
        const handleEdit = () => {
          setEditingTrainer(record);
        };

        const handleArchive = () => {
          dispatch(archiveTrainers({ ids: [record.id] }));
        };

        const deleteSoft = () => {
          dispatch(deleteTrainersSoft({ ids: [record.id] }));
        };
        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Редактировать" placement="bottom">
              <EditOutlined onClick={handleEdit} />
            </Tooltip>

            <Popconfirm title="Архивировать тренера?" onConfirm={handleArchive}>
              <Tooltip title="Архивировать" placement="bottom">
                <FileZipOutlined />
              </Tooltip>
            </Popconfirm>

            <Popconfirm title="Удалить тренера?" onConfirm={deleteSoft}>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onClose = () => {
    setEditingTrainer(null);
  };

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  useEffect(() => {
    dispatch(
      fetchTrainers({
        skip,
        limit: LIMIT,
        status: "active",
        trainer_type: type,
      })
    );
  }, [dispatch, skip, type]);

  useEffect(() => {
    dispatch(setParamsType({ type: "trainer_type", value: type }));
    dispatch(setParamsType({ type: "status", value: "active" }));
  }, [dispatch, type]);

  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <div>
      <Table
        columns={columns}
        dataSource={trainerList}
        loading={loading}
        scroll={{ x: true }}
        rowKey="id"
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {editingTrainer && (
        <UpdateTrainer data={editingTrainer} onClose={onClose} />
      )}
    </div>
  );
};
