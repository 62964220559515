import { Spin } from "antd";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import {
  routeConfigPrivate,
  routeConfigPrivateExperience,
  routeConfigPrivateJalgroup,
  routeConfigPrivateStore,
  routeConfigPublic,
} from "@router/index";
import { authStore } from "@store/authorization/authStore";
import { observer } from "mobx-react-lite";

export const AppRouter = observer(() => {
  const { isAuth } = authStore;

  const userRole = localStorage.getItem("user_role");
  let routes;
  switch (userRole) {
    case "superAdmin":
      routes = isAuth ? routeConfigPrivate : routeConfigPublic;
      break;
    case "adminStore":
      routes = isAuth ? routeConfigPrivateStore : routeConfigPublic;
      break;

    case "adminJalgroup":
      routes = isAuth ? routeConfigPrivateJalgroup : routeConfigPublic;
      break;
    case "adminExperience":
      routes = isAuth ? routeConfigPrivateExperience : routeConfigPublic;
      break;
    default:
      routes = routeConfigPublic;
  }
  return (
    <div className="">
      <Suspense
        fallback={
          <Spin
            size="large"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "45vh 0",
            }}
          />
        }
      >
        <Routes>
          {Object.values(routes).map(({ element, path }) => (
            <Route path={path} key={path} element={element} />
          ))}
        </Routes>
      </Suspense>
    </div>
  );
});
