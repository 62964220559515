import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import BannersList from "./BannersList";
import CreateBanner from "./CreateBanner";
import { useURLSearchParams } from "@shared/libs";

const Banners = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Title>Баннеры</Title>
      <Tabs
        items={[
          {
            label: "Баннеры",
            key: "1",
            children: <BannersList />,
          },
          {
            label: "Создать баннер",
            key: "2",
            children: <CreateBanner />,
          },
        ]}
        onChange={setActiveTabs("tab")}
        activeKey={activeTabsKey["tab"]}
      />
    </div>
  );
};

export default Banners;
