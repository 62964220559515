import { FC, useEffect, useState } from "react";
import { Button, FormInstance, Image, Upload, message } from "antd";
import { CloseCircleOutlined, InboxOutlined } from "@ant-design/icons";

type UploadFilesUIProps = {
  form: FormInstance;
  name: string;
  onChange?: (value?) => void;
};

export const UploadFilesUI: FC<UploadFilesUIProps> = (props) => {
  const { form, name, onChange } = props;
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (form && name) {
      const initialFiles = form.getFieldValue(name);
      setFiles(initialFiles ? initialFiles : []);
    }
  }, [form, name]);

  const handleFileChange = async (info) => {
    const newFiles = info.fileList
      .filter((file) => file.status !== "removed")
      .map((file) => ({
        url: URL.createObjectURL(file.originFileObj),
        file: file.originFileObj,
      }));

    const allFiles = [...files, ...newFiles];

    setFiles(allFiles);
    onChange &&
      onChange(
        allFiles.map((fileObj) =>
          typeof fileObj === "string" ? fileObj : fileObj.file
        )
      );
  };

  const beforeUpload = (file) => {
    const allowedTypes = ["image/png", "image/webp", "image/jpeg", "image/jpg"];
    const isAllowed = allowedTypes.includes(file.type);

    if (!isAllowed) {
      message.error("Только файлы PNG, WEBP, JPEG и JPG разрешены");
      return Upload.LIST_IGNORE;
    }

    if (file.size / 1024 / 1024 > 30) {
      message.error("Размер файла должен быть меньше 30MB");
      return Upload.LIST_IGNORE;
    }

    return false;
  };

  const handleDeleteFile = (fileUrl) => {
    const newFiles = files.filter((fileObj) => {
      const fileCurrent = typeof fileObj === "string" ? fileObj : fileObj.url;

      return fileCurrent !== fileUrl;
    });
    setFiles(newFiles);
    onChange &&
      onChange(
        newFiles.map((fileObj) =>
          typeof fileObj === "string" ? fileObj : fileObj.file
        )
      );
  };

  return (
    <>
      <Upload.Dragger
        fileList={[]}
        onChange={handleFileChange}
        accept=".png,.webp,.jpeg,.jpg"
        beforeUpload={beforeUpload}
        multiple
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text" style={{ fontSize: "small" }}>
          Перетащите файлы сюда или нажмите, чтобы выбрать файлы
        </p>
      </Upload.Dragger>
      <div
        style={{ display: "flex", flexWrap: "wrap", gap: 10, marginTop: 10 }}
      >
        {files.map((file, index) => {
          const fileCurrent = typeof file === "string" ? file : file.url;

          return (
            <div key={index} style={{ position: "relative" }}>
              <Image
                src={fileCurrent}
                style={{
                  objectFit: "contain",
                  minWidth: 50,
                  maxWidth: 100,
                  height: 50,
                }}
              />
              <Button
                size="small"
                danger
                type="primary"
                icon={<CloseCircleOutlined />}
                onClick={() => handleDeleteFile(fileCurrent)}
                style={{ position: "absolute", top: 0, right: 0 }}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
