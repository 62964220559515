/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceConstants } from "@constants/slices";
import { FinReport } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { CommonName, CommonParams } from "@models/common";
import { deleteFile } from "@constants/api/filesUpload";
import { LIMIT } from "@constants/variables";
import { createValidate, deleteValidate } from "./paramsSlice";
import { RootState } from "..";
import $api from "src/app/axios/axiosInstance";

export type FinReportType = {
  id?: string;
  title: CommonName;
  finreport_type: "pdf";
  quarter_type: "I" | "II" | "III" | "IV";
  url: string;
  year: number;
};

type FinReportState = {
  finReportList: FinReportType[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    skip: number;
    page: number;
    title: string;
  };
};

export const fetchFinreport = createAsyncThunk(
  SliceConstants.fetchFinreport,
  async (params: { title?: string } & CommonParams, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${FinReport.finReport}`, {
        params: { ...params, title: params.title || null },
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createFinreport = createAsyncThunk(
  SliceConstants.createFinreport,
  async (data: FinReportType, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(FinReport.finReport, data);

      const store = getState() as RootState;
      const { page, title } = store.finReport.params;

      if (createValidate([], page) && !title) {
        await dispatch(fetchFinreport({ skip: 0, limit: LIMIT }));
      } else {
        dispatch(resetParams());
      }

      notification.success({
        message: "Фин отчет создан",
      });

      return response.data;
    } catch (error: any) {
      await deleteFile(data.url);
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateFinreport = createAsyncThunk(
  SliceConstants.updateFinreport,
  async (
    { id, ...data }: FinReportType,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await $api.put(`${FinReport.finReport}/${id}`, data);

      const store = getState() as RootState;
      const { skip, title } = store.finReport.params;
      await dispatch(fetchFinreport({ skip, limit: LIMIT, title }));

      notification.success({
        message: "Фин отчет обновлен",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteFinreport = createAsyncThunk(
  SliceConstants.deleteFinreport,
  async ({ id }: FinReportType, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${FinReport.finReport}/delete/${id}`);

      const store = getState() as RootState;
      const { finReportList, params } = store.finReport;
      const { skip, page, title } = params;

      const currentPage = deleteValidate(skip, finReportList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, finReportList);

        await dispatch(fetchFinreport({ skip: newSkip, limit: LIMIT, title }));
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Фин отчет удален",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: FinReportState = {
  finReportList: [],
  amount: 0,
  loading: false,
  error: null,
  params: {
    skip: 0,
    page: 1,
    title: "",
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const finReport = createSlice({
  name: "finReport",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    setTitleSearch(state, action) {
      state.params.title = action.payload;
    },
    resetPagination(state) {
      state.params.page = 1;
      state.params.skip = 0;
    },
    resetParams(state) {
      state.params.page = 1;
      state.params.skip = 0;
      state.params.title = "";
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchFinreport, (state: any, action: any) => {
      state.finReportList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(
      builder,
      createFinreport,
      (state: any, action: any) => {}
    );

    handleAsyncAction(
      builder,
      updateFinreport,
      (state: any, action: any) => {}
    );
    handleAsyncAction(
      builder,
      deleteFinreport,
      (state: any, action: any) => {}
    );
  },
});

export const { setCurrentPage, setTitleSearch, resetParams, resetPagination } =
  finReport.actions;
export default finReport.reducer;
