import { Form, Input, Button, Select } from "antd";
import { useFilesUpload } from "@constants/api";
import { SELECTED_FILE, validateURL } from "@constants/validateFields";
import { Banner, createBanner } from "@store/slices/bannerSlice";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { UploadFileUI } from "@shared/uikit";
import { typeBanners } from "./consts";

const bannerState: Banner = {
  banner_type: "news",
  banner: "",
};

const CreateBanner = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.banners);

  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async (values: Banner) => {
    const banner = await uploadFile(values.banner);

    const updatedData: Banner = {
      ...values,
      banner,
      link: values.link || undefined,
    };

    if (banner) {
      dispatch(createBanner(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          form.resetFields();
        }
      });
    }
  };

  return (
    <>
      <h2>Создать баннер</h2>
      <div style={{ width: "400px" }}>
        <Form
          form={form}
          name="createBannerForm"
          onFinish={onFinish}
          layout="vertical"
          disabled={loading || loadingFile}
          initialValues={bannerState}
          onValuesChange={onFormDirty}
        >
          <Form.Item label="Название" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Описание" name="description">
            <Input.TextArea style={{ height: 120 }} />
          </Form.Item>
          <Form.Item
            label="Изображение баннера"
            name="banner"
            rules={[SELECTED_FILE]}
          >
            <UploadFileUI form={form} name="banner" />
          </Form.Item>
          <Form.Item
            label="Ссылка"
            name="link"
            rules={[
              {
                validator: validateURL,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Тип баннера" name="banner_type" required>
            <Select options={typeBanners} />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading || loadingFile}
              disabled={false}
            >
              Создать
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default CreateBanner;
