import { FC } from "react";
import { Form, Radio, Select, DatePicker, Col, notification } from "antd";
import {
  LangBanner,
  LangEditor,
  LangField,
  LangVideo,
  ModalUI,
  UploadFilesUI,
} from "@shared/uikit";
import { NEWSTYPES } from "@features/news/CreateNews/consts";
import { NewsType } from "@models/news";
import {
  arraysAreEqual,
  separateImages,
  useAppDispatch,
  useAppSelector,
  useUnloadPage,
} from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { updateNews } from "@store/slices/newsSlice";
import dayjs from "dayjs";

interface IProps {
  data: NewsType;
  onClose: () => void;
}

export const UpdateNews: FC<IProps> = ({ data, onClose }) => {
  const [form] = Form.useForm<NewsType>();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.news);

  const {
    filesUpload: { uploadFiles, loadingFiles, setImgFiles, setIsChangedFiles },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const { strImages, objImages } = separateImages(values?.images || []);

      const images = await uploadFiles(objImages);

      const updatedData: NewsType = {
        ...values,
        id: data.id,
        images: [...strImages, ...images],
      };

      dispatch(updateNews(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          onClose();
        }

        if (data.meta.requestStatus === "rejected") {
          if (!arraysAreEqual(values.images, strImages)) {
            setIsChangedFiles(true);
            setImgFiles(images);
          }
        }
      });
    } catch (error) {
      notification.error({
        message:
          error?.outOfDate === false
            ? "Заполните все обязательные поля!"
            : "Что-то пошло не так!",
      });
    }
  };

  return (
    <ModalUI
      title={`Редактировать новость - ` + data?.title?.ru}
      open
      onCancel={onClose}
      onOk={onFinish}
      width="lg"
      loading={loading || loadingFiles}
    >
      <Form
        name="updateNews"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        disabled={loading || loadingFiles}
        initialValues={{
          ...data,
          newsDate: data?.newsDate ? dayjs(data.newsDate) : null,
        }}
        onValuesChange={onFormDirty}
      >
        <Form.Item name="title" label="Название" required>
          <LangField name="title" />
        </Form.Item>

        <Form.Item name="description" label="Описание" required>
          <LangField name="description" isTextArea />
        </Form.Item>

        <Form.Item name="text" label="Текст" required>
          <LangEditor form={form} name="text" />
        </Form.Item>

        <Form.Item name="news_type" label="Отображение новости" required>
          <Select style={{ width: "250px" }} options={NEWSTYPES.bishkek} />
        </Form.Item>

        <Form.Item name="newsDate" label="Дата">
          <DatePicker />
        </Form.Item>

        <Form.Item name="color" label="Цвет">
          <Radio.Group>
            <Radio value="light">Светлый</Radio>
            <Radio value="dark">Темный</Radio>
          </Radio.Group>
        </Form.Item>

        <Col>
          {/* BANNER AND VIDEO */}
          <Form.Item name="videos" label="Видео">
            <LangVideo form={form} name="videos" />
          </Form.Item>
          <Form.Item name="banners">
            <LangBanner form={form} name="banners" />
          </Form.Item>

          <Form.Item name="images" label="Изображения">
            <UploadFilesUI form={form} name="images" />
          </Form.Item>
        </Col>
      </Form>
    </ModalUI>
  );
};
