/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import { SliceConstants } from "@constants/slices";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CollectionApi, deleteFile } from "@constants/api";
import { Collection, CollectionType, CommonParams } from "@models/index";
import { notification } from "antd";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import { LIMIT } from "@constants/variables";
import $api from "src/app/axios/axiosInstance";

interface CollectionState {
  collectionList: CollectionType[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    skip: number;
    page: number;
  };
}

export const fetchCollection = createAsyncThunk(
  SliceConstants.FetchCollection,
  async (params: CommonParams, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${CollectionApi.collection}`, {
        params,
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createCollection = createAsyncThunk(
  SliceConstants.CreateCollection,
  async (data: CollectionType, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(CollectionApi.collection, data);

      const store = getState() as RootState;
      const { page } = store.category.params;

      if (createValidate([], page)) {
        await dispatch(
          fetchCollection({
            skip: 0,
            limit: LIMIT,
          })
        );
      } else {
        dispatch(resetPagination());
      }

      notification.success({
        message: "Коллекция создана",
      });

      return response.data;
    } catch (error: any) {
      await deleteFile(data.image);
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateCollection = createAsyncThunk(
  SliceConstants.UpdateCollection,
  async ({ id, ...data }: CollectionType, { rejectWithValue }) => {
    try {
      const response = await $api.put(
        `${CollectionApi.collection}/${id}`,
        data
      );

      notification.success({
        message: "Коллекция обновлена",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteCollection = createAsyncThunk(
  SliceConstants.DeleteCollection,
  async (id: string, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${CollectionApi.collection}/delete/${id}`);

      const store = getState() as RootState;
      const { categoriesList, params } = store.category;
      const { skip, page } = params;

      const currentPage = deleteValidate(skip, categoriesList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, categoriesList);

        await dispatch(
          fetchCollection({
            skip: newSkip,
            limit: LIMIT,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Коллекция удалена",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);
// archive, delete, activate

const initialState: CollectionState = {
  collectionList: [],
  loading: false,
  error: null,
  amount: 0,
  params: {
    page: 1,
    skip: 0,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any, action: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const collectionSlice = createSlice({
  name: "collection",
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    resetPagination(state) {
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchCollection, (state: any, action: any) => {
      state.collectionList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(
      builder,
      createCollection,
      (state: any, action: any) => {}
    );

    handleAsyncAction(builder, updateCollection, (state: any, action: any) => {
      const { id, ...data } = action.payload;
      const index = state.collectionList.findIndex(
        (collection: Collection) => collection.id === id
      );
      if (index !== -1) {
        state.collectionList[index] = {
          ...state.collectionList[index],
          ...data,
        };
      }
    });

    handleAsyncAction(
      builder,
      deleteCollection,
      (state: any, action: any) => {}
    );
  },
});

export const { setCurrentPage, resetPagination } = collectionSlice.actions;
export default collectionSlice.reducer;
