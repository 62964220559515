import { Image, Tag } from "antd";
import { MultiLangUI } from "@shared/uikit";

export const columnsData = [
  {
    title: "Фото",
    dataIndex: "images",
    key: "images",
    render: (images) => {
      return (
        <Image
          src={images[0]}
          width={120}
          height={100}
          style={{ objectFit: "contain" }}
        />
      );
    },
  },
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
    render: (name) => {
      return <MultiLangUI data={name} />;
    },
  },

  {
    title: "Cтатус",
    dataIndex: "product_status",
    key: "product_status",
    render: (product_status) => {
      return (
        <div>
          {product_status === "available" ? (
            <Tag color="green">Доступен</Tag>
          ) : (
            <Tag color="red">Недоступен</Tag>
          )}
        </div>
      );
    },
  },
  {
    title: "Коллекция",
    dataIndex: "collection",
    key: "collection",
    render: (collection) => {
      return <MultiLangUI data={collection.name} />;
    },
  },
  {
    title: "Категория",
    dataIndex: "category",
    key: "category",
    render: (category) => {
      return <MultiLangUI data={category.name} />;
    },
  },
  {
    title: "Для кого",
    dataIndex: "product_sex",
    key: "product_sex",
    render: (product_sex) => {
      if (product_sex === "men") {
        return <Tag>Для мужчин</Tag>;
      } else if (product_sex === "women") {
        return <Tag>Для женщин</Tag>;
      } else if (product_sex === "girl") {
        return <Tag>Для девочек</Tag>;
      } else if (product_sex === "boy") {
        return <Tag>Для мальчиков</Tag>;
      } else if (product_sex === "none") {
        return <Tag>Другое </Tag>;
      }
    },
  },
];
