import { Tabs } from "antd";
import Title from "antd/es/typography/Title";
import { BishkekTrainers } from "..";
import { Museum } from "@features/museum";
import { Cafe } from "@features/cafe";
import { useURLSearchParams } from "@shared/libs";

export const BishkekAcademy = () => {
  const { activeTabsKey, setAndResetTabs } = useURLSearchParams();

  const onChangeTab = (key: string) => {
    setAndResetTabs("page", key);
  };

  const type = "bishkek";

  return (
    <div>
      <Title>Академия Бишкека</Title>
      <Tabs
        items={[
          {
            label: "Тренера",
            key: "1",
            children: <BishkekTrainers />,
          },
          {
            label: "Музей",
            key: "2",
            children: <Museum type={type} />,
          },
          {
            label: "Кафе",
            key: "3",
            children: <Cafe type={type} />,
          },
        ]}
        onChange={onChangeTab}
        activeKey={activeTabsKey["page"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
