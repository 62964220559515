import { useEffect, useState } from "react";
import { Input, Table, Tooltip, Popconfirm, TableColumnsType } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  fetchFinreport,
  deleteFinreport,
  FinReportType,
  setCurrentPage,
  setTitleSearch,
  resetPagination,
} from "@store/slices/finReportSlice";
import { LIMIT } from "@constants/variables";
import { useAppDispatch, useAppSelector, useDebounce } from "@shared/libs";
import { UpdateFinReport } from "./UpdateFinReport";
import styles from "./style.module.scss";

export const FinReportTableList = () => {
  const dispatch = useAppDispatch();
  const { finReportList, loading, amount, params } = useAppSelector(
    (state) => state.finReport
  );
  const { page, skip, title } = params;

  const [isEdit, setIsEdit] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const debouncedSearchQuery = useDebounce(title, () => {
    dispatch(resetPagination());
  });

  const columns: TableColumnsType<FinReportType> = [
    {
      title: "Название (RU)",
      dataIndex: ["title", "ru"],
      key: "title_ru",
      render: (title) => <div className={styles.title}>{title}</div>,
    },
    {
      title: "Название (EN)",
      dataIndex: ["title", "en"],
      key: "title_en",
      render: (title) => <div className={styles.title}>{title}</div>,
    },
    {
      title: "Название (KY)",
      dataIndex: ["title", "ky"],
      key: "title_ky",
      render: (title) => <div className={styles.title}>{title}</div>,
    },
    {
      title: "Тип отчета",
      dataIndex: "finreport_type",
      key: "finreport_type",
    },
    {
      title: "Квартал",
      dataIndex: "quarter_type",
      key: "quarter_type",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          Перейти по ссылке
        </a>
      ),
    },
    {
      title: "Год",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Действия",
      key: "actions",
      render: (_, record) => {
        const handleDelete = () => {
          dispatch(deleteFinreport(record));
        };

        const showEditModal = () => {
          setCurrentReport(record);
          setIsEdit(true);
        };

        return (
          <div style={{ display: "flex", gap: 8 }}>
            <Tooltip title="Редактировать" placement="bottom">
              <EditOutlined onClick={showEditModal} />
            </Tooltip>
            <Popconfirm title="Удалить отчет?" onConfirm={handleDelete}>
              <Tooltip title="Удалить" placement="bottom">
                <DeleteOutlined />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleSearchChange = (e) => {
    dispatch(setTitleSearch(e.target.value));
  };

  const onClose = () => {
    setIsEdit(false);
  };

  useEffect(() => {
    dispatch(
      fetchFinreport({
        skip,
        limit: LIMIT,
        title: debouncedSearchQuery,
      })
    );
  }, [debouncedSearchQuery, dispatch, skip]);

  return (
    <div>
      <Input
        placeholder="Поиск по названию (RU)"
        onChange={handleSearchChange}
        value={title}
      />
      <br />
      <br />
      <Table
        scroll={{ x: true }}
        columns={columns}
        dataSource={finReportList}
        loading={loading}
        rowKey="id"
        pagination={{
          onChange: onChangePage,
          total: amount,
          current: page,
          pageSize: LIMIT,
          showSizeChanger: false,
        }}
      />

      {currentReport && (
        <UpdateFinReport
          report={currentReport}
          open={isEdit}
          onClose={onClose}
        />
      )}
    </div>
  );
};
