/* eslint-disable no-param-reassign */
import { notification } from "antd";
import { SliceConstants } from "@constants/slices";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CommonParams, NewsType } from "@models/index";
import { NewsApi } from "@constants/api";
import { errorFailCatch } from "@constants/api/tokenCheck";
import { deleteFile } from "@constants/api/filesUpload";
import { LIMIT } from "@constants/variables";
import { RootState } from "..";
import { createValidate, deleteValidate } from "./paramsSlice";
import $api from "src/app/axios/axiosInstance";

interface NewsState {
  newsList: NewsType[];
  amount: number;
  loading: boolean;
  error: string | null;
  params: {
    status?: NewsType["status"];
    news_type?: NewsType["news_type"];
    title: string;
    skip: number;
    page: number;
  };
}

interface ArgumentsType {
  status: NewsType["status"];
  news_type: NewsType["news_type"];
  title?: string;
}

export const fetchNews = createAsyncThunk(
  SliceConstants.FetchNews,
  async (params: ArgumentsType & CommonParams, { rejectWithValue }) => {
    try {
      const response = await $api.get(`${NewsApi.news}`, {
        params: { ...params, title: params.title || null },
      });

      return { amount: response.data.amount, items: response.data.items };
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const createNews = createAsyncThunk(
  SliceConstants.CreateNews,
  async (data: NewsType, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.post(NewsApi.news, data);

      const store = getState() as RootState;
      const { page, news_type, status, title } = store.news.params;

      if (status && status === "active") {
        if (createValidate([], page) && !title) {
          await dispatch(
            fetchNews({
              skip: 0,
              limit: LIMIT,
              status,
              news_type,
              title,
            })
          );
        } else {
          dispatch(resetParams());
        }
      }

      notification.success({
        message: "Новость создана",
      });

      return response.data;
    } catch (error: any) {
      if (data.images?.length) {
        await Promise.all(
          data.images.map(
            async (img) =>
              await deleteFile(img).catch((err) => {
                console.error("Failed to delete file:", img, err);
              })
          )
        );
      }

      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const updateNews = createAsyncThunk(
  SliceConstants.UpdateNews,
  async (
    { id, ...data }: NewsType,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const response = await $api.put(`${NewsApi.news}/${id}`, data);

      const store = getState() as RootState;
      const { skip, status, news_type, title } = store.news.params;
      await dispatch(
        fetchNews({
          skip,
          limit: LIMIT,
          news_type,
          status,
          title,
        })
      );

      notification.success({
        message: "Новость обновлена",
      });

      return response.data;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const archiveNews = createAsyncThunk(
  SliceConstants.ArchiveNews,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${NewsApi.news}/archive`, {
        ids,
      });

      const store = getState() as RootState;
      const { newsList } = store.news;
      const { skip, page, news_type, status, title } = store.news.params;

      const currentPage = deleteValidate(skip, newsList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, newsList);

        await dispatch(
          fetchNews({
            skip: newSkip,
            limit: LIMIT,
            news_type,
            status,
            title,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Новость архивирована",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const activateNews = createAsyncThunk(
  SliceConstants.ActivateNews,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${NewsApi.news}/active`, {
        ids,
      });

      const store = getState() as RootState;
      const { newsList } = store.news;
      const { skip, page, news_type, status, title } = store.news.params;

      const currentPage = deleteValidate(skip, newsList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, newsList);

        await dispatch(
          fetchNews({
            skip: newSkip,
            limit: LIMIT,
            news_type,
            status,
            title,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Новость активирована",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteNewsSoft = createAsyncThunk(
  SliceConstants.DeleteNewsSoft,
  async ({ ids }: any, { getState, rejectWithValue, dispatch }) => {
    try {
      const response = await $api.patch(`${NewsApi.news}/delete`, {
        ids,
      });

      const store = getState() as RootState;
      const { newsList } = store.news;
      const { skip, page, news_type, status, title } = store.news.params;

      const currentPage = deleteValidate(skip, newsList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, newsList);

        await dispatch(
          fetchNews({
            skip: newSkip,
            limit: LIMIT,
            news_type,
            status,
            title,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Новость удалена",
      });

      return response.data;
    } catch (error) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const deleteNews = createAsyncThunk(
  SliceConstants.DeleteNews,
  async (id: number, { getState, rejectWithValue, dispatch }) => {
    try {
      await $api.delete(`${NewsApi.news}/delete/${id}`);

      const store = getState() as RootState;
      const { newsList } = store.news;
      const { skip, page, news_type, status, title } = store.news.params;

      const currentPage = deleteValidate(skip, newsList, page);

      if (page === currentPage) {
        const newSkip = deleteValidate(skip, newsList);

        await dispatch(
          fetchNews({
            skip: newSkip,
            limit: LIMIT,
            news_type,
            status,
            title,
          })
        );
      } else {
        dispatch(setCurrentPage(currentPage));
      }

      notification.success({
        message: "Новость полностью удалена",
      });

      return id;
    } catch (error: any) {
      return errorFailCatch(error, rejectWithValue);
    }
  }
);

export const initialState: NewsState = {
  newsList: [],
  amount: 0,
  loading: false,
  error: null,
  params: {
    status: null,
    news_type: undefined,
    title: "",
    skip: 0,
    page: 1,
  },
};

const handleAsyncAction = (builder: any, action: any, successCallback: any) => {
  builder
    .addCase(action.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    })
    // eslint-disable-next-line @typescript-eslint/no-shadow
    .addCase(action.fulfilled, (state: any, action: any) => {
      state.loading = false;
      successCallback(state, action);
      state.error = null;
    })
    .addCase(action.rejected, (state: any) => {
      state.loading = false;
      state.error = action.payload;
    });
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setParamsType(state, action) {
      state.params[action.payload.type] = action.payload.value;
      state.params.page = 1;
      state.params.skip = 0;
    },
    setCurrentPage(state, action) {
      state.params.page = action.payload;
      state.params.skip = (action.payload - 1) * LIMIT;
    },
    setTitleSearch(state, action) {
      state.params.title = action.payload;
    },
    resetPagination(state) {
      state.params.page = 1;
      state.params.skip = 0;
    },
    resetParams(state) {
      state.params.status = null;
      state.params.news_type = undefined;
      state.params.title = "";
      state.params.page = 1;
      state.params.skip = 0;
    },
  },
  extraReducers: (builder) => {
    handleAsyncAction(builder, fetchNews, (state: any, action: any) => {
      state.newsList = action.payload.items;
      state.amount = action.payload.amount;
    });

    handleAsyncAction(builder, createNews, (state: any, action: any) => {});

    handleAsyncAction(builder, updateNews, (state: any, action: any) => {});

    handleAsyncAction(builder, activateNews, (state: any, action: any) => {});

    handleAsyncAction(builder, archiveNews, (state: any, action: any) => {});

    handleAsyncAction(builder, deleteNews, (state: any, action: any) => {});

    handleAsyncAction(builder, deleteNewsSoft, (state: any, action: any) => {});
  },
});

export const {
  setParamsType,
  setCurrentPage,
  setTitleSearch,
  resetParams,
  resetPagination,
} = newsSlice.actions;
export default newsSlice.reducer;
