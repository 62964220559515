import { CollectionType } from "@models/collection";
import { useForm } from "antd/es/form/Form";
import { Alert, Button, Form } from "antd";
import { createCollection } from "@store/slices/collectionSlice";
import { photoDescription } from "@shared/consts/common";
import { useAppDispatch, useAppSelector, useUnloadPage } from "@shared/libs";
import { useFilesUpload } from "@constants/api";
import { SELECTED_FILE } from "@constants/validateFields";
import { LangField, UploadFileUI } from "@shared/uikit";

export const CreateCollection = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.collection);

  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();
  const { onFormDirty, onFormNotDirty } = useUnloadPage();

  const onFinish = async (values: CollectionType) => {
    const image = await uploadFile(values.image);

    if (image) {
      dispatch(
        createCollection({
          ...values,
          isMain: false,
          isRecommendation: false,
          image,
        })
      ).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          onFormNotDirty();
          form.resetFields();
        }
      });
    }
  };

  return (
    <div>
      <h2>Создать коллекцию</h2>
      <Form
        form={form}
        name="createCollection"
        layout="vertical"
        onFinish={onFinish}
        style={{ maxWidth: 1000 }}
        onValuesChange={onFormDirty}
        disabled={loading || loadingFile}
        scrollToFirstError={{ behavior: "smooth", block: "center" }}
      >
        <Form.Item name="name" label="Название" required>
          <LangField name="name" />
        </Form.Item>

        <Form.Item name="description" label="Описание" required>
          <LangField name="description" isTextArea />
        </Form.Item>

        <Alert description={photoDescription} showIcon />
        <Form.Item name="image" label="Изображение" rules={[SELECTED_FILE]}>
          <UploadFileUI form={form} name="image" withFormat />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading || loadingFile}
            disabled={false}
          >
            Создать коллекцию
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
