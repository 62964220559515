import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export const ErrorPlug = () => {
  const navigate = useNavigate();
  const reloadPage = () => {
    // navigate("/");
    window.location.reload();
  };

  return (
    <div>
      <h1>Oops!..</h1>
      <p>Произошла непредвиденная ошибка</p>
      <Button onClick={reloadPage}>Обновить страницу</Button>
    </div>
  );
};
