import { Card, Tabs, TabsProps } from "antd";
import { CreateMuseum } from "@shared/uikit/Museum/CreateMuseumReusable";
import { useURLSearchParams } from "@shared/libs";
import { MuseumInfo } from "@shared/uikit/Museum/MuseumInfo";

export const Museum = ({ type }) => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Музей",
      children: <MuseumInfo type={type} />,
    },
    {
      key: "2",
      label: "Создать музей",
      children: <CreateMuseum type={type} />,
    },
  ];

  return (
    <div>
      <Card>
        <Tabs
          items={tabItems}
          onChange={setActiveTabs("museum")}
          activeKey={activeTabsKey["museum"]}
        />
      </Card>
    </div>
  );
};
