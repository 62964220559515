import { Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAddOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { items, userRole } from "./sideBarItems";
import { Routes } from "@enums/routes";
import styles from "./sider.module.scss";
import { useEffect, useState } from "react";

export const Sidebar = () => {
  const route = useNavigate();
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const flattenItems = (items) => {
    let flatItems = [];
    items.forEach((item) => {
      flatItems.push(item);
      if (item.children) {
        flatItems = flatItems.concat(flattenItems(item.children));
      }
    });
    return flatItems;
  };

  useEffect(() => {
    const path = location.pathname;
    const flatItems = flattenItems(items);

    const findParentKey = (items, path) => {
      for (const item of items) {
        if (item.children) {
          for (const child of item.children) {
            if (child.label?.props.to === path) {
              return item.key;
            }
          }
        }
      }
      return "";
    };

    const parentKey = findParentKey(items, path);
    const currentKey = flatItems.find((item) => {
      if (!item.children) {
        return item.label?.props.to === path;
      }
      return item.children.label?.props.to === path;
    })?.key;

    setOpenKeys(parentKey ? [parentKey] : []);
    setSelectedKeys(currentKey ? [currentKey] : []);
  }, [location.pathname]);

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const handleAdminsClick = () => {
    route(Routes.ADMINS);
  };

  return (
    <Sider
      style={{
        padding: 0,
        height: "100vh",
        overflow: "hidden",
      }}
      width={240}
      className={styles.sider}
    >
      <div className={styles.siderBlock}>
        <Menu
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
          theme="dark"
          mode="inline"
          items={items}
          color="black"
          style={{ color: "black" }}
        />
      </div>
      {userRole === "superAdmin" && (
        <div className={styles.admins} onClick={handleAdminsClick}>
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <UserAddOutlined />
            <div>Администраторы</div>
          </div>
        </div>
      )}
    </Sider>
  );
};
