export const SliceConstants = {
  GetCars: "barcaSlice/getList",
  SignIn: "admin/signin",
  SignUp: "barcaSlice/signup",
  GetProfile: "barcaSlice/getProfile",
  SignInGoogle: "barcaSlice/signInGoogle",
  // news
  CreateNews: "newsSlice/createNews",
  FetchNews: "newsSlice/fetchNews",
  UpdateNews: "newsSlice/updateNews",
  DeleteNews: "newsSlice/deleteNews",
  DeleteNewsSoft: "newsSlice/deleteNewsSoft",
  ArchiveNews: "newsSlice/archiveNews",
  ActivateNews: "newsSlice/activateNews",
  // contact
  CreateContact: "contactSlice/createContact",
  FetchContact: "contactSlice/fetchContact",
  UpdateContact: "contactSlice/updateContact",
  DeleteContact: "contactSlice/deleteContact",
  // info
  CreateInfo: "InfoSlice/createInfo",
  FetchInfo: "InfoSlice/fetchInfo",
  FetchInfoOne: "InfoSlice/FetchInfoOne",
  UpdateInfo: "InfoSlice/updateInfo",
  DeleteInfo: "InfoSlice/deleteInfo",
  // faq
  CreateFaq: "FaqSlice/createFaq",
  FetchFaq: "FaqSlice/fetchFaq",
  UpdateFaq: "FaqSlice/updateFaq",
  DeleteFaq: "FaqSlice/deleteFaq",
  // trainers
  FetchTrainers: "trainersSlice/fetchTrainers",
  CreateTrainer: "trainersSlice/createTrainer",
  DeleteTrainer: "trainersSlice/deleteTrainer",
  UpdateTrainer: "trainersSlice/updateTrainer",
  ArchiveTrainer: "trainersSlice/archiveTrainers",
  ActivateTrainer: "trainersSlice/activateTrainer",
  DeleteSoftTrainer: "trainersSlice/deleteSoftTrainer",
  // Products
  FetchProducts: "productsSlice/fetchProducts",
  CreateProduct: "productsSlice/createProduct",
  DeleteProduct: "productsSlice/deleteProduct",
  UpdateProduct: "productsSlice/updateProduct",
  // arhiving
  ArchiveProduct: "productsSlice/archiveProduct",
  ActivateProduct: "productsSlice/activateProduct",
  DeleteSoftProduct: "productsSlice/deleteSoftProduct",
  // Categories
  FetchCategories: "categorySlice/fetchCategory",
  CreateCategory: "categorySlice/createCategory",
  DeleteCategory: "categorySlice/deleteCategory",
  UpdateCategory: "categorySlice/updateCategory",
  // arhiving
  ArchiveCategory: "categorySlice/archiveCategory",
  ActivateCategory: "categorySlice/activateCategory",
  DeleteSoftCategory: "categorySlice/deleteSoftCategory", 

  // Cafe
  FetchCafe: " cafeSlice/fetchCafe",
  CreateCafe: "cafeSlice/createCafe",
  DeleteCafe: "cafeSlice/deleteCafe",
  UpdateCafe: "cafeSlice/updateCafe",

  // museum
  fetchMuseums: "museumSlice/fetchMuseums",
  createMuseum: "museumSlice/createMuseum",
  getOneMuseum: "museumSlice/getMuseum",
  updateMuseum: "museumSlice/updateMuseum",
  deleteMuseum: "museumSlice/deleteMuseum",

  // Collection
  FetchCollection: "collectionSlice/fetchCollection",
  CreateCollection: "collectionSlice/createCollection",
  DeleteCollection: "collectionSlice/deleteCollection",
  UpdateCollection: "collectionSlice/updateCollection",
  // orders
  FetchOrders: "ordersSlice/fetchOrders",
  //Vacancies
  fetchVacancies: "vacancySlice/fetchVacancies",
  fetchVacancyId: "vacancySlice/fetchVacancyId",
  createVacancy: "vacancySlice/createVacancy",
  deleteVacancy: "vacancySlice/deleteVacancy",
  updateVacancy: "vacancySlice/updateVacancy",
  //Vacancies
  fetchVacanciesReply: "vacanciesReplySlice/fetchVacanciesReply",
  acceptVacanciesReply: "vacanciesReplySlice/createVacancy",
  rejectVacanciesReply: "vacanciesReplySlice/deleteVacancy",
  //archive
  archiveVacancy: "vacancySlice/archiveVacancy",
  activateVacancy: "vacancySlice/activateVacancy",
  deleteVacancySoft: "vacancySlice/deleteVacancySoft",
  // users
  fetchUsers: "usersSlice/fetchUsers",
  activateUsers: "usersSlice/activateUsers",
  banUsers: "usersSlice/banUsers",
  //social networks
  fetchNetworks: "socialSlice/fetchSocial",
  createNetwork: "socialSlice/createNetwork",
  updateNetwork: "socialSlice/updateNetwork",
  deleteNetwork: "socialSlice/deleteNetwork",
  //admin
  fetchAdmins: "adminSlice/fetchAdmins",
  createAdmin: "adminSlice/createAdmin",
  resetPassword: "adminSlice/resetPassword",
  deleteAdmin: "adminSlice/deleteAdmin",
  //camp
  fetchCamp: "campSlice/fetchCamps",
  createCamp: "campSlice/createCamp",
  updateCamp: "campSlice/updateCamp",
  deleteCamp: "campSlice/deleteCamp",
  //Banner
  fetchBanner: "bannerSlice/fetchBanners",
  createBanner: "bannerSlice/createBanner",
  createBannerOne: "bannerSlice/createBannerOne",
  updateBanner: "bannerSlice/updateBanner",
  deleteBanner: "bannerSlice/deleteBanner",
  //Video
  fetchVideo: "videoSlice/fetchVideo",
  createVideo: "videoSlice/createVideo",
  createVideoOne: "videoSlice/createVideoOne",
  updateVideo: "videoSlice/updateVideo",
  deleteVideo: "videoSlice/deleteVideo",
  //Clinic
  fetchClinic: "clinicSlice/fetchClinic",
  createClinic: "clinicSlice/createClinic",
  updateClinic: "clinicSlice/updateClinic",
  deleteClinic: "clinicSlice/deleteClinic",
  //championship
  fetchChampiohship: "ChampionshipSlice/fetchChampionship",
  createChampionship: "ChampionshipSlice/createChampionship",
  updateChampionship: "ChampionshipSlice/updateChampionship",
  deleteChampionship: "ChampionshipSlice/deleteChampionship",
  //service item
  fetchServiceItem: "serviceItemSlice/fetchServiceItem",
  createServiceItem: "serviceItemSlice/createServiceItem",
  updateServiceItem: "serviceItemSlice/updateServiceItem",
  deleteServiceItem: "serviceItemSlice/deleteServiceItem",
  //Fin report
  fetchFinreport: "finreportSlice/fetchFinreport",
  createFinreport: "finreportSlice/createFinreport",
  updateFinreport: "finreportSlice/updateFinreport",
  deleteFinreport: "finreportSlice/deleteFinreport",
  //Fin report
  fetchProjectExperience: "projectExperienceSlice/fetchProjectExperience",
  createProjectExperience: "projectExperienceSlice/createProjectExperience",
  updateProjectExperience: "projectExperienceSlice/updateProjectExperience",
  deleteProjectExperience: "projectExperienceSlice/deleteProjectExperience",
  //expMainPage
  fetchExpMainPage: "expMainPageSlice/fetchExpMainPage",
  createExpMainPage: "expMainPageSlice/createExpMainPage",
  updateExpMainPage: "expMainPageSlice/updateExpMainPage",
  deleteExpMainPage: "expMainPageSlice/deleteExpMainPage",
};
