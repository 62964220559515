import { useState, useEffect } from "react";

export const useDebounce = (
  value: string,
  callback: () => void = null,
  delay = 500
) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value.trim());
      callback && callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
