import { Tabs } from "antd";
import { ActiveVacancies } from "./ActiveVacancies";
import { ArchivedVacancies } from "./ArchivedVacancies";
import { DeletedVacancies } from "./DeletedVacancies";
import { useURLSearchParams } from "@shared/libs";

export const VacancyTable = ({ type }) => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  return (
    <div>
      <Tabs
        items={[
          {
            key: "1",
            label: "Активные вакансии",
            children: <ActiveVacancies type={type} />,
          },
          {
            key: "2",
            label: "Архивированные вакансии",
            children: <ArchivedVacancies type={type} />,
          },
          {
            key: "3",
            label: "Удаленные вакансии",
            children: <DeletedVacancies type={type} />,
          },
        ]}
        onChange={setActiveTabs("vacancies")}
        activeKey={activeTabsKey["vacancies"]}
        destroyInactiveTabPane
      />
    </div>
  );
};
