import { useAppDispatch, useAppSelector } from "@shared/libs";
import { validatePassword } from "@shared/libs/validatePassword";
import { AdminType, createAdmin } from "@store/slices/adminSlice";
import { Button, Form, Input, Select } from "antd";

const { Option } = Select;

const CreateAdmin = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.admins);

  const onFinish = (values: AdminType) => {
    dispatch(createAdmin(values)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        form.resetFields();
      }
    });
  };

  return (
    <Form
      name="createAdmin"
      onFinish={onFinish}
      form={form}
      style={{ maxWidth: 1000 }}
      layout="vertical"
      disabled={loading}
    >
      <Form.Item
        name="username"
        label="Имя"
        rules={[{ required: true, message: "Пожалуйста, введите Имя" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Пароль"
        rules={[
          { required: true, message: "Пожалуйста, введите пароль" },
          {
            validator: validatePassword,
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="role"
        label="Роль"
        rules={[{ required: true, message: "Пожалуйста, выберите роль" }]}
      >
        <Select style={{ width: "300px" }}>
          <Option value="adminExperience">
            Администратор Barça Experience
          </Option>
          <Option value="adminStore">Администратор Barça Store</Option>
          <Option value="adminJalgroup">Администратор Jal Group</Option>
        </Select>
      </Form.Item>
      <br />

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={false}
        >
          Создать
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateAdmin;
