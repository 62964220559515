import { Tabs, TabsProps } from "antd";
import { TrainersTableBish } from "./TrainersTable";
import { CreateTrainer } from "./CreateTrainerBish";
import { useURLSearchParams } from "@shared/libs";

export const TrainersBishkek = () => {
  const { activeTabsKey, setActiveTabs } = useURLSearchParams();

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Таблица тренеров",
      children: <TrainersTableBish />,
    },
    {
      key: "2",
      label: "Создать тренера",
      children: <CreateTrainer type="bishkek" />,
    },
  ];
  return (
    <div>
      <Tabs
        items={tabItems}
        onChange={setActiveTabs("table")}
        activeKey={activeTabsKey["table"]}
      />
    </div>
  );
};
